import { ReactElement } from 'react';
import { DataGridCustomized } from '../../../../../../../../theme/DataGrid';
import { UserModel } from '../../../../../../../models/UserModel';

const UsersTable = ({
  users,
  loading,
  columnsSchema,
}: {
  users: Partial<UserModel>[];
  loading: boolean;
  columnsSchema: any;
}): ReactElement => {
  return (
    <div className='h-full'>
      <DataGridCustomized
        columns={columnsSchema}
        rows={users}
        props={{
          loading,
        }}
      />
    </div>
  );
};

export { UsersTable };
