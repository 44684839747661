import 'reflect-metadata';
import { container } from 'tsyringe';
import { GCPTemplateService } from '../service/impl/GCPTemplateService';
import { GCPImagesService } from '../service/impl/GCPImagesService';
import { GCPAuthService } from '../service/impl/GCPAuthService';
import { GCPJobSitesService } from '../service/impl/GCPJobSitesService';
import { GCPVerbalService } from '../service/impl/GCPVerbalService';
import { GCPNonConformityTemplateService } from '../service/impl/GCPNonConformityTemplateService';
import { GCPCategoriesService } from '../service/impl/GCPCategoriesService';
import { GCPCompaniesService } from '../service/impl/GCPCompaniesService';
import { GCPNonConformityService } from '../service/impl/GCPNonConformityService';
import { GCPUsersService } from '../service/impl/GCPUsersService';
import { GCPRolesService } from '../service/impl/GCPRolesService';
import { GCPPermissionsService } from '../service/impl/GCPPermissionsService';
import { GCPActorsService } from '../service/impl/GCPActorsService';

const AppModule = (): any => {
  container.register('AuthService', {
    useClass: GCPAuthService,
  });
  container.register('TemplateService', {
    useClass: GCPTemplateService,
  });
  container.register('ImagesService', {
    useClass: GCPImagesService,
  });
  container.register('UsersService', {
    useClass: GCPUsersService,
  });
  container.register('JobSitesService', {
    useClass: GCPJobSitesService,
  });
  container.register('VerbalsService', {
    useClass: GCPVerbalService,
  });
  container.register('NonConformityService', {
    useClass: GCPNonConformityService,
  });
  container.register('NonConformityTemplateService', {
    useClass: GCPNonConformityTemplateService,
  });
  container.register('CategoriesService', {
    useClass: GCPCategoriesService,
  });
  container.register('CompaniesService', {
    useClass: GCPCompaniesService,
  });
  container.register('RolesService', {
    useClass: GCPRolesService,
  });
  container.register('PermissionsService', {
    useClass: GCPPermissionsService,
  });
  container.register('ActorsService', {
    useClass: GCPActorsService,
  });
};
export default AppModule;
