import { useEffect, useState } from 'react';
import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { container } from 'tsyringe';
import { ActorsService } from '../../../../service/contracts/ActorsService';
import { RolesService } from '../../../../service/contracts/RolesService';
import { UsersService } from '../../../../service/contracts/UsersService';
import { updateSettingsState } from '../../../../utils/updateSettingsState';
import { Tenant } from '../../../models/Tenant.model';
import { UserModel } from '../../../models/UserModel';
import { settingsState, userState } from '../../sikuro_auth/state/atoms';
import { ChangeTenantDialog } from './ChangeTenantDialog';
type Props = {};
const ChangeTenantDialogWrapper: FC<Props> = (): ReactElement => {
  const intl = useIntl();
  let history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const usersService = container.resolve<UsersService>('UsersService');
  const [userData, setUserData] = useRecoilState(userState);
  const setSettings = useSetRecoilState(settingsState);

  useEffect(() => {
    try {
      const fetchTenants = async () => {
        const ud = (await usersService.get(userData.uid)) as UserModel;
        setTenants(ud.tenants!);
      };
      setLoading(true);
      fetchTenants();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickOnTenant = async (tenant: Tenant) => {
    container.register('Tenant', { useValue: tenant.id });
    const permissions = await getPermissions(userData.uid);
    if (!permissions.includes('BACKOFFICE_ACCESS')) {
      return history.push('/logout');
    }
    localStorage.setItem(
      'userData',
      JSON.stringify({ ...userData, permissions, tenant: tenant.id })
    );
    setUserData({ ...userData, permissions, tenant: tenant.id });
    updateSettingsState({ defaultTenant: tenant.id! }, setSettings);
    setOpen(false);
  };

  const getPermissions = async (userId: string): Promise<string[]> => {
    try {
      const rolesService = container.resolve<RolesService>('RolesService');
      const actorsService = container.resolve<ActorsService>('ActorsService');
      const actor = await actorsService.get(userId);
      const role = await rolesService.get(actor.roleId);
      return role.permissions.map((p) => p.permissionValue);
    } catch (e) {
      return [];
    }
  };

  return (
    <>
      <div
        className='menu-item px-10 cursor-pointer'
        onClick={() => setOpen(true)}
      >
        <p>{intl.formatMessage({ id: 'ACCOUNT_MENU.CHANGE_TENANT' })}</p>
      </div>

      <ChangeTenantDialog
        open={open}
        setDialogStatus={setOpen}
        tenants={tenants}
        loading={loading}
        onClickOnTenant={onClickOnTenant}
      />
    </>
  );
};

export { ChangeTenantDialogWrapper };
