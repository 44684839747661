import { FC, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  defaultMultichoices,
  defaultRadioChoices,
} from '../../../../constants/choices';
import { useIntl } from 'react-intl';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { DialogTitleWrapper } from './DialogTitleWrapper';
import { TextAndColorItem } from '../../../../models/textAndColorItem.model';
import {
  handleRenderChips,
  handleRenderRemainingChips,
  isValidIndex,
} from '../../../../utilities/dialogUtilities';
import { DialogActions } from '@mui/material';
import { Button } from '../../../../../../../_metronic/components/Button';
import { ListFormDialogWrapper } from './ListFormDialogWrapper';
import { formMode } from '../../../../../../models/creationMode.model';

const itemsSelectedInDefaultChoices = (
  radioMode: boolean,
  items: TextAndColorItem[]
): boolean => {
  const arrayToIterate = radioMode ? defaultRadioChoices : defaultMultichoices;
  return arrayToIterate.some((dChoicesFamily: TextAndColorItem[]) => {
    if (dChoicesFamily.length !== items.length) return false; // scarto le famiglia con numero scelte diverse
    const itemsString = items.map((y) => `${y.text}-${y.color}`).join();
    const dChoicesFamilyString = dChoicesFamily
      .map((x) => `${x.text}-${x.color}`)
      .join();
    return dChoicesFamilyString === itemsString;
  });
};

type Props = {
  open: boolean;
  handleClose: () => void;
  onChangeInput: Function;
  items: TextAndColorItem[];
  radioMode?: boolean;
};
const ChoicesListDialog: FC<Props> = ({
  open,
  handleClose,
  onChangeInput,
  items,
  radioMode,
}) => {
  const intl = useIntl();
  const [modalState, setModalState] = useState(false);
  const [choiceChosen, setChoiceChosen] = useState<TextAndColorItem[]>([]);

  const setDefaultChoices = (): void => {
    if (itemsSelectedInDefaultChoices(!!radioMode, items)) {
      return;
    }
    if (radioMode) {
      defaultRadioChoices.push(items);
      return;
    }
    defaultMultichoices.push(items);
  };

  setDefaultChoices();
  const [choices, setChoices] = useState(
    radioMode ? defaultRadioChoices : defaultMultichoices
  );
  const [formChoicesMode, setFormChoicesMode] = useState<formMode>(
    formMode.create
  );
  const [indexChoiceChosen, setIndexChoiceChosen] = useState<number>(-1);

  useEffect(() => {
    const arrayToIterate = radioMode
      ? defaultRadioChoices
      : defaultMultichoices;
    setChoices(arrayToIterate);
  }, [radioMode]);

  const handleRenderChoices = (): ReactJSXElement[] => {
    const defaultChoicesJSX: ReactJSXElement[] = [];
    choices.map((choiceFamily: TextAndColorItem[], index: number) => {
      return defaultChoicesJSX.push(
        handleRenderChoicesFamily(index, choiceFamily)
      );
    });
    return defaultChoicesJSX;
  };

  const closeModal = (): void => {
    setModalState(false);
  };

  const openModal = (
    formChoicesMode: formMode,
    choiceFamily?: TextAndColorItem[],
    index?: number
  ) => {
    if (formChoicesMode === formMode.create) setChoiceChosen([]);
    if (formChoicesMode === formMode.edit && isValidIndex(index)) {
      setIndexChoiceChosen(index!);
      setChoiceChosen(choiceFamily!);
    }
    setFormChoicesMode(formChoicesMode);
    setModalState(true);
  };

  const onChangeLocalInput = (choiceFamily: TextAndColorItem[]) => {
    onChangeInput(true, 'options', choiceFamily);
    handleClose();
  };

  const handleRenderChoicesFamily = (
    index: number,
    choiceFamily: TextAndColorItem[]
  ): ReactJSXElement => {
    return (
      <tr key={index}>
        <td
          className='px-6 py-4 whitespace-nowrap'
          onClick={() => onChangeLocalInput(choiceFamily)}
        >
          <div className='flex items-center gap-2'>
            {handleRenderChips(choiceFamily)}
            {handleRenderRemainingChips(choiceFamily)}
          </div>
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
          <button
            className='font-bold'
            onClick={() => openModal(formMode.edit, choiceFamily, index)}
          >
            {intl.formatMessage({ id: 'GENERAL.FORM.EDIT' })}
          </button>
        </td>
      </tr>
    );
  };

  const onReturnFromListForm = (items: TextAndColorItem[]): void => {
    if (formChoicesMode === formMode.create) {
      return setChoices((c) => [...c, items]);
    }
    const choicesClone = [...choices];
    choicesClone.splice(indexChoiceChosen, 1, items);
    setChoices(choicesClone);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth={true}
      >
        <DialogTitleWrapper
          id='choices-list-dialog-title'
          onClose={handleClose}
        >
          {intl.formatMessage({
            id: 'QUESTION.MULTICHOICE.DIALOG.MODAL_TITLE',
          })}
        </DialogTitleWrapper>
        <DialogContent dividers className={'overflow-hidden'}>
          <div className='flex flex-col'>
            <div className='-my-2 sm:-mx-6 lg:-mx-8'>
              <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                  <table className='min-w-full divide-y divide-gray-200'>
                    <tbody className='divide-y divide-gray-200'>
                      {handleRenderChoices()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={'py-5'}>
          <Button
            id='submit-new-template'
            classes='w-full sm:w-auto'
            otherProps={{
              onClick: () => openModal(formMode.create),
              type: 'submit',
            }}
            innerText={intl.formatMessage({
              id: 'QUESTION.CHOICE_MODAL_ADD_CHOOSE_ACTION',
            })}
          />
        </DialogActions>
      </Dialog>
      <ListFormDialogWrapper
        open={modalState}
        handleClose={closeModal}
        formChoicesMode={formChoicesMode}
        choiceFamily={choiceChosen}
        onSave={(items: TextAndColorItem[]) => onReturnFromListForm(items)}
        maxItems={undefined}
      />
    </div>
  );
};
export { ChoicesListDialog };
