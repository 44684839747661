import {IntlShape} from "react-intl";
import {CategoryType} from "../models/categoryType.enum";
import {QuestionModel} from "../models/question.model";


/**
 * Transcodifica il codice categoria. Viene gestito anche il caso nel quale la categoria provenga da firebase e non sia
 * tra quelle mappate presente nel tipo previsto {@link CategoryType}
 * @category any perchè questa funzione può essere chiamata da DataGrid...
 * @intl utility per gestire la traduzione del codice categoria
 **/
export function transcodeCategoryType(category: any, intl: IntlShape): string {
    if(!category) return ''
    if(typeof category !== 'string') return ''
    const allCategoriesAvailables: string[] =  Object.values(CategoryType)
    if(!allCategoriesAvailables.includes(category)) return `Categoria non esistente (${category})`
    const categoryUpper = category.toUpperCase();
    return intl.formatMessage(
        {id: `TEMPLATE.FORM_CREATION_CATEGORY_TYPE_${categoryUpper}`}
    );
}

export function sortQuestions(questions: QuestionModel[]){
    const sortFn = (firstQuestion: QuestionModel, secondQuestion: QuestionModel): 0 | -1 | 1 => {
        if (firstQuestion.order < secondQuestion.order) { return -1; }
        if (firstQuestion.order > secondQuestion.order) { return 1; }
        return 0;
    }
    if(questions.length === 0) return [];
    const questionsClone = [...questions];
    return questionsClone.sort(sortFn);
}
