import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { container } from 'tsyringe';
import { DeletionModal } from '../../../../../../_metronic/components/modals/DeletionModal';
import { usersDGColumnsDefinition } from '../../../settings/DataGrid';
import { JobSiteModel, jobSiteString } from '../../../../../models/JobSite';
import {
  ErrorType,
  JobSitesService,
} from '../../../../../../service/contracts/JobSitesService';
import { AlertErrors } from '../../../../../../_metronic/components/alerts/Errors';
import { DataGridCustomized } from '../../../../../../theme/DataGrid';
import { userState } from '../../../../sikuro_auth/state/atoms/user';
import { useRecoilValue } from 'recoil';

const JobSitesTableWrapper: FC = () => {
  const intl = useIntl();
  const jobSitesService = container.resolve<JobSitesService>('JobSitesService');
  const [jobSites, setJobSites] = useState<JobSiteModel[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [jobSiteToDelete, setJobSiteToDelete] = useState<JobSiteModel>();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(true);
  const userData = useRecoilValue(userState);

  useEffect(() => {
    async function getJobSites() {
      setLoading(true);
      let response: JobSiteModel[] | ErrorType = await jobSitesService.getAll();
      setLoading(false);
      if (!('isError' in response)) return setJobSites(response);
      setErrors([intl.formatMessage({ id: response.intlIdCode })]);
    }
    getJobSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, userData]);

  const deleteJobSite = async () => {
    await jobSitesService.delete(jobSiteToDelete!);
    setOpenDeleteModal(false);
    setTrigger(!trigger);
  };

  const openDeletionModal = (user: JobSiteModel) => {
    setOpenDeleteModal(true);
    setJobSiteToDelete(user);
  };

  return (
    // (1) Report errori + (2) Lista JobSites + (3) Modale di cancellazione
    <div className='container mw-100 px-0'>
      {/* (1) */}
      <div>
        <AlertErrors errors={errors} />
      </div>
      {/* (2) */}
      <div className='h-full'>
        <DataGridCustomized
          columns={usersDGColumnsDefinition(intl, openDeletionModal)}
          rows={jobSites}
          props={{ loading }}
        />
      </div>
      {/* (3) */}
      <DeletionModal
        open={openDeleteModal}
        setModalState={setOpenDeleteModal}
        onConfirm={deleteJobSite}
        entityString={jobSiteString(jobSiteToDelete)}
      />
    </div>
  );
};

export { JobSitesTableWrapper };
