import {Box, Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import {useIntl} from "react-intl";

type FabActionsProps = {
    add?: () => void
    save?: () => Promise<void>
    addTitle?: string
}

const FabActions = ({add, save, addTitle = 'TEMPLATE.ADD_SECTION_BUTTON'}: FabActionsProps) => {
    const intl = useIntl();
    return (
        <div className="container mw-100 p-10" style={{width: '353px'}}>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    width: 'inherit',
                    opacity: .5,
                    '&:hover': {
                        opacity: 1
                    }
                }}
            >
                <div
                    style={{
                        justifyContent: 'center',
                        display: 'flex',
                        padding: '30px 0'
                    }}
                >
                    {add && (
                        <Fab
                            variant="extended"
                            onClick={add}
                            sx={{ marginX: 1 }}
                        >
                            <AddIcon sx={{ mr: 1 }} />
                            {intl.formatMessage({
                                id: addTitle,
                            })}
                        </Fab>
                    )}
                    {save && (
                        <Fab
                            sx={{ marginX: 1 }}
                            variant="extended"
                            onClick={save}
                        >
                            <SaveIcon sx={{ mr: 1 }} />
                            {intl.formatMessage({
                                id: 'GENERAL.FORM.SAVE',
                            })}
                        </Fab>
                    )}
                </div>
            </Box>
        </div>
    )
}

export default FabActions