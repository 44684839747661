import { FC, ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { container } from 'tsyringe';
import {
  ErrorType,
  JobSitesService,
} from '../../../../../../service/contracts/JobSitesService';
import { formMode } from '../../../../../models/creationMode.model';
import { JobSiteModel } from '../../../../../models/JobSite';
import { JobSiteTabsContext } from './context';
import { JobSiteTabs } from './JobSiteTabs';
import { useHistory, useParams } from 'react-router-dom';
import { UserModel } from '../../../../../models/UserModel';
import { UsersService } from '../../../../../../service/contracts/UsersService';

type Props = {
  mode: formMode;
};

const initialValues = {
  name: '',
  created_at: new Date(),
  users: [],
};

const JobSiteTabsWrapper: FC<Props> = ({ mode }): ReactElement => {
  // @ts-ignore
  const { id } = useParams();
  const intl = useIntl();
  const jobSitesService = container.resolve<JobSitesService>('JobSitesService');
  const usersService = container.resolve<UsersService>('UsersService');
  let history = useHistory();
  const [values, setValues] = useState<JobSiteModel>();
  const [crudErrors, setCrudErrors] = useState<string[]>([]);
  const [usersToBind, setUsersToBind] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (mode === formMode.create) {
      return setValues(initialValues as JobSiteModel);
    }
    const getJobSite = async (id: string) => {
      const jobSitesFetched = await jobSitesService.get(id);
      if ('isError' in jobSitesFetched) {
        return setCrudErrors([
          ...crudErrors,
          intl.formatMessage({ id: jobSitesFetched.intlIdCode }),
        ]);
      }
      const usersBinded: UserModel[] = [];
      if (jobSitesFetched.users) {
        for (let index = 0; index < jobSitesFetched.users.length; index++) {
          const user = jobSitesFetched.users[index];
          const userData = await usersService.get(user.id);
          usersBinded.push(userData as UserModel);
        }
      }
      setValues({ ...jobSitesFetched, users: usersBinded });
    };
    getJobSite(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const onSubmit = async (data: JobSiteModel) => {
    let res: true | ErrorType;
    if (mode === formMode.create) {
      const valuesWithCreatedDate = { ...data, created_at: new Date() };
      res = await jobSitesService.create(valuesWithCreatedDate);
    } else {
      res = await jobSitesService.update(data);
    }
    if (res) {
      history.push('/job-sites-list');
    }
  };

  const unbindUser = async (user: UserModel) => {
    setLoading(true);
    await jobSitesService.unbindUser(values!.id!, user.id);
    setLoading(false);
    setTrigger(!trigger);
  };

  const bindUsers = async (usersIds: string[]) => {
    await jobSitesService.bindUsers(values!.id!, usersIds);
    setTrigger(!trigger);
  };

  if (!values) {
    return <></>;
  }
  return (
    <JobSiteTabsContext.Provider
      value={{
        usersToBind,
        setUsersToBind,
        data: values,
        mode,
        onSubmit,
        unbindUser,
        loading,
        bindUsers,
      }}
    >
      <JobSiteTabs />
    </JobSiteTabsContext.Provider>
  );
};

export { JobSiteTabsWrapper };
