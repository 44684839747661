import {FC} from "react";
import {colors} from '../../../../constants/colors'
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";


type Props = {
    onClickPicker: (index: number, color: string) => void,
    choiceIndex: number
}
const PalettePopover: FC<Props> = ({onClickPicker, choiceIndex}) => {
    const divClasses = "w-14 h-14"

    const handleRenderColorsDivs = (): ReactJSXElement[] => {
        const divs: ReactJSXElement[] = []
        colors.map((c, index) => {
            return divs.push(<div style={{backgroundColor: c}} onClick={() => onClickPicker(choiceIndex, c)}
                           className={divClasses} key={index}/>)
        })
        return divs
    }

    return(
        <div className={'grid grid-cols-4 gap-2 p-2'}>
            {handleRenderColorsDivs()}
        </div>
    )
}
export {PalettePopover}
