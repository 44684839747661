import { TextField, IconButton } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { ReactElement } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

export const CustomSearchBox = ({
  currentRefinement,
  refine,
}: {
  currentRefinement: string;
  refine: CallableFunction;
}): ReactElement => {
  return (
    <TextField
      fullWidth
      id='search-user'
      variant='outlined'
      value={currentRefinement}
      onChange={(event) => refine(event.currentTarget.value)}
      size='small'
      sx={{
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
      }}
      InputLabelProps={{ shrink: true }}
      hiddenLabel={true}
      InputProps={{
        endAdornment: (
          <IconButton>
            <SearchOutlined />
          </IconButton>
        ),
      }}
    />
  );
};

export const SearchBox = connectSearchBox(CustomSearchBox);
