import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from '../../../../../../_metronic/components/Button';
import { JobSitesTableWrapper } from './JobSitesTableWrapper';

const JobSitesSection: FC = (): ReactElement => {
  const intl = useIntl();

  return (
    <>
      {/* (1) Pulsante Nuovo Cantiere + (2) Tabella JobSites */}
      <div className={'mb-2'}>
        <Link to={'/job-site'}>
          <Button
            classes='mr-2'
            id='new-job-site'
            innerText={intl.formatMessage({
              id: 'JOB_SITES.BUTTONS.NEW_JOB_SITE',
            })}
          />
        </Link>
      </div>
      {/* (2) */}
      <JobSitesTableWrapper></JobSitesTableWrapper>
    </>
  );
};

export { JobSitesSection };
