import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Textarea } from '../../../../../../_metronic/layout/components/form/Textarea';
import { UploadImagesForm } from '../../../../../../_metronic/layout/components/form/UploadImages';
import { ImageData } from '../../../models/question.model';
import { updateQuestionType } from '../../../models/updateQuestionType';

export type onChangeImagesFn = (
  imagesUris: ImageData[] | string[],
  type: 'images' | 'imagesToDelete'
) => void;

type Props = {
  onChangeInput: updateQuestionType;
  instructionDescription: string;
  images: ImageData[];
};
const InstructionType: FC<Props> = ({
  onChangeInput,
  instructionDescription,
  images = [],
}) => {
  const intl = useIntl();
  const randomId = Date.now();

  const onChangeImages: onChangeImagesFn = (imagesData, type) => {
    onChangeInput(true, type, imagesData);
  };

  const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChangeInput(e, 'instruction_description');
  };

  return (
    <div className={'pb-5'}>
      <div className={'w-full'}>
        <Textarea
          id={`instruction_description_${randomId}`}
          onChangeInput={onChangeDescription}
          label={intl.formatMessage({
            id: 'QUESTION.INSTRUCTION_DESRIPTION_LABEL',
          })}
          value={instructionDescription}
        />
      </div>
      <div className='w-full mt-2'>
        <UploadImagesForm
          id={`images_${randomId}`}
          onChangeImages={onChangeImages}
          images={images}
          label={intl.formatMessage({
            id: 'QUESTION.INSTRUCTION_UPLOAD_IMAGES_LABEL',
          })}
        />
      </div>
    </div>
  );
};
export { InstructionType };
