import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '../../../../_metronic/components/Button';
import { VerbalTemplate } from '../models/template.model';
import { templateDGColumnsDefinition } from '../settings/DataGrid';
import { container } from 'tsyringe';
import { TemplateService } from '../../../../service/contracts/TemplateService';
import { DeletionModal } from '../../../../_metronic/components/modals/DeletionModal';
import { Link } from 'react-router-dom';
import SimpleModalWithText from './SimpleModalWithText';
import { DataGridCustomized } from '../../../../theme/DataGrid';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { templateState } from '../state/template';
import { CategoriesAutocomplete } from './CategoriesAutocomplete';
import { CategoriesService } from '../../../../service/contracts/CategoriesService';
import { Category } from '../../../modules/categories/models/Category.model';
import { userState } from '../../../modules/sikuro_auth/state/atoms/user';

const TemplatesListPage: FC = () => {
  const intl = useIntl();
  const reset = useResetRecoilState(templateState);
  const templateService = container.resolve<TemplateService>('TemplateService');
  const categoriesService =
    container.resolve<CategoriesService>('CategoriesService');
  const [categories, setCategories] = useState<Partial<Category>[]>([]);
  const [categoriesChoosed, setCategoriesChoosed] = useState<
    Partial<Category>[]
  >([]);
  const [templates, setTemplates] = useState<VerbalTemplate[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openCopyTemplateModal, setOpenCopyTemplateModal] =
    useState<boolean>(false);
  const [templateDataToCopy, setTemplateDataToCopy] = useState<{
    id: string;
    inputValue: string;
  }>({
    id: '',
    inputValue: '',
  });
  const [templateToDelete, setTemplateToDelete] = useState<VerbalTemplate>();
  const [loading, setLoading] = useState<boolean>(false);
  const [letFetch, setLetFetch] = useState<boolean>(false);
  const userData = useRecoilValue(userState);

  const getCategories = async () => {
    let response: Partial<Category>[] | false =
      await categoriesService.getAll();
    if (typeof response === 'boolean') {
      return;
    }
    setCategories(
      response.map((category) => ({ id: category.id, tag: category.tag }))
    );
  };

  useEffect(() => {
    reset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteTemplate = async () => {
    if (!templateToDelete) {
      return;
    }
    if (!templateToDelete.id) {
      return console.log('Qualcosa è andato storto, Id Assente!');
    }
    try {
      await templateService.deleteTemplate(templateToDelete);
      setOpenDeleteModal(false);
      setLetFetch(!letFetch);
    } catch (err: unknown) {
      console.log('Cancellazione Template Fallita!');
    }
  };

  const templateToDeleteString = (): string => {
    if (!templateToDelete) return '';
    return templateToDelete.name;
  };

  const openDeletionModal = (template: VerbalTemplate) => {
    setOpenDeleteModal(true);
    setTemplateToDelete(template);
  };

  /**
   * Handle Verbal Template copy
   */
  const handleOpenTemplateCopyModal = async (data: {
    id: string;
    name: string;
  }) => {
    setOpenCopyTemplateModal(true);
    setTemplateDataToCopy({
      id: data.id,
      inputValue: `${data.name}_Copy`,
    });
  };

  const handleTemplateCopy = async (data: {
    id: string;
    inputValue: string;
  }) => {
    try {
      const template = (await templateService.getTemplate(
        data.id
      )) as VerbalTemplate;
      const templateWithNewName = {
        ...template,
        name: data.inputValue,
      };
      await templateService.create(templateWithNewName, true);
      setOpenCopyTemplateModal(false);
      setTemplateDataToCopy({ id: '', inputValue: '' });
      setLetFetch(!letFetch);
    } catch (err: unknown) {
      console.error('Duplicazione del verbale Fallita', err);
    }
  };

  const columns = templateDGColumnsDefinition(
    intl,
    openDeletionModal,
    handleOpenTemplateCopyModal
  );

  const onCategoriesChange = (event: any, values: any) => {
    setCategoriesChoosed(values);
    setLetFetch(!letFetch);
  };

  useEffect(() => {
    const fetchTemplateFiltered = async () => {
      setLoading(true);
      try {
        const t = await templateService.getTemplatesFiltered({
          categories: categoriesChoosed,
        });
        if (!t) {
          return;
        }
        setTemplates(t);
      } catch (err: unknown) {
        console.log('Fetch Template Fallito!');
      } finally {
        setLoading(false);
      }
    };
    getCategories();
    fetchTemplateFiltered();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letFetch, userData]);

  return (
    <div className='container mw-100 px-0'>
      <div className={'mb-2'}>
        <Link to={'/template/create'}>
          <Button
            classes='mr-2'
            id='new-template'
            innerText={intl.formatMessage({
              id: 'TEMPLATE.BUTTONS.NEW_TEMPLATE_AUDIT',
            })}
          />
        </Link>
      </div>
      <div className={'container mw-100 primary-bg rounded-xl mt-5 p-5 h-auto'}>
        <label className='text-lg'>
          {intl.formatMessage({
            id: 'TEMPLATE.FILTER.CATEGORIES',
          })}
        </label>
        <CategoriesAutocomplete
          categories={categories}
          onCategoriesChange={onCategoriesChange}
          value={categoriesChoosed}
        />
      </div>
      <div className='h-full mt-5'>
        <DataGridCustomized
          columns={columns}
          rows={templates}
          props={{ loading }}
        />
      </div>
      <DeletionModal
        open={openDeleteModal}
        setModalState={setOpenDeleteModal}
        onConfirm={deleteTemplate}
        entityString={templateToDeleteString()}
      />
      <SimpleModalWithText
        entityProps={templateDataToCopy}
        open={openCopyTemplateModal}
        setOpen={setOpenCopyTemplateModal}
        handleSubmit={handleTemplateCopy}
        labels={{
          inputLabel: intl.formatMessage({
            id: 'DUPLICATE_NC_MODAL.NEW_TEMPLATE_NAME_LABEL',
          }),
          helpText: intl.formatMessage({
            id: 'SIMPLE_MODAL_TEXT.HELP_TEXT',
          }),
        }}
      />
    </div>
  );
};

export { TemplatesListPage };
