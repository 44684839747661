import { Category } from '../../app/modules/categories/models/Category.model';

export type upsertData = {
  categoryData: Partial<Category> & { inputValue: string };
  userId: string;
};

export abstract class CategoriesService {
  abstract getAll(): Promise<Category[] | false>;
  abstract get(id: string): Promise<Category | false>;
  abstract create({ categoryData, userId }: upsertData): Promise<string>;
  abstract update({ categoryData, userId }: upsertData): Promise<boolean>;
  abstract delete(id: string): Promise<boolean>;
}
