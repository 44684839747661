import React, { FC } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useIntl } from 'react-intl';

type Props = {
  errors: string[];
};

const AlertErrors: FC<Props> = ({ errors }) => {
  const intl = useIntl();

  const handleRenderErrors = (): ReactJSXElement[] => {
    const errorsJSX: ReactJSXElement[] = [];
    errors.map((error: string, index: number) =>
      errorsJSX.push(<li key={index}>{error}</li>)
    );
    return errorsJSX;
  };

  const handleRenderErrorSummary = (): string => {
    if (errors.length === 0) return '';
    if (errors.length === 1)
      return intl.formatMessage({ id: 'GENERAL.ALERTS.THERE_WAS_AN_ERRORS' });
    return intl.formatMessage(
      { id: 'GENERAL.ALERTS.THERE_WAS_N_ERRORS' },
      { errorsNumber: errors.length }
    );
  };

  if (errors.length === 0) return <></>;
  return (
    <div className='rounded-md bg-red-50 p-4'>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <svg
            className='h-5 w-5 text-red-400'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            aria-hidden='true'
          >
            <path
              fillRule='evenodd'
              d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293
                               1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414
                               10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
              clipRule='evenodd'
            />
          </svg>
        </div>
        <div className='ml-3'>
          <h3 className='text-sm font-medium text-red-800'>
            {handleRenderErrorSummary()}
          </h3>
          <div className='mt-2 text-red-700'>
            <ul className='list-disc pl-5 space-y-1'>{handleRenderErrors()}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AlertErrors };
