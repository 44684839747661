import { createContext } from 'react';
import { formMode } from '../../../../../models/creationMode.model';
import { Permission } from '../../../models/Permission.model';
import { emptyRole, Role } from '../../../models/Role.model';

const initialState: {
  data: Partial<Role>;
  mode: formMode;
  permissionsToBind: string[];
  setPermissionsToBind: CallableFunction;
  bindPermissions: (permissions: Permission[]) => void;
  unbindPermission: (user: Permission) => void;
  loading: boolean;
  otherPermissions: Permission[];
} = {
  data: emptyRole,
  mode: formMode.create,
  permissionsToBind: [],
  setPermissionsToBind: Object,
  bindPermissions: Object,
  unbindPermission: Object,
  loading: false,
  otherPermissions: [],
};

export const RoleTabsContext = createContext(initialState);
