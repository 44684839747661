import React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formMode } from '../models/creationMode.model';
type Props<T> = {
  config: {
    id: string;
    mode: formMode;
    onSubmitUrl: string;
    initialValues: T;
  };
  customSubmitEvent?: (data: any) => void;
  entitiesService: any;
  children: ReactElement;
};
const EntityForm = <T extends unknown>({
  config,
  customSubmitEvent,
  entitiesService,
  children,
}: Props<T>) => {
  let history = useHistory();
  // @ts-ignore
  const [entities, setEntities] = useState<Partial<T>>();

  /**
   * Fetch the entity to edit id in edit mode
   */
  useEffect(() => {
    const getEntity = async (id: string) => {
      const fetchedResult = await entitiesService.get(id);
      setEntities(fetchedResult);
    };
    try {
      if (config.mode === formMode.create) {
        setEntities(config.initialValues);
        return;
      }
      getEntity(config.id);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitEvent = async (data: T) => {
    if (customSubmitEvent) {
      await customSubmitEvent(data);
    } else {
      if (config.mode === formMode.create) {
        await entitiesService.create(data);
      } else {
        await entitiesService.update(data);
      }
    }
    history.push(config.onSubmitUrl);
  };

  if (config.mode === formMode.edit && !entities) {
    return <></>;
  }

  return React.cloneElement(children, {
    mode: config.mode,
    handleSubmitEvent,
    values: entities,
  });
};

export { EntityForm };
