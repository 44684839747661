import {
  GridCellValue,
  GridColDef,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { IntlShape } from 'react-intl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const usersDGColumnsDefinition = (intl: IntlShape): GridColDef[] => [
  {
    field: 'field_name',
    headerName: intl.formatMessage({ id: 'USERS_LIST.TABLE.NAME_AND_SURNAME' }),
    sortable: true,
    flex: 1,
    renderCell: (params: GridValueGetterParams): GridCellValue => (
      <Box>
        <Typography>{params.row.email}</Typography>
        {params.row.displayName && (
          <Typography className={'w-full block'}>
            {params.row.displayName}
          </Typography>
        )}
      </Box>
    ),
  },
];
