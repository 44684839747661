import { createContext } from 'react';
import { formMode } from '../../../../../models/creationMode.model';
import { JobSiteModel } from '../../../../../models/JobSite';
import { UserModel } from '../../../../../models/UserModel';

const initialState: {
  data: JobSiteModel;
  mode: formMode;
  usersToBind: string[];
  setUsersToBind: CallableFunction;
  onSubmit: (data: JobSiteModel) => void;
  unbindUser: (user: UserModel) => void;
  bindUsers: (usersIds: string[]) => void;
  loading: boolean;
} = {
  data: {
    name: '',
    created_at: new Date(),
    users: [],
  },
  mode: formMode.create,
  usersToBind: [],
  setUsersToBind: (usersIds: string[]) => {},
  onSubmit: (data: JobSiteModel) => {},
  bindUsers: (usersIds: string[]) => {},
  unbindUser: (user: UserModel) => {},
  loading: false,
};

export const JobSiteTabsContext = createContext(initialState);
