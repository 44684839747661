import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from '../../../../app/modules/sikuro_auth/state/atoms/user';
import { Languages } from './Languages';
import { getAcronym } from '../../../utilities/utilities';
import { ChangeTenantDialogWrapper } from '../../../../app/modules/accounts/changeTenantDialog/ChangeTenantDialogWrapper';

type Props = {
  userDisplayName: string;
};

const HeaderUserMenu: FC<Props> = ({ userDisplayName }) => {
  const intl = useIntl();
  const user = useRecoilValue(userState);

  // const [state, setState] = useState({
  //   top: false,
  //   left: false,
  //   bottom: false,
  //   right: false,
  // });

  // const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='rounded-full h-12 w-12 flex items-center justify-center secondary-bg font-bold mr-3'>
            <div>
              <span>{getAcronym(userDisplayName)}</span>
            </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {userDisplayName}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <div className='fw-bold text-muted text-hover-primary fs-7'>
              {user.email}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2' />

      {/*<div className="menu-item px-5">
        <div>
            <a className='menu-link px-5'>
              <span className='menu-text' onClick={toggleDrawer('right', true)}>Impostazioni</span>
            </a>
            <Drawer anchor='right' open={state['right']} onClose={toggleDrawer('right', false)}>
              <UserSettings/>
            </Drawer>
        </div>
      </div>*/}

      {/*
      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
    </div>*/}

      <div className='separator my-2' />
      <Languages />
      <div className='separator my-2' />
      <ChangeTenantDialogWrapper />
      <div className='separator my-2' />
      {/*<div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <Link to='/logout' className='menu-link px-5'>
          {intl.formatMessage({ id: 'MENU.HEADER.USERDIV.LOGOUT' })}
        </Link>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
