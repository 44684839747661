import { FC, useState } from 'react';
import { ButtonText } from '../../../../../_metronic/components/ButtonText';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import * as formUtils from '../../utils/formUtils';
import { ButtonWrapper } from '../../../../../_metronic/components/ButtonWrapper';
import { Input } from '../../../../../_metronic/layout/components/form/Input';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { loginSchema } from './config/loginSchema';
import { initialValues } from './config/initialValues';
import { loginOutputType } from '../../../../../service/contracts/AuthService';
type Props = {
  loginAction: (email: string, password: string) => Promise<loginOutputType>;
  onSuccess: (response: loginOutputType) => void;
};
const Login: FC<Props> = ({ loginAction, onSuccess }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema(intl),
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      loginAction(values.email, values.password)
        .then(async (response: loginOutputType) => {
          await onSuccess(response);
          setSubmitting(false);
        })
        .catch((error: unknown) => {
          setSubmitting(false); // @ts-ignore
          setStatus(intl.formatMessage({ id: error!.message ?? error }));
        })
        .finally(() => setLoading(false));
    },
  });

  const handleRenderSubmitContent = (): ReactJSXElement => {
    if (!loading)
      return (
        <ButtonText
          title={intl.formatMessage({ id: 'AUTH.LOGIN.FORM_SUBMIT' })}
        />
      );
    return (
      <ButtonText classes='indicator-progress block'>
        <span className='spinner-border spinner-border-sm align-middle ms-2' />
      </ButtonText>
    );
  };

  const formStatusJSX = () => {
    if (!formik.status) return <></>;
    return (
      <div
        className='mb-lg-15 alert alert-danger'
        id='error-msg'
        data-testid='error-msg'
      >
        <div className='alert-text font-weight-bold'>{formik.status}</div>
      </div>
    );
  };

  const formHeaderJSX = () => {
    return (
      <div className='text-center mb-10'>
        <h2 className='fw-bold fs-6 fw-bolder text-dark'>
          {intl.formatMessage({ id: 'AUTH.LOGIN.WELCOME_MSG' })}
        </h2>
        <h1 className='text-dark mb-3 text-5xl font-bold'>
          {intl.formatMessage({ id: 'AUTH.LOGIN.FORM_TITLE' })}
        </h1>
      </div>
    );
  };
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {formHeaderJSX()}
      {formStatusJSX()}
      <div className='mb-5'>
        <Input
          label={intl.formatMessage({ id: 'AUTH.LOGIN.FORM_USERNAME' })}
          id='email'
          type='email'
          formikProps={{ ...formik.getFieldProps('email') }}
          errorMsg={formUtils.getErrorMsg('email', formik)}
          isTouched={formUtils.isTouched('email', formik)}
          thereIsError={formUtils.thereIsError('email', formik)}
        />
      </div>
      <div className='mb-5'>
        <Input
          label={intl.formatMessage({ id: 'AUTH.LOGIN.FORM_PASSWORD' })}
          id='password'
          type='password'
          formikProps={{ ...formik.getFieldProps('password') }}
          errorMsg={formUtils.getErrorMsg('password', formik)}
          isTouched={formUtils.isTouched('password', formik)}
          thereIsError={formUtils.thereIsError('password', formik)}
        ></Input>
      </div>
      <div className='text-center'>
        <ButtonWrapper
          id='submit'
          classes='w-full'
          otherProps={{
            disabled: formik.isSubmitting || !formik.isValid,
            type: 'submit',
          }}
        >
          {handleRenderSubmitContent()}
        </ButtonWrapper>
      </div>
    </form>
  );
};

export { Login };
