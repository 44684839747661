import React from 'react'
import {Link} from 'react-router-dom'
import { useIntl } from 'react-intl'
import {Button} from "../../../../_metronic/components/Button";


type Props = {
    message: string,
    type: 'success' | 'danger'
    nextPathRoute?: string
}

const IntermediateMessage: React.FC<Props> = ({message, type, nextPathRoute}) => {
    const intl = useIntl()


    return (
        <>
            <div className={`mb-lg-15 alert alert-${type}`}>
                <div className='alert-text font-weight-bold'>
                    {message}
                </div>
            </div>
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                {nextPathRoute && <Link to={`${nextPathRoute}`}>
                    <Button id='reset_password_continue'
                            innerText={intl.formatMessage({id: "AUTH.INTERMEDIATE_MESSAGE.CONTINUE" })}/>
                </Link>}

                <Link to='/'>
                    <Button id='reset_password_cancel'
                            innerText={intl.formatMessage({id: "AUTH.INTERMEDIATE_MESSAGE.BACK_TO_HOME" })}/>
                </Link>
            </div>
        </>
    )
}

export {IntermediateMessage}
