import { ImagesService } from '../contracts/ImagesService';
import { deleteObject, getDownloadURL, uploadBytes } from 'firebase/storage';
import { StorageReference } from '@firebase/storage/dist/storage-public';

export class GCPImagesService implements ImagesService {
  uploadBytesImage(
    storageRef: StorageReference,
    imageFile: File
  ): Promise<any> {
    return uploadBytes(storageRef, imageFile);
  }

  deleteImage(imageRef: StorageReference): Promise<void> {
    return deleteObject(imageRef);
  }

  getImageUrl(imageRef: StorageReference): Promise<string> {
    return getDownloadURL(imageRef);
  }
}
