import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { formMode } from '../../../../models/creationMode.model';
import { JobSiteTabsWrapper } from './tabs/JobSiteTabsWrapper';

type Props = {
  mode: formMode;
};

const JobSiteForm: FC<Props> = ({ mode }) => {
  const intl = useIntl();
  const handleRenderTitle = (): string => {
    if (mode === formMode.create)
      return intl.formatMessage({ id: 'JOB_SITE.FORM_CREATION_TITLE_LABEL' });
    return intl.formatMessage({ id: 'JOB_SITE.FORM_EDIT_TITLE_LABEL' });
  };

  return (
    <>
      {/* Titolo + Form a tabs */}
      {/* (1) */}
      <PageTitle breadcrumbs={[]}>{handleRenderTitle()}</PageTitle>
      {/* (2) */}
      <div className={'container primary-bg rounded-xl mw-100 p-10 h-auto'}>
        <JobSiteTabsWrapper mode={mode} />
      </div>
    </>
  );
};

export { JobSiteForm };
