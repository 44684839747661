import { FC } from 'react';
import { useIntl } from 'react-intl';
import { QuestionModel } from '../../../../models/question.model';
import { QuestionType } from '../../../../models/questionType.enum';
import { updateQuestionType } from '../../../../models/updateQuestionType';
import { Question } from '../../Question';
import { getNewValue } from '../../QuestionWrapper';

const typesToExcludes = [QuestionType.table, QuestionType.instruction];

type Props = {
  otherProps?: any;
  sectionIndex: number;
  entity: QuestionModel;
  updateTableTypeQuestions: Function;
  deleteTableTypeQuestion: Function;
  errorMsg?: string | undefined
};

/**
 * Wrapper around Question component.
 * IT MANAGE ITS STATE CRUD OPERATIONS
 * Parte dal presupposto che sia chiamato da una section => già cosi' non va bene
 * Può essere contenuto anche da una domanda (per tipo tabella)
 * @param props.mode Form mode (edit or create)
 * @param props.otherProps Any other props to apply to Question div component
 * @param props.sectionIndex Index of the section item where the question is
 * @param props.question Question to render
 * */
const QuestionTableWrapper: FC<Props> = ({
  otherProps,
  sectionIndex,
  entity,
  updateTableTypeQuestions,
  deleteTableTypeQuestion,
  errorMsg
}) => {
  const intl = useIntl();

  /**
   * Update table type question (not 'simple' template or non_confomity type!!)
   */
  const updateQuestion: updateQuestionType = (
    eventOrSpecialCase: any,
    key: keyof QuestionModel,
    value?: any
  ) => {
    const valueAdapted = getNewValue(eventOrSpecialCase, value);
    updateTableTypeQuestions(
      eventOrSpecialCase,
      key,
      valueAdapted,
      entity.order
    );
  };

  const deleteQuestion = () => {
    deleteTableTypeQuestion(entity.order);
  };

  return (
    <Question
      otherProps={otherProps}
      sectionIndex={sectionIndex}
      question={entity}
      deleteQuestion={deleteQuestion}
      updateQuestion={updateQuestion}
      typesToExcludes={typesToExcludes}
      excludeCheckbox={true}
      questionLabels={{
        title: intl.formatMessage(
          { id: 'QUESTION.QUESTION_TABLE_LABEL' },
          { number: otherProps.index + 1 }
        ),
        placeholder: intl.formatMessage(
          { id: 'QUESTION.QUESTION_TABLE_PLACEHOLDER' },
          { number: otherProps.index + 1 }
        ),
      }}
      errorMsg={errorMsg}
    />
  );
};
export { QuestionTableWrapper };
