import React, {FC} from 'react';
import {useIntl} from 'react-intl';
import {PageTitle} from '../../../../_metronic/layout/core';
import {Template} from "./Template";
import {PrivateRoutes} from "../../../routing/PrivateRoutes";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {formMode} from "../../../models/creationMode.model";


const TemplateWrapper: FC = () => {
    const intl = useIntl()
    let match = useRouteMatch();

    return (
        <>
            {/** TODO: eliminare questa parte ed inserirla nel pagesConfiguration, al momento lascio **/}
            <PrivateRoutes>
                <Switch>
                    <Route exact path={`${match.path}/edit/:id`}>
                        <Template mode={formMode.edit}/>
                    </Route>
                    <Route path={match.path}>
                        <Template mode={formMode.create}/>
                    </Route>
                </Switch>
            </PrivateRoutes>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CREATE_TEMPLATE' })}</PageTitle>
        </>
    )
}

export {TemplateWrapper}
