import { FC } from 'react';
import { QuestionModel } from '../../models/question.model';
import { useRecoilState } from 'recoil';
import {
  newMultichoiceTextColor,
  newQuestion,
  newRadioTextColorItem,
  templateState,
} from '../../state/template';
import { updateQuestionType } from '../../models/updateQuestionType';
import { formMode } from '../../../../models/creationMode.model';
import { Question } from './Question';
import { QuestionCore } from '../../models/questionCore.model';
import { QuestionType } from '../../models/questionType.enum';

/**
 * Return the correct value for the field updated.
 *
 * @param eventOrSpecialCase: is a SyntheticBaseEvent, within it there is the value to be used (if the input
 * changed isn't boolean)
 * @param value: is the value to be used in case of special case (e.g. when the input changed is NOT boolean)
 */
export const getNewValue: any = (
  eventOrSpecialCase: any,
  value?: any
): void => {
  if (typeof eventOrSpecialCase === 'boolean') {
    return value;
  }
  if (eventOrSpecialCase.target.type === 'checkbox') {
    return eventOrSpecialCase.target.checked;
  }
  return eventOrSpecialCase.target.value;
};

/**
 * Generate the new Question to be updated
 * (*) - In case of multichoice/radio the question key used is the same ('options'). For this reason it must be
 * resetted when the question type is changed between radio and multichoice.
 * @param key: the question key to be updated
 * @param value: the value to be updated
 * @returns: the question to be updated
 */
export const getQuestionCleaned = (
  question: QuestionModel,
  mode: formMode,
  key: keyof QuestionModel,
  value: any
): QuestionModel => {
  // @ts-ignore
  return {
    ...newQuestion(question.order),
    ...getMandatoryFields(question),
    ...getQuestionEntityFields(question, key),
    ...getCustomFields(question, key, value),
    [key]: value,
  };
};

const getMandatoryFields = (question: QuestionModel): Partial<QuestionCore> => {
  return {
    ...{ order: question.order },
    ...{ content: question.content },
    ...{ type: question.type },
    ...{ required: question.required },
    ...{ is_duplicable: question.is_duplicable },
    ...{ is_visible_in_report: question.is_visible_in_report },
    ...(question.width_perc && typeof question.width_perc === 'number'
      ? { width_perc: question.width_perc }
      : {}),
  };
};

const getQuestionEntityFields = (
  question: QuestionModel,
  key: keyof QuestionModel
) => {
  const noEntitiesTypes = [
    'SLIDER',
    'MULTICHOICE',
    'DATETIME',
    'CORRELATION',
    'TABLE',
    'INSTRUCTION',
  ];
  const isQuestionEntityField = !noEntitiesTypes.includes(question.type);
  const canKeepField = isQuestionEntityField && key !== 'type';
  return {
    ...(canKeepField && {
      is_visible_in_report: question.is_visible_in_report,
    }),
    ...(canKeepField && {
      is_duplicable: question.is_duplicable,
    }),
  };
};

const handleInstructionCase = (
  question: QuestionModel,
  key: keyof QuestionModel,
  images: ImageData[] | string[]
) => {
  return {
    ...(['images', 'imagesToDelete'].includes(key) && {
      ...(key === 'images' && {
        images,
        imagesToDelete: question.imagesToDelete ?? [],
      }),
      ...(key === 'imagesToDelete' && {
        images: question.images,
        imagesToDelete: images,
      }),
      instruction_description: question.instruction_description,
    }),
    ...(!['images', 'imagesToDelete'].includes(key) &&
      question.type === QuestionType.instruction && {
        images: question.images,
        imagesToDelete: question.imagesToDelete,
        instruction_description: question.instruction_description,
      }),
  };
};

const getCustomFields = (
  question: QuestionModel,
  key: keyof QuestionModel,
  value: any
) => {
  return {
    ...handleDatetimeCase(question, key),
    ...handleCorrelationCase(question, key),
    ...handleMultichoiceCase(question, key, value),
    ...handleSliderCase(question, key, value),
    ...handleInstructionCase(question, key, value),
    ...handleTableCase(question, key, value),
  };
};

const handleCorrelationCase = (
  question: QuestionModel,
  key: keyof QuestionModel
) => {
  return {
    ...(key !== 'correlationType' &&
      'CORRELATION' === question.type && {
        correlationType: question.correlationType,
      }),
  };
};

const handleDatetimeCase = (
  question: QuestionModel,
  key: keyof QuestionModel
) => {
  return {
    ...(key !== 'date_time' &&
      'DATETIME' === question.type && {
        date_time: question.date_time,
      }),
  };
};

const handleTableCase = (
  question: QuestionModel,
  key: keyof QuestionModel,
  value: any
) => {
  return {
    ...(question.type === 'TABLE' && { columns: question.columns }),
  };
};

const handleMultichoiceCase = (
  question: QuestionModel,
  key: keyof QuestionModel,
  value: any
) => {
  return {
    ...(key === 'type' &&
      ['RADIO'].includes(value) && {
        options: newRadioTextColorItem(),
      }), // (*)
    ...(key === 'type' &&
      ['MULTICHOICE'].includes(value) && {
        options: newMultichoiceTextColor(),
      }), // (*)
    ...(key !== 'type' &&
      ['RADIO', 'MULTICHOICE'].includes(question.type) && {
        options: question.options,
      }), // (*)
  };
};

const handleSliderCase = (
  question: QuestionModel,
  key: keyof QuestionModel,
  value: any
) => {
  return {
    ...(key === 'type' &&
      value === 'SLIDER' && {
        min: 0,
        max: 10,
        step: 1,
      }),
    ...(key !== 'type' &&
      'SLIDER' === question.type && {
        min: Number(question.min),
        max: Number(question.max),
        step: Number(question.step),
      }),
  };
};

type Props = {
  mode: formMode;
  otherProps?: Object;
  sectionIndex: number;
  entity: QuestionModel;
  templateErrors: { field: string; message: string }[];
};

/**
 * Wrapper around Question component.
 * IT MANAGE ITS STATE CRUD OPERATIONS
 * Parte dal presupposto che sia chiamato da una section => già cosi' non va bene
 * Può essere contenuto anche da una domanda (per tipo tabella)
 * @param props.mode Form mode (edit or create)
 * @param props.otherProps Any other props to apply to Question div component
 * @param props.sectionIndex Index of the section item where the question is
 * @param props.question Question to render
 * */
const QuestionWrapper: FC<Props> = ({
  mode,
  otherProps,
  sectionIndex,
  entity,
  templateErrors,
}) => {
  const [template, setTemplate] = useRecoilState(templateState);
  const oldQuestions = [...template.sections[sectionIndex].questions];
  const oldSection = { ...template.sections[sectionIndex] };
  const oldSections = [...template.sections];
  const questionIndex = oldQuestions.findIndex((q) => q.order === entity.order);

  const deleteQuestion = () => {
    oldQuestions.splice(questionIndex, 1);
    oldSection.questions = oldQuestions;
    oldSections[sectionIndex] = oldSection;
    setTemplate({ ...template, sections: oldSections });
  };

  const saveQuestion: updateQuestionType = (
    eventOrSpecialCase: any,
    key: keyof QuestionModel,
    value?: any
  ) => {
    const valueAdapted = getNewValue(eventOrSpecialCase, value);
    const newQuestionCleaned = getQuestionCleaned(
      oldQuestions[questionIndex],
      mode,
      key,
      eventOrSpecialCase.target?.type === 'number'
        ? Number(valueAdapted)
        : valueAdapted
    );
    oldQuestions[questionIndex] = newQuestionCleaned;
    const _oldSection = { ...oldSection, questions: oldQuestions };
    oldSections[sectionIndex] = _oldSection;
    setTemplate({ ...template, sections: oldSections });
  };

  return (
    <Question
      otherProps={otherProps}
      sectionIndex={sectionIndex}
      templateErrors={templateErrors}
      question={entity}
      deleteQuestion={deleteQuestion}
      updateQuestion={saveQuestion}
    />
  );
};
export { QuestionWrapper };
