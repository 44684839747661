import { atom } from 'recoil';
import { VerbalTemplate } from '../models/template.model';
import { SectionModel } from '../models/section.model';
import { QuestionModel } from '../models/question.model';
import { TextAndColorItem } from '../models/textAndColorItem.model';
import { QuestionType } from '../models/questionType.enum';
import { DateTimeEnum } from '../models/dateTime.enum';
import { defaultMultichoices, defaultRadioChoices } from '../constants/choices';
import { EntityTypeEnum } from '../models/entityType.enum';

export const defaultTemplate: VerbalTemplate = {
  id: null,
  name: '',
  description: '',
  categories: [],
  sections: [],
  jobSite: '',
};

export const templateState = atom({
  key: 'template',
  default: defaultTemplate,
});

export const getLastEntitiesOrder = (
  entities: SectionModel[] | QuestionModel[]
): number => {
  if (!entities) return 0;
  if (entities.length === 0) return 0;
  return (
    Math.max(...entities.map((s: SectionModel | QuestionModel) => s['order'])) +
    1
  );
};

export const newMultichoiceTextColor = (): TextAndColorItem[] =>
  defaultMultichoices[0];

export const newRadioTextColorItem = (): TextAndColorItem[] =>
  defaultRadioChoices[0];

export const newQuestion = (orderQuestion: number): QuestionModel => {
  return {
    order: orderQuestion,
    content: '',
    type: QuestionType.text,
    required: false,
    is_visible_in_report: false,
    notes: '',
    is_duplicable: false,
    correlationType: EntityTypeEnum.users,
    text: '',
    images: [],
    imagesToDelete: [],
    instruction_description: '',
    options: [],
    min: 0,
    max: 10,
    step: 1,
    date_time: DateTimeEnum.date,
    columns: [],
    answer: { value: null },
  };
};

export const newSection = (order: number) => {
  return {
    order: order,
    title: '',
    questions: [],
  };
};

export const newChoice = (): TextAndColorItem => {
  return {
    text: '',
    color: '#01E801',
  };
};
