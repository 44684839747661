import { Box } from '@mui/material';
import { FC, ReactElement, useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '../../../../../../../../_metronic/components/Button';
import { RoleTabsContext } from '../../context';
import { PermissionsDialogWithRowsSelection } from './PermissionsDialogWithRowsSelection';
import { PermissionsTable } from '../table/PermissionsTable';
import { RolesService } from '../../../../../../../../service/contracts/RolesService';
import { container } from 'tsyringe';
import { permissionsDGColumnsDefinition } from './DataGrid';

type Props = {
  isOpen: boolean;
  setDialogState: (state: boolean) => void;
};
const AddPermissionSection: FC<Props> = ({
  isOpen,
  setDialogState,
}): ReactElement => {
  const intl = useIntl();
  const rolesService = container.resolve<RolesService>('RolesService');
  const bindButtonElement = useRef(null);
  const {
    data,
    loading,
    permissionsToBind,
    bindPermissions,
    setPermissionsToBind,
    otherPermissions,
  } = useContext(RoleTabsContext);

  const onRowSelection = (usersIds: string[]) => {
    setPermissionsToBind(usersIds);
  };

  useEffect(() => {
    rolesService.getManyNotInThisIds(data.permissions!.map((e) => e.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRenderBindButton = (): ReactElement => {
    if (permissionsToBind?.length > 0) {
      return (
        <Box>
          {/* Pulsante aggiungi permesso */}
          <button
            className={`items-center px-10 py-3 border rounded-md bg-blue-500 font-bold disabled:opacity-25`}
            onClick={async () => {
              // @ts-ignore
              bindButtonElement.current!.setAttribute('disabled', true);
              await bindPermissions(
                otherPermissions.filter((op) =>
                  permissionsToBind.includes(op.id)
                )
              );
              // @ts-ignore
              bindButtonElement.current!.removeAttribute('disabled');
              setPermissionsToBind([]);
              setDialogState(false);
            }}
            ref={bindButtonElement}
          >
            <span className='text-white'>
              {intl.formatMessage({ id: 'GENERAL.ADD_USER_DIALOG.BIND_USERS' })}
            </span>
          </button>
        </Box>
      );
    }
    return <></>;
  };

  return (
    <>
      {/* (1) Pulsante aggiungi permesso */}
      <Button
        id='bind-permissions'
        innerText={intl.formatMessage({ id: 'PERMISSION.BIND_NEW_PERMISSION' })}
        otherProps={{
          onClick: () => setDialogState(true),
        }}
      />
      {/* (2) Dialog per binding nuovi permessi */}
      <PermissionsDialogWithRowsSelection
        setDialogState={() => setDialogState(false)}
        isOpen={isOpen}
        title={intl.formatMessage({ id: 'PERMISSION.BIND_NEW_PERMISSION' })}
        ActionButton={handleRenderBindButton()}
      >
        <PermissionsTable
          permissions={otherPermissions!}
          props={{
            loading,
            checkboxSelection: true,
            density: 'compact',
            onSelectionModelChange: (newSelectionModel: string[]) =>
              onRowSelection(newSelectionModel),
          }}
          columnsSchema={permissionsDGColumnsDefinition(intl)}
        />
      </PermissionsDialogWithRowsSelection>
    </>
  );
};

export { AddPermissionSection };
