import { ErrorType } from '../contracts/JobSitesService';
import { VerbalsService } from '../contracts/VerbalsService';
import {
  getFirestore,
  Firestore,
  collection,
  getDocs,
  getDoc,
  query,
  orderBy,
  DocumentSnapshot,
  CollectionReference,
  doc,
  deleteDoc,
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentData,
} from 'firebase/firestore';
import { Verbal } from '../../app/modules/verbals/models/VerbalModel';
import { inject, singleton } from 'tsyringe';
import { GCPUtilities } from '../GCPUtilities';

@singleton()
export class GCPVerbalService extends VerbalsService {
  private readonly firestore: Firestore;
  private readonly verbalsRef: CollectionReference;
  private readonly tenantRef: DocumentReference;

  constructor(@inject('Tenant') tenant: string) {
    super();
    this.firestore = getFirestore();
    this.tenantRef = doc(
      this.firestore,
      GCPUtilities.tenantsCollectionName,
      tenant
    );
    this.verbalsRef = collection(
      this.tenantRef,
      GCPUtilities.verbalsCollectionName
    );
  }

  public get = (id: string): Promise<Verbal | ErrorType> => {
    return Promise.resolve({
      isError: true,
      code: 'not-implemented',
      intlIdCode: 'not-implemented',
    });
  };

  public getAll = async (): Promise<Partial<Verbal>[] | false> => {
    try {
      let verbals: Partial<Verbal>[] = [];
      const verbalsQuery = query(
        this.verbalsRef,
        orderBy('updated_at', 'desc')
      );
      const verbalsSnapshot = await getDocs(verbalsQuery);
      for (const t of verbalsSnapshot.docs) {
        verbals.push(await this.getVerbalData(t));
      }
      return verbals;
    } catch (err: unknown) {
      console.log('Fetch dei verbali fallito', err);
      return false;
    }
  };

  private getVerbalData = async (
    verbal: QueryDocumentSnapshot<DocumentData>
  ) => {
    const allData = verbal.data();
    const signedBy = await this.getUserString(allData.signedBy);
    const pdfUrl = await this.getPdfUrl(verbal.id);
    return {
      ...allData,
      id: verbal.id,
      updatedAt: allData.updated_at,
      signedBy,
      pdfUrl,
    };
  };

  private getPdfUrl = async (verbalId: string): Promise<string> => {
    if (!verbalId) {
      return '';
    }
    return await GCPUtilities.getLastPdfFromCollection(
      this.firestore,
      doc(this.tenantRef, GCPUtilities.verbalsCollectionName, verbalId)
    );
  };

  private getUserString = async (userRef: any): Promise<string> => {
    let signedBy = '';
    if (userRef && typeof userRef !== 'string') {
      const userDoc: DocumentSnapshot = await getDoc(userRef);
      const userDocData = userDoc.data();
      signedBy = `${userDocData?.name} ${userDocData?.surname}`;
    }
    return signedBy;
  };

  public delete = async (verbal: Verbal): Promise<boolean> => {
    try {
      const verbalRef = doc(this.verbalsRef, verbal.id!);
      await deleteDoc(verbalRef);
      return Promise.resolve(true);
    } catch (err: unknown) {
      console.error(`Errore in fase di cancellazione verbale`, err);
      return Promise.resolve(false);
    }
  };
}
