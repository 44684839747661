import { inject, singleton } from 'tsyringe';
import {
  Firestore,
  getFirestore,
  collection,
  getDoc,
  getDocs,
  doc,
  updateDoc,
  DocumentReference,
  runTransaction,
  CollectionReference,
  query,
  where,
  QueryConstraint,
  Transaction,
} from 'firebase/firestore';
import { Filters, UsersService } from '../contracts/UsersService';
import { UserModel } from '../../app/models/UserModel';
import { Tenant } from '../../app/models/Tenant.model';
import { usersCollectionName } from '../GCPUtilities';

@singleton()
export class GCPUsersService extends UsersService {
  private readonly firestore: Firestore;
  private readonly usersCollectionName = usersCollectionName;
  private readonly usersRef: CollectionReference;
  protected readonly tenant: string;

  constructor(@inject('Tenant') tenant: string) {
    super();
    this.tenant = tenant;
    this.firestore = getFirestore();
    this.usersRef = collection(this.firestore, this.usersCollectionName);
  }

  public async getManyInThisTenant(
    filters?: Partial<Filters>
  ): Promise<UserModel[]> {
    try {
      let users: UserModel[] = [];
      const usersQuerySnapshot = await getDocs(
        query(this.usersRef, this.getQueryForUsersInCurrentTenant())
      );
      usersQuerySnapshot.forEach((t: any) =>
        users.push({ ...t.data(), id: t.id })
      );
      return users;
    } catch (error: any) {
      console.error('Fetch Users Fallito');
      return Promise.reject(error);
    }
  }

  private getQueryForUsersInCurrentTenant(): QueryConstraint {
    const currentTenantRef = this.getTenantReference(this.tenant);
    return where('tenants', 'array-contains', currentTenantRef);
  }

  private getTenantReference(id: string): DocumentReference {
    return doc(this.firestore, 'tenants', id);
  }

  public async get(id: string): Promise<UserModel | false> {
    try {
      const userReference = doc(this.firestore, this.usersCollectionName, id);
      const userSnapshot = await getDoc(userReference);
      const userData = await this.getUserData(id);
      const tenants = await this.getTenantsIds(userData!.tenants ?? []);
      // @ts-ignore
      return { ...userData, id: userSnapshot.id, tenants };
    } catch (error: unknown) {
      console.log(`Errore ${error}`);
      return false;
    }
  }

  private async getUserData(userId: string): Promise<any> {
    const userReference = doc(this.firestore, this.usersCollectionName, userId);
    const userSnapshot = await getDoc(userReference);
    return { ...userSnapshot.data(), id: userId };
  }

  private async getTenantsIds(tenants: DocumentReference[]): Promise<Tenant[]> {
    const tenantsIds = [];
    for (let i = 0; i < tenants.length; i++) {
      const tenant = await getDoc(tenants[i]);
      tenantsIds.push({ id: tenant.id, name: tenant.data()!.name });
    }
    return tenantsIds;
  }

  public async create(user: UserModel): Promise<UserModel | false> {
    try {
      throw new Error('Not implemented');
    } catch (error: unknown) {
      console.error('Errore in fase di creazione utente:', error);
      return false;
    }
  }

  public async update(user: UserModel): Promise<UserModel | false> {
    try {
      const userReference = doc(
        this.firestore,
        this.usersCollectionName,
        user.id
      );
      await updateDoc(userReference, user);
      return user;
    } catch (error: unknown) {
      console.error('Errore in fase di creazione utente', error);
      return false;
    }
  }

  private evaluateAddNewTenant(userData: any): DocumentReference[] {
    const tenantReference = doc(this.firestore, 'tenants', this.tenant);
    if (!userData.tenants) {
      return [tenantReference];
    }
    if (this.tenantIsNewForUser(this.tenant, userData)) {
      return [...userData.tenants, tenantReference];
    }
    return userData.tenants;
  }
  private tenantIsNewForUser(
    newTenantId: string,
    userData: UserModel
  ): boolean {
    const existingTenants: string[] = userData.tenants!.map(
      // @ts-ignore
      (t: DocumentReference) => t.id
    );
    const isNewTenant = !existingTenants.includes(newTenantId);
    return isNewTenant;
  }

  public async unbindTenants(usersId: string[]): Promise<boolean> {
    try {
      await runTransaction(this.firestore, async (transaction) => {
        for (let i = 0; i < usersId.length; i++) {
          await this.unbindSingleTenantViaTransaction(usersId[i], transaction);
        }
      });
      return Promise.resolve(true);
    } catch (error: unknown) {
      console.error('Errore in fase di disassociazione utente', error);
      return Promise.resolve(false);
    }
  }

  private async unbindSingleTenantViaTransaction(
    userId: string,
    transaction: Transaction
  ) {
    const userReference = doc(this.firestore, this.usersCollectionName, userId);
    let userData = await this.getUserData(userId);
    const tenantsIds = await this.getTenantsIds(userData.tenants);
    const newTenantsIds = tenantsIds
      .map((t) => t.id!)
      .filter((t: string) => t !== this.tenant);
    const newTenantsReferences = newTenantsIds.map((nt) =>
      doc(this.firestore, 'tenants', nt)
    );
    userData = {
      ...userData,
      tenants: newTenantsReferences,
    };
    transaction.update(userReference, userData);
  }

  public async bindTenants(usersIds: string[]): Promise<boolean> {
    try {
      await runTransaction(this.firestore, async (transaction) => {
        for (let i = 0; i < usersIds.length; i++) {
          await this.bindSingleTenantViaTransaction(usersIds[i], transaction);
        }
      });
      return Promise.resolve(true);
    } catch (error: unknown) {
      console.error('Errore in fase di associazione utente', error);
      return Promise.resolve(false);
    }
  }

  private async bindSingleTenantViaTransaction(
    userId: string,
    transaction: Transaction
  ) {
    const userReference = doc(this.firestore, this.usersCollectionName, userId);
    let userData = await this.getUserData(userId);
    userData = {
      ...userData,
      tenants: this.evaluateAddNewTenant(userData),
    };
    transaction.update(userReference, userData);
  }

  public async delete(id: string): Promise<boolean> {
    try {
      throw new Error('Not implemented');
    } catch (error: unknown) {
      console.error('Errore in fase di cancellazione utente:', error);
      return false;
    }
  }
}
