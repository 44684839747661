import React, { ChangeEvent, FC } from 'react';

type Props = {
  id: string;
  label: string;
  otherProps?: any;
  onChangeInput?: (event: ChangeEvent<HTMLInputElement>, key: any) => void;
};
const CheckboxItem: FC<Props> = ({ id, label, otherProps, onChangeInput }) => {
  return (
    <div className='relative flex items-start'>
      <div className='flex items-center h-5'>
        <input
          id={id}
          name={id}
          type='checkbox'
          {...otherProps}
          className='border-gray-300 w-7 h-7'
          {...(onChangeInput && {
            onChange: (e: ChangeEvent<HTMLInputElement>) =>
              onChangeInput(e, id),
          })}
        />
      </div>
      <div className='ml-3'>{label}</div>
    </div>
  );
};
export { CheckboxItem };
