import { FC } from 'react';

type Props = {
  id: string;
  classes?: string;
  otherProps?: any;
};

const ButtonWrapper: FC<Props> = ({ id, classes, otherProps, children }) => {
  return (
    <button
      id={id}
      data-testid={id}
      {...otherProps}
      className={`items-center px-10 py-3 border rounded-md secondary-bg-lighter font-bold ${classes}`}
    >
      {children}
    </button>
  );
};

export { ButtonWrapper };
