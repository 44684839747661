import { FC, ReactElement } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitleWrapper } from '../../../pages/templates/components/question/types/partials/DialogTitleWrapper';
import { useIntl } from 'react-intl';
import { Tenant } from '../../../models/Tenant.model';
import { DataGridCustomized } from '../../../../theme/DataGrid';
import { dgColumnsDefinition } from './DGSettings';
import { userState } from '../../sikuro_auth/state/atoms/user';
import { useRecoilValue } from 'recoil';
type Props = {
  tenants: Tenant[];
  setDialogStatus: (isOpen: boolean) => void;
  open: boolean;
  loading: boolean;
  onClickOnTenant: (tenant: Tenant) => void;
};
/**
 * Let to a user to change its tenant context
 * @param props.handleClose Function to close dialog
 * @param props.open True if the Dialog must be opened
 **/
const ChangeTenantDialog: FC<Props> = ({
  tenants,
  setDialogStatus,
  open,
  loading,
  onClickOnTenant,
}): ReactElement => {
  const intl = useIntl();
  const userData = useRecoilValue(userState);
  const columns = dgColumnsDefinition(intl, userData.tenant, onClickOnTenant);

  return (
    <Dialog onClose={() => setDialogStatus(false)} open={open} fullWidth={true}>
      <DialogTitleWrapper
        id='switch-dialog-dialog'
        onClose={() => setDialogStatus(false)}
      >
        {intl.formatMessage({
          id: 'CHANGE_TENANT_DIALOG.TITLE',
        })}
      </DialogTitleWrapper>
      <DialogContent dividers className={'overflow-hidden'}>
        <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
          <DataGridCustomized
            columns={columns}
            rows={tenants}
            props={{ loading }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { ChangeTenantDialog };
