import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useHistory, useParams } from 'react-router-dom';
import { formMode } from '../../../models/creationMode.model';
import { container } from 'tsyringe';
import { CompaniesService } from '../../../../service/contracts/CompaniesService';
import { CompanyForm } from './CompanyForm';
import { Company } from '../models/Company.model';

type Props = {
  mode?: formMode;
};

const CompanyWrapper: FC<Props> = ({ mode }) => {
  const intl = useIntl();
  const modality = mode ?? formMode.create;
  let history = useHistory();
  const companiesService =
    container.resolve<CompaniesService>('CompaniesService');
  // @ts-ignore
  const { id } = useParams();
  const [values, setValues] = useState<Partial<Company>>();
  useEffect(() => {
    if (mode === formMode.create) {
      setValues({ name: '', vat_code: '' });
    }
    if (!id) {
      return;
    }
    const getCompany = async (id: string) => {
      const companyFetched = await companiesService.get(id);
      // narrowing seguendo linee guida doc. TS
      if (!companyFetched) {
        console.log('There are errors!');
        return;
      }
      setValues(companyFetched);
    };
    getCompany(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, mode]);

  const handleSubmitEvent = async (data: Company) => {
    if (mode === formMode.create) {
      await companiesService.create(data);
    } else {
      await companiesService.update(data);
    }
    history.push('/companies-summary');
  };

  return (
    <>
      {values && (
        <CompanyForm
          handleSubmitEvent={handleSubmitEvent}
          mode={modality}
          values={values}
        />
      )}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'COMPANY.FORM.NEW' })}
      </PageTitle>
    </>
  );
};

export { CompanyWrapper };
