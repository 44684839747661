import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import componentsOverrides from './overrides/index';

export default function ThemeColorPresets({ children }: any) {
  const defaultTheme = useTheme();
  const theme = createTheme(defaultTheme);

  theme.components = componentsOverrides(defaultTheme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
