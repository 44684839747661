import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  CollectionReference,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore';
import { Company } from '../../app/modules/companies/models/Company.model';
import { CompaniesService } from '../contracts/CompaniesService';
import { inject, singleton } from 'tsyringe';

@singleton()
export class GCPCompaniesService extends CompaniesService {
  private readonly firestore: Firestore;
  private readonly companiesRef: CollectionReference;
  private readonly companiesCollectionName = 'companies';
  protected readonly tenant: string;

  constructor(@inject('Tenant') tenant: string) {
    super();
    this.tenant = tenant;
    this.firestore = getFirestore();
    this.companiesRef = collection(
      doc(this.firestore, 'tenants', this.tenant),
      this.companiesCollectionName
    );
  }

  public create = async (company: Company): Promise<Company | false> => {
    try {
      const docRef = await addDoc(this.companiesRef, company);
      const docSnapshot = await getDoc(docRef);
      const data = {
        ...(docSnapshot.data() as Partial<Company>),
        id: docSnapshot.id,
      };
      return Promise.resolve(data as Company);
    } catch (error: unknown) {
      return Promise.resolve(false);
    }
  };

  public getMany = async (): Promise<Company[] | false> => {
    try {
      let companies: Company[] = [];
      const companiesQuery = query(this.companiesRef, orderBy('name', 'asc'));
      const companiesQuerySnapshot = await getDocs(companiesQuery);
      companiesQuerySnapshot.forEach((t: any) =>
        companies.push({ ...t.data(), id: t.id })
      );
      return companies;
    } catch (error: unknown) {
      return Promise.resolve(false);
    }
  };

  public delete = async (company: Company | Company[]): Promise<boolean> => {
    try {
      if (Array.isArray(company)) {
        for (const c of company) {
          const companyReference = doc(this.companiesRef, c.id!);
          await deleteDoc(companyReference);
        }
      }
      return Promise.resolve(true);
    } catch (error: unknown) {
      return Promise.resolve(false);
    }
  };

  public update = async (company: Company): Promise<boolean> => {
    try {
      const companyReference = doc(this.companiesRef, company.id!);
      await updateDoc(companyReference, company);
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.resolve(false);
    }
  };

  public get = async (id: string): Promise<Company | false> => {
    try {
      const companyReference = await doc(this.companiesRef, id);
      const response = await getDoc(companyReference);
      return { ...response.data(), id: response.id } as Company;
    } catch (error: any) {
      return Promise.resolve(false);
    }
  };
}
