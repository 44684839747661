import { FC } from 'react';
import { SectionModel } from '../models/section.model';
import { formMode } from '../../../models/creationMode.model';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { makeStyles, createStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import Stack from '@mui/material/Stack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Section } from './Section';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { useRecoilState } from 'recoil';
import { templateState } from '../state/template';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    test: {
      '&:before': {
        display: 'none',
      },
      marginTop: '5px',
    },
  })
);

type Props = {
  sections: SectionModel[];
  mode: formMode;
  saveTemplate: () => void;
  templateErrors: { field: string, message: string }[]
};

const DragAndDropSections: FC<Props> = ({ sections, mode, saveTemplate, templateErrors }) => {
  const classes = useStyles();
  const [template, updateTemplate] = useRecoilState(templateState);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    reorder(sections, result.source.index, result.destination.index);
  };

  const reorder = (
    sections: SectionModel[],
    startIndex: number,
    endIndex: number
  ): void => {
    const result = Array.from(sections);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    updateTemplate({
      ...template,
      sections: result,
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppableSections'>
        {(provided: DroppableProvided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {sections.map(
              (section, index: number): ReactJSXElement => (
                <Draggable
                  key={index}
                  draggableId={`${index}-draggable`}
                  index={index}
                >
                  {(provided: DraggableProvided) => (
                    <Accordion
                      classes={{ root: classes.test }}
                      defaultExpanded={section?.expanded}
                      key={index}
                      {...{
                        ...provided.draggableProps,
                        ...provided.dragHandleProps,
                        ref: provided.innerRef,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}a-content-s`}
                        id={`panel${index}a-header-s`}
                      >
                        <Stack direction='row' spacing={1}>
                          <Box>
                            <DragIndicatorIcon></DragIndicatorIcon>
                          </Box>
                          <Box>{`Pagina ${index + 1}`}</Box>
                          {section.title && (
                            <Box sx={{ fontWeight: 'bold' }}>
                              {section.title}
                            </Box>
                          )}
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingTop: 0, paddingX: 4 }}>
                        <Section
                          mode={mode}
                          section={section}
                          key={0}
                          submitAction={saveTemplate}
                          templateErrors={templateErrors}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Draggable>
              )
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default DragAndDropSections;
