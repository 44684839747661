import React, { ChangeEvent } from 'react';
import { FieldInputProps } from 'formik';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

type Props = {
  label: string;
  id: any;
  autocomplete?: string;
  formikProps?: FieldInputProps<any>;
  errorMsg?: string | undefined;
  isTouched?: boolean;
  className?: string;
  thereIsError?: boolean;
  withLabel?: boolean;
  placeholder?: string;
  value?: string | number;
  onChangeInput?: (event: ChangeEvent<HTMLSelectElement>, key: any) => void;
  otherProps?: any;
};

const Select: React.FC<Props> = ({
  label,
  id,
  autocomplete = 'off',
  formikProps,
  errorMsg = '',
  isTouched = false,
  thereIsError = false,
  className = 'fv-row',
  children,
  withLabel = true,
  placeholder,
  value,
  onChangeInput,
  otherProps,
}) => {
  const intl = useIntl();
  placeholder =
    placeholder ??
    intl.formatMessage({ id: 'GENERAL.TABLE.SELECT_CHOOSE_OPTION' });

  const getElementClass = (
    isTouched: boolean,
    thereIsError: boolean
  ): string => {
    /* form-control form-control-lg form-control-solid => NON sono classi BS */
    return clsx(
      'input-background form-control-solid w-full',
      {
        'is-invalid': isTouched && thereIsError,
      },
      {
        'is-valid': isTouched && !thereIsError,
      }
    );
  };

  const getElementErrorDiv = (errorMsg: string): JSX.Element => {
    return errorMsg ? (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          <span role='alert'>{errorMsg}</span>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div className={className}>
      {withLabel && (
        <label htmlFor={id} className='text-lg'>
          {label}
        </label>
      )}
      <select
        id={id}
        data-testid={id}
        name={id}
        className={clsx(
          'rounded-md',
          `border-${thereIsError ? 'red' : 'grey'}-300`,
          getElementClass(isTouched, thereIsError)
        )}
        autoComplete={autocomplete}
        value={value}
        {...formikProps}
        {...otherProps}
        {...(onChangeInput && {
          onChange: (e: ChangeEvent<HTMLSelectElement>) => onChangeInput(e, id),
        })}
      >
        <option value='' disabled>
          {placeholder}
        </option>
        {children}
      </select>
      {getElementErrorDiv(errorMsg)}
    </div>
  );
};

export { Select };
