import React, {FC, Fragment, useRef} from 'react'
import {useIntl} from "react-intl";
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';


const backdropJSX = <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-25"
    leave="ease-in duration-300"
    leaveFrom="opacity-25"
    leaveTo="opacity-0"
>
    <div className="fixed inset-0 bg-gray-900"/>
</Transition.Child>;

type Props = {
    open: boolean,
    setModalState: (state: boolean) => void,
    onConfirm: CallableFunction,
    entityString: string
}


const DeletionModal: FC<Props> = ({open, setModalState, onConfirm, entityString}) => {
    const intl = useIntl();
    const cancelButtonRef = useRef(null);

    const handleConfirmAction = () => {
        onConfirm();
        setModalState(false);
    }

    const handleRenderButtons = (): JSX.Element => {
        return <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border
                           border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium
                           text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2
                           focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => handleConfirmAction()}
            >
                {intl.formatMessage({ id: 'GENERAL.FORM.CONFIRM' })}
            </button>
            <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-gray-300
                          shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700
                          hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
                          focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => setModalState(false)}
                ref={cancelButtonRef}
            >
                {intl.formatMessage({ id: 'GENERAL.FORM.CANCEL' })}
            </button>
        </div>
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef}
                    onClose={() => setModalState(false)}>
                {backdropJSX}
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl
                             transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="flex-shrink-0 flex items-center justify-center h-12 w-12
                                        rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                        <div className="text-left mt-0 ml-4">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                {intl.formatMessage({ id: 'GENERAL.FORM.DELETION_TITLE' })}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    {intl.formatMessage({ id: 'GENERAL.MODAL.DELETE_CONFIRMATION' })} <span className={"font-bold"}>{entityString}</span>?
                                                </p>
                                                <p className="text-sm text-gray-500">
                                                    {intl.formatMessage({ id: 'GENERAL.MODAL.DELETE_CONFIRMATION_DESCRIPTION' })}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {handleRenderButtons()}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export {DeletionModal}
