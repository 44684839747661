import { VerbalTemplate } from '../../app/pages/templates/models/template.model';
import { Category } from '../../app/modules/categories/models/Category.model';

export abstract class TemplateService {
  abstract getTemplates(): Promise<VerbalTemplate[] | false>;
  abstract getTemplate(id: string): Promise<VerbalTemplate | false>;
  abstract create(
    template: VerbalTemplate,
    is_copying_images?: boolean
  ): Promise<VerbalTemplate | false>;
  abstract deleteTemplate(template: VerbalTemplate): Promise<boolean>;
  abstract update(template: VerbalTemplate): Promise<boolean>;
  abstract getTemplatesFiltered(filters: {
    categories: Partial<Category>[];
  }): Promise<VerbalTemplate[] | false>;
}
