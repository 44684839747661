import React, { FC, useEffect, useState } from 'react';
import { Alert, Input, Skeleton, Snackbar } from '@mui/material';
import { useIntl } from 'react-intl';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { getStorage, ref } from 'firebase/storage';
import { container } from 'tsyringe';
import { ImagesService } from '../../../../service/contracts/ImagesService';
import { onChangeImagesFn } from '../../../../app/pages/templates/components/question/types/InstructionType';
import { ImageData } from '../../../../app/pages/templates/models/question.model';

const skeletonImage = (
  <div>
    <Skeleton variant='rectangular' height={150} sx={{ borderRadius: 2 }} />
  </div>
);

type Props = {
  id: string;
  label: string;
  actionDescription?: string;
  info?: string;
  limit?: number;
  onChangeImages: onChangeImagesFn;
  images: ImageData[];
};
const UploadImagesForm: FC<Props> = ({
  id,
  label,
  actionDescription,
  info,
  limit = 10,
  onChangeImages,
  images,
}) => {
  const intl = useIntl();
  const storage = getStorage();
  const imagesService = container.resolve<ImagesService>('ImagesService');
  actionDescription =
    actionDescription ?? intl.formatMessage({ id: 'GENERAL.FORM.LOAD_IMAGE' });
  info =
    info ?? intl.formatMessage({ id: 'GENERAL.FORM.INFO_IMAGE' }, { limit });
  const [imagesProcessed, setImagesProcessed] = useState<ImageData[]>(images);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
  const [imagesJsx, setImagesJsx] = useState<any[]>([]);
  const [uploadError, setUploadError] = useState<string>('');
  const [loadingImage, setLoadingImage] = useState<ReactJSXElement>(<></>);

  /**
   * Whene an Image is uploaded => update imagesProcessed array
   * @param fileImagesUploaded: File array coming from input field
   */
  const handleImagesUploaded = (fileImagesUploaded: File[]): void => {
    if (fileImagesUploaded.length < 1) {
      return;
    }
    // Get only valid images
    const validImages = fileImagesUploaded.filter(
      (i: File) => !imageIsTooBig(i)
    );
    // TODO: gestire la messaggistica di errore nel caso in cui ci sia almeno una immagine non valida
    setImagesProcessed([
      ...imagesProcessed.filter((i) => !imagesToDelete.includes(i.uri)),
      ...validImages.map((vImage: File) => ({
        uri: URL.createObjectURL(vImage),
        type: 'fromLocal' as 'fromLocal',
        file: vImage,
      })),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  /**
   * Handle render images JSX
   */
  useEffect(() => {
    if (imagesProcessed && imagesProcessed.length === 0) {
      return;
    }
    setLoadingImage(skeletonImage);
    const fetchData = async () => {
      // You can await here
      const jsxImages = imagesProcessed.map(
        async (imageData: ImageData, index: number) => {
          const imgUrl = await getImageUrl(imageData);
          return {
            ...imageData,
            url: imgUrl,
          };
        }
      );
      const imagesToRender = await Promise.all(jsxImages);
      setImagesJsx(imagesToRender);
      setLoadingImage(<></>);
    };
    fetchData();
    onChangeImages(imagesProcessed, 'images');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesProcessed]);

  const handleRenderImages = (): ReactJSXElement[] => {
    return imagesJsx.map((imageData: any, index: number) => {
      return (
        <div key={index}>
          <div className={'text-center'}>
            <a
              href={imageData.url}
              target='_blank'
              rel='noopener noreferrer'
              download
            >
              <img
                className='object-fill h-48 w-100 object-scale-down'
                alt={`uploaded-img-${index}`}
                src={imageData.url}
              />
            </a>
            <button
              type='button'
              className='font-medium cursor-pointer'
              onClick={() => deleteImage(index, imageData)}
            >
              {intl.formatMessage({ id: 'GENERAL.FORM.DELETE' })}
            </button>
          </div>
        </div>
      );
    });
  };

  /**
   * Handle getting image url: if from cloud (1) it must be retrieved via its own service otherwise (2) it is a local image
   */
  const getImageUrl = async (imageData: ImageData) => {
    // (1)
    if (imageData.type === 'fromCloud') {
      const imgRef = ref(storage, imageData.uri);
      return await imagesService.getImageUrl(imgRef);
    }
    // (2)
    return imageData.uri;
  };

  /**
   * Trigger template update with images uris to delete
   */

  useEffect(() => {
    if (!imagesToDelete || imagesToDelete.length === 0) {
      return;
    }
    onChangeImages(imagesToDelete, 'imagesToDelete');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesToDelete]);

  // const manageImagesUploaded = () => {
  //   if (fileImagesUploaded.length < 1) {
  //     return;
  //   }
  //   const validImages = fileImagesUploaded.filter(
  //     (i: File) => !imageIsTooBig(i)
  //   );
  //   validImages.forEach(async (imageFile: File) => {
  //     try {
  //       const storageRef = ref(storage, uuidv4());
  //       const snapshot = await imagesService.uploadBytesImage(
  //         storageRef,
  //         imageFile
  //       );
  //       setImagesProcessed([
  //         ...imagesProcessed,
  //         `gs://${snapshot.ref.bucket}/${snapshot.ref.fullPath}`,
  //       ]);
  //       setUploadError('');
  //     } catch (err: unknown) {
  //       console.log('Caricamento immagine Fallito!', err);
  //       setUploadError(
  //         intl.formatMessage({
  //           id: 'QUESTION.INSTRUCTION_UPLOAD_IMAGES_UPLOAD_ERROR',
  //         })
  //       );
  //     }
  //   });
  // };

  /**
   * True if the @param image size is greater than 'limit' property.
   */
  const imageIsTooBig = (image: File): boolean => {
    return image.size / 1048576 > limit;
  };

  /**
   * Delete an image. The uri reference isn't removed immediatly from cloud storage because in that case the reference
   * would be lost . For this reason i fill 'imagesToDelete' array that the template service will use to delete
   * the images.
   * (1) - Update images JSX elements
   * (2) - Fill the 'imagesToDelete' array
   * @param index: the element index position of the image to delete
   * @param imageData: from this I know if the image is from cloud or from local
   */
  const deleteImage = async (index: number, imageData: ImageData) => {
    try {
      // (1)
      const clone = [...imagesJsx];
      clone.splice(index, 1);
      setImagesJsx(clone);
      // (2)
      if (imageData.type === 'fromCloud') {
        setImagesToDelete([...imagesToDelete, imageData.uri]);
      }
      setUploadError('');
    } catch (err: unknown) {
      setUploadError(
        intl.formatMessage({
          id: 'QUESTION.INSTRUCTION_UPLOAD_IMAGES_DELETE_ERROR',
        })
      );
    }
  };

  return (
    <div className={'fv-row'}>
      <label className='text-lg'>{label}</label>
      {/* Upload form input */}
      <div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
        <label
          htmlFor={id}
          className='w-full relative cursor-pointer rounded-md font-medium'
        >
          <div className='space-y-1 text-center'>
            <svg
              className='mx-auto h-12 w-12 text-gray-400'
              stroke='currentColor'
              fill='none'
              viewBox='0 0 48 48'
              aria-hidden='true'
            >
              <path
                d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0
                              01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0
                              0l4 4m4-24h8m-4-4v8m-12 4h.02'
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <div className='flex text-sm text-center justify-center'>
              <p className={'text-lg'}>{actionDescription}</p>
              <Input
                id={id}
                name={id}
                type='file'
                className='sr-only'
                inputProps={{ accept: 'image/*' }}
                onChange={(e: React.ChangeEvent<any>) =>
                  handleImagesUploaded([...e.target.files])
                }
              />
            </div>
            <p className='text-xs'>{info}</p>
          </div>
        </label>
      </div>
      {/* Images Uploaded */}
      <div className={'w-full'}>
        <div
          className={
            'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 my-5'
          }
        >
          {handleRenderImages()}
          {loadingImage}
        </div>
      </div>
      {/* Error Snackbar */}
      <Snackbar
        open={!!uploadError}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setUploadError('')}
        autoHideDuration={6000}
      >
        <Alert severity='error'>{uploadError}</Alert>
      </Snackbar>
    </div>
  );
};
export { UploadImagesForm };
