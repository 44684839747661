import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { IntlShape } from 'react-intl';
import { DataGridActionsColumn } from '../../../../_metronic/components/datagrid/DataGridActionsColumn';
import { formMode } from '../../../models/creationMode.model';
import { showDateInItFormat } from '../../../../_metronic/utilities/utilities';
import { IconButton } from '@mui/material';
import Copy from '@mui/icons-material/FileCopy';
import { handleRenderCategoriesChips } from '../../../pages/templates/settings/DataGrid';

export const dgColumnsDefinition = (
  intl: IntlShape,
  onDelete: CallableFunction,
  handleOpenNCCopyModal: Function
): GridColDef[] => [
  {
    field: 'name',
    headerName: intl.formatMessage({ id: 'NON_CONFORMITY.TITLE_LABEL' }),
    sortable: true,
    width: 500,
  },
  {
    field: 'categories',
    headerName: intl.formatMessage({ id: 'TEMPLATES.TABLE.CATEGORIESS' }),
    sortable: true,
    flex: 1,
    renderCell: (params: GridValueGetterParams): ReactJSXElement =>
      handleRenderCategoriesChips(params.row.categories),
  },
  {
    field: 'created_at',
    headerName: intl.formatMessage({ id: 'GENERAL.CREATED_AT' }),
    sortable: true,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => {
      // @ts-ignore
      return showDateInItFormat(params.value);
    },
  },
  {
    field: 'updated_at',
    headerName: intl.formatMessage({ id: 'GENERAL.UPDATED_AT' }),
    sortable: true,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => {
      // @ts-ignore
      return showDateInItFormat(params.value);
    },
  },
  {
    field: 'sub',
    headerName: intl.formatMessage({ id: 'GENERAL.TABLE.ACTIONS_LABEL' }),
    renderCell: (param: GridRenderCellParams<string>): ReactJSXElement => (
      <DataGridActionsColumn
        param={param}
        editPath={`/non-conformity/${formMode.edit}/${param.row.id}`}
        onDelete={onDelete}
      >
        <IconButton
          onClick={() =>
            handleOpenNCCopyModal({
              id: param.id,
              name: param.row.name,
            })
          }
        >
          <Copy />
        </IconButton>
      </DataGridActionsColumn>
    ),
    sortable: false,
    filterable: false,
    hide: false,
    disableColumnMenu: true,
    width: 120,
  },
];
