import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '5px',
  p: 4,
};

type Props = {
  open: boolean;
  setOpen: Function;
  entityProps: { id: string; inputValue: string };
  handleSubmit: Function;
  labels: { inputLabel: string; helpText: string };
};

const SimpleModalWithText: FC<Props> = ({
  open,
  setOpen,
  entityProps,
  handleSubmit,
  labels,
}) => {
  const [textValue, setTextValue] = useState<string>('');

  useEffect(() => {
    setTextValue(entityProps.inputValue);
  }, [entityProps]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            await handleSubmit({ id: entityProps.id, inputValue: textValue });
          }}
        >
          <Box sx={style}>
            {/* Input field with label */}
            <TextField
              id={uuidv4()}
              label={labels.inputLabel}
              variant='outlined'
              fullWidth
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
            />
            {/* Help Text */}
            <Typography sx={{ paddingTop: 1, fontStyle: 'italic' }}>
              {labels.helpText}
            </Typography>
          </Box>
        </form>
      </Modal>
    </>
  );
};
export default SimpleModalWithText;
