import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { NonConformitiesTable } from './NonConformitiesTable';

const NonConformitiesTableWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.NON_CONFORMITIES' })}
      </PageTitle>
      <NonConformitiesTable />
    </>
  );
};

export { NonConformitiesTableWrapper };
