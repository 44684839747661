import { Theme } from '@mui/material/styles';

export const defaultDataGridProps = {
  pageSize: 25,
  autoHeight: true,
  disableSelectionOnClick: true,
};

export default function DataGrid(theme: Theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          border: `1px solid transparent`,
          '& .MuiTablePagination-root': {
            borderTop: 0,
          },
          backgroundColor: theme.palette.background.default,
        },
        cell: {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        columnSeparator: {
          display: 'none',
        },
        columnHeaders: {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: theme.palette.background.default,
        },
        columnHeaderTitle: {
          color: theme.palette.text.secondary,
          fontSize: '1.1em',
        },
      },
    },
  };
}
