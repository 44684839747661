import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '../../../../../../_metronic/components/Button';
import { container } from 'tsyringe';
import { Link } from 'react-router-dom';
import { RolesService } from '../../../../../../service/contracts/RolesService';
import { GeneralTable } from '../../../../../components/GeneralTable';
import { Role } from '../../../models/Role.model';
import { dgColumnsDefinition } from './DataGrid';

const RolesSection: FC = () => {
  const intl = useIntl();
  const rolesService = container.resolve<RolesService>('RolesService');
  const columns = dgColumnsDefinition(intl);

  return (
    <div className='container mw-100 px-0'>
      {/* Pulsante Nuovo Ruolo */}
      <div className={'mb-2'}>
        <Link to={'/role'}>
          <Button
            classes='mr-2'
            id='new-role'
            innerText={intl.formatMessage({
              id: 'COMPANY.BUTTONS.NEW:ROLE',
            })}
          />
        </Link>
      </div>
      {/* Tabella Roles */}
      <GeneralTable
        entityName='role'
        entitiesService={rolesService}
        columns={columns}
        toEntityString={(row: Role) => row.roleName}
      />
    </div>
  );
};

export { RolesSection };
