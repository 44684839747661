import { UserModel } from '../../app/models/UserModel';

export type Filters = {
  name: string;
  surname: string;
  email: string;
  tenants: string[];
};
export type UserErrorType = {
  isError: true;
  code: string;
  description: string | null; // code da fornire a intl per una successiva traduzione
};
export abstract class UsersService {
  abstract getManyInThisTenant(
    filters?: Partial<Filters>
  ): Promise<UserModel[]>;
  abstract get(id: string): Promise<UserModel | false>;
  abstract create(user: UserModel): Promise<UserModel | false>;
  abstract update(user: UserModel): Promise<UserModel | false>;
  abstract delete(id: string): Promise<boolean>;
  abstract unbindTenants(usersId: string[]): Promise<boolean>;
  abstract bindTenants(usersId: string[]): Promise<boolean>;
}
