import { Box } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IntlShape } from 'react-intl';
import { handleRenderCategoryChip } from '../../../../../../_metronic/utilities/utilities';
import { Permission } from '../../../models/Permission.model';

export const handleRenderPermissionsChips = (
  permissions: Permission[],
  characterLimit = 20,
  chipNumberLimit = 3
): React.ReactNode => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      {permissions
        .slice(0, chipNumberLimit)
        .map((permission: Partial<Permission>, index: number) => (
          <Box sx={{ pl: 1 }} key={index}>
            {handleRenderCategoryChip(permission.label!, characterLimit)}
          </Box>
        ))}
      {permissions.length > chipNumberLimit && (
        <Box sx={{ pl: 1 }}> + {permissions.length - chipNumberLimit}</Box>
      )}
    </Box>
  );
};

export const dgColumnsDefinition = (intl: IntlShape): GridColDef[] => [
  {
    field: 'roleName',
    headerName: intl.formatMessage({ id: 'ROLE.MODEL.LABELS.NAME' }),
    sortable: true,
    flex: 4,
  },
  {
    field: 'permissions',
    headerName: intl.formatMessage({ id: 'ROLE.MODEL.LABELS.PERMISSIONS' }),
    sortable: true,
    flex: 3,
    renderCell: (params: GridValueGetterParams) =>
      handleRenderPermissionsChips(params.row.permissions || []),
  },
];
