import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { container } from 'tsyringe';
import { Link } from 'react-router-dom';
import { Button } from '../../../../_metronic/components/Button';
import { DeletionModal } from '../../../../_metronic/components/modals/DeletionModal';
import { NonConformityTemplate } from '../models/nonConformity.model';
import { NonConformityTemplateService } from '../../../../service/contracts/NonConformityTemplateService';
import { dgColumnsDefinition } from '../settings/DataGrid';
import SimpleModalWithText from '../../../pages/templates/components/SimpleModalWithText';
import { DataGridCustomized } from '../../../../theme/DataGrid';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { nonConformityState } from '../state/nonConformity';
import { CategoriesAutocomplete } from '../../../pages/templates/components/CategoriesAutocomplete';
import { CategoriesService } from '../../../../service/contracts/CategoriesService';
import { Category } from '../../categories/models/Category.model';
import { userState } from '../../sikuro_auth/state/atoms/user';

const NonConformityTable: FC = () => {
  const intl = useIntl();
  const reset = useResetRecoilState(nonConformityState);
  const nonConformityService = container.resolve<NonConformityTemplateService>(
    'NonConformityTemplateService'
  );
  const categoriesService =
    container.resolve<CategoriesService>('CategoriesService');
  const [categories, setCategories] = useState<Partial<Category>[]>([]);
  const [categoriesChoosed, setCategoriesChoosed] = useState<
    Partial<Category>[]
  >([]);
  const [nonConformities, setNonConformities] = useState<
    NonConformityTemplate[]
  >([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [nonConformityToDelete, setNonConformityToDelete] =
    useState<NonConformityTemplate>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openCopyNCModal, setOpenCopyNCModal] = useState<boolean>(false);
  const [ncDataToCopy, setNcDataToCopy] = useState<{
    id: string;
    inputValue: string;
  }>({
    id: '',
    inputValue: '',
  });
  const [letFetch, setLetFetch] = useState<boolean>(false);
  const userData = useRecoilValue(userState);

  const getCategories = async () => {
    let response: Partial<Category>[] | false =
      await categoriesService.getAll();
    if (typeof response === 'boolean') {
      return;
    }
    setCategories(
      response.map((category) => ({ id: category.id, tag: category.tag }))
    );
  };

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteNonConformity = async () => {
    if (!nonConformityToDelete) return;
    await nonConformityService.delete(nonConformityToDelete);
    setOpenDeleteModal(false);
    setLetFetch(!letFetch);
  };

  const openDeletionModal = (nonConformity: NonConformityTemplate) => {
    setOpenDeleteModal(true);
    setNonConformityToDelete(nonConformity);
  };

  const nonConformityString = (): string => {
    if (!nonConformityToDelete) return '';
    return nonConformityToDelete.name;
  };

  /**
   * Handle Verbal Template copy
   */
  const handleOpenNCCopyModal = async (data: { id: string; name: string }) => {
    setOpenCopyNCModal(true);
    setNcDataToCopy({
      id: data.id,
      inputValue: `${data.name}_Copy`,
    });
  };

  const handleNcCopy = async (data: { id: string; inputValue: string }) => {
    try {
      const template = (await nonConformityService.get(
        data.id
      )) as NonConformityTemplate;
      const ncWithNewName = {
        ...template,
        name: data.inputValue,
      };
      await nonConformityService.create(ncWithNewName, true);
      setOpenCopyNCModal(false);
      setNcDataToCopy({ id: '', inputValue: '' });
      setLetFetch(!letFetch);
    } catch (err: unknown) {
      console.error('Duplicazione della NC Fallita', err);
    }
  };

  const columns = dgColumnsDefinition(
    intl,
    openDeletionModal,
    handleOpenNCCopyModal
  );

  const onCategoriesChange = (event: any, values: any) => {
    setCategoriesChoosed(values);
    setLetFetch(!letFetch);
  };

  useEffect(() => {
    const fetchNCFiltered = async () => {
      setLoading(true);
      try {
        const t = await nonConformityService.getFiltered({
          categories: categoriesChoosed,
        });
        if (!t) {
          return;
        }
        setNonConformities(t);
      } catch (err: unknown) {
        console.log('Fetch Template Fallito!');
      } finally {
        setLoading(false);
      }
    };
    getCategories();
    fetchNCFiltered();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letFetch, userData]);

  return (
    <div className='container mw-100 px-0'>
      <div className={'mb-2'}>
        <Link to={'/non-conformity'}>
          <Button
            classes='mr-2'
            id='new-job-site'
            innerText={intl.formatMessage({ id: 'MENU.CREATE_NON_CONFORMITY' })}
          />
        </Link>
      </div>
      <div className={'container mw-100 primary-bg rounded-xl mt-5 p-5 h-auto'}>
        <label className='text-lg'>
          {intl.formatMessage({
            id: 'TEMPLATE.FILTER.CATEGORIES',
          })}
        </label>
        <CategoriesAutocomplete
          categories={categories}
          onCategoriesChange={onCategoriesChange}
          value={categoriesChoosed}
        />
      </div>
      <div className='h-full mt-5'>
        <DataGridCustomized
          columns={columns}
          rows={nonConformities}
          props={{ loading }}
        />
      </div>
      <DeletionModal
        open={openDeleteModal}
        setModalState={setOpenDeleteModal}
        onConfirm={deleteNonConformity}
        entityString={nonConformityString()}
      />
      <SimpleModalWithText
        entityProps={ncDataToCopy}
        open={openCopyNCModal}
        setOpen={setOpenCopyNCModal}
        handleSubmit={handleNcCopy}
        labels={{
          inputLabel: intl.formatMessage({
            id: 'DUPLICATE_TEMPLATE_MODAL.NEW_TEMPLATE_NAME_LABEL',
          }),
          helpText: intl.formatMessage({
            id: 'SIMPLE_MODAL_TEXT.HELP_TEXT',
          }),
        }}
      />
    </div>
  );
};

export { NonConformityTable };
