import React from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';

type Props<T> = {
  saveEntitiesAfterDrag: (entitiesReordered: T[]) => void;
  innerComponentProps: any;
  initialEntities: T[];
  children?: React.ReactNode;
};

export const DragDropQuestions = <T,>({
  saveEntitiesAfterDrag,
  innerComponentProps,
  initialEntities,
  children,
}: Props<T>): JSX.Element => {
  /**
   * Chiamata eseguita alla fine del trascinamento
   * */
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    saveEntitiesAfterDrag(
      reorder(initialEntities, result.source.index, result.destination.index)
    );
  };

  const reorder = (list: T[], startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided: DroppableProvided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {initialEntities.map((entity: T, index: number) => (
              <Draggable
                key={index}
                draggableId={`${index}-draggable`}
                index={index}
              >
                {(provided: DraggableProvided) =>
                  //@ts-ignore
                  React.cloneElement(children, {
                    ...innerComponentProps,
                    otherProps: {
                      ...provided.draggableProps,
                      ...provided.dragHandleProps,
                      ref: provided.innerRef,
                      index,
                    },
                    entity,
                  })
                }
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropQuestions;
