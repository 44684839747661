import { ErrorType, JobSitesService } from '../contracts/JobSitesService';
import { AuthErrorType } from '../contracts/AuthService';
import {
  collection,
  doc,
  Firestore,
  getDocs,
  getFirestore,
  query,
  deleteDoc,
  updateDoc,
  getDoc,
  addDoc,
  orderBy,
  CollectionReference,
} from 'firebase/firestore';
import { inject, singleton } from 'tsyringe';
import { JobSiteModel } from '../../app/models/JobSite';

@singleton()
export class GCPJobSitesService extends JobSitesService {
  private readonly firestore: Firestore;
  private readonly jobSitesCollectionName = 'jobSites';
  private readonly jobSitesRef: CollectionReference;
  protected readonly tenant: string;

  constructor(@inject('Tenant') tenant: string) {
    super();
    this.tenant = tenant;
    this.firestore = getFirestore();
    this.jobSitesRef = collection(
      doc(this.firestore, 'tenants', tenant),
      this.jobSitesCollectionName
    );
  }

  public create = async (jobSite: JobSiteModel): Promise<true | ErrorType> => {
    try {
      await addDoc(this.jobSitesRef, jobSite);
      return Promise.resolve(true);
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  public getAll = async (): Promise<JobSiteModel[] | ErrorType> => {
    try {
      let jobSites: JobSiteModel[] = [];
      const jobSitesQuery = query(this.jobSitesRef, orderBy('name', 'asc'));
      const jobSitesQuerySnapshot = await getDocs(jobSitesQuery);
      jobSitesQuerySnapshot.forEach((t: any) =>
        jobSites.push({ ...t.data(), id: t.id })
      );
      return jobSites;
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  public delete = async (jobSite: JobSiteModel): Promise<true | ErrorType> => {
    try {
      const jobSiteReference = doc(this.jobSitesRef, jobSite.id!);
      await deleteDoc(jobSiteReference);
      return Promise.resolve(true);
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  public update = async (jobSite: JobSiteModel): Promise<true | ErrorType> => {
    try {
      const jobSiteReference = doc(this.jobSitesRef, jobSite.id!);
      await updateDoc(jobSiteReference, jobSite);
      return Promise.resolve(true);
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  public get = async (id: string): Promise<JobSiteModel | ErrorType> => {
    try {
      const jobSiteReference = await doc(this.jobSitesRef, id);
      const response = await getDoc(jobSiteReference);
      // @ts-ignore
      return { ...response.data(), id: response.id };
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  private manageErrorCode(error: any): AuthErrorType {
    return {
      isError: true,
      code: error.code,
      intlIdCode: this.decodeErrorCode(error),
    };
  }

  public async unbindUser(jobSiteId: string, userId: string): Promise<boolean> {
    try {
      const jobSite = (await this.get(jobSiteId)) as JobSiteModel;
      const usersUpdated = jobSite.users.filter((u) => u.id !== userId);
      const jobSiteReference = doc(this.jobSitesRef, jobSite.id!);
      await updateDoc(jobSiteReference, { ...jobSite, users: usersUpdated });
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.resolve(false);
    }
  }

  public async bindUsers(
    jobSiteId: string,
    usersIds: string[]
  ): Promise<boolean> {
    try {
      const jobSite = (await this.get(jobSiteId)) as JobSiteModel;
      const existingUsers = jobSite.users ?? [];
      const existingUsersIds = existingUsers.map((u) => u.id);
      const newIds = usersIds.filter((id) => !existingUsersIds.includes(id));
      const usersUpdated = [
        ...existingUsers,
        ...newIds.map((id) => doc(this.firestore, 'users', id)),
      ];
      await updateDoc(doc(this.jobSitesRef, jobSite.id!), {
        ...jobSite,
        users: usersUpdated,
      });
      return Promise.resolve(true);
    } catch (error: unknown) {
      console.error('Errore in fase di associazione utente', error);
      return Promise.resolve(false);
    }
  }

  private decodeErrorCode = (e: any): string => {
    if (e?.code === 'auth/network-request-failed')
      return 'AUTH.GCP.NETWORK_REQUEST_FAILED';
    if (e?.code === 'auth/user-not-found') return 'AUTH.GCP.USER_NOT_FOUND';
    if (e?.code === 'auth/wrong-password') return 'AUTH.GCP.WRONG_PASSWORD';
    return 'AUTH.GENERAL.SOMETHING_WENT_WRONG';
  };
}
