import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  query,
  runTransaction,
  orderBy,
  DocumentReference,
  where,
  CollectionReference,
} from 'firebase/firestore';
import {
  deleteObject,
  FirebaseStorage,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { QuestionModel } from '../../app/pages/templates/models/question.model';
import { QuestionType } from '../../app/pages/templates/models/questionType.enum';
import { SectionModel } from '../../app/pages/templates/models/section.model';
import { VerbalTemplate } from '../../app/pages/templates/models/template.model';
import { TemplateService } from '../contracts/TemplateService';
import { v4 as uuidv4 } from 'uuid';
import { Category } from '../../app/modules/categories/models/Category.model';
import { inject, singleton } from 'tsyringe';
import { JobSiteModel } from '../../app/models/JobSite';

export const getCategoriesObj = async (
  categories: DocumentReference<Category>[]
): Promise<Partial<Category>[]> => {
  const categoriesObject = [];
  if (!categories) {
    return [];
  }
  for (const categoryRef of categories) {
    const categorySnapshot = await getDoc(categoryRef);
    categoriesObject.push({
      id: categorySnapshot.id,
      tag: categorySnapshot.data()!.tag,
    });
  }
  return categoriesObject;
};

export const getJobSiteDataFromReference = async (
  jobSite: DocumentReference
): Promise<{ id: string; data: Partial<JobSiteModel> }> => {
  const jobSiteSnapshot = await getDoc(jobSite);
  return {
    id: jobSiteSnapshot.id,
    data: jobSiteSnapshot.data()!,
  };
};

export const getCategoriesRef = (
  firestore: Firestore,
  categories: Partial<Category>[],
  tenant: string
): DocumentReference[] => {
  const categoriesRef = [];
  for (const category of categories) {
    categoriesRef.push(
      doc(
        collection(doc(firestore, 'tenants', tenant), categoriesCollectionName),
        category.id!
      )
    );
  }
  return categoriesRef;
};

export const deleteImagesFromCloud = async (
  uriImages: string[]
): Promise<void> => {
  for (const imageUri of uriImages) {
    const desertRef = ref(getStorage(), imageUri);
    await deleteObject(desertRef);
  }
};

export const adaptQuestionsInCreation = async (
  questions: any[],
  storage: FirebaseStorage
) => {
  let _questions: any[] = [];
  for (const question of questions) {
    const uris = await uploadImagesAndGetTheirUris(question, storage);
    await deleteImagesFromCloud(question.imagesToDelete || []);
    const { imagesToDelete, ...questionWithoutImagesToDelete } = question;
    _questions = [
      ..._questions,
      {
        ...questionWithoutImagesToDelete,
        images: uris,
      },
    ];
  }
  return _questions;
};

/**
 * Get all uris of the images (of a question) to upload
 * (1) - If an image must be delete => don't do nothing because here we are only updating
 * (2) - If an image is already stored in google storage => get its uri and nothing more
 * (3) - Create a new image if coming from local storage
 * @param question: question containing images to upload and to delete
 * @param storage: firebase storage
 */
export const uploadImagesAndGetTheirUris = async (
  question: QuestionModel,
  storage: FirebaseStorage
): Promise<string[]> => {
  let uris: string[] = [];
  for (const imageObj of question.images) {
    // (1)
    if (
      question.imagesToDelete &&
      question.imagesToDelete.includes(imageObj.uri)
    ) {
      continue;
    }
    // (2)
    let newUri = imageObj.uri;
    if (imageObj.type === 'fromLocal') {
      const fileExtension = imageObj!.file!.name.split('.').pop();
      const file_name = `${uuidv4()}.${fileExtension}`;
      const storageRef = ref(storage, file_name);
      const snapshot = await uploadBytes(storageRef, imageObj.file!);
      newUri = `gs://${snapshot.ref.bucket}/${snapshot.ref.fullPath}`;
    }
    uris = [...uris, newUri];
  }
  return uris;
};

export const handleImagesToDelete = async (
  question: QuestionModel,
  storage: FirebaseStorage
): Promise<void> => {
  for (const qi of question.imagesToDelete) {
    const imgRef = ref(storage, qi);
    await deleteObject(imgRef);
  }
};

export const adaptQuestionsInReading = (questions: any[]) => {
  return questions.map((question: any) => {
    return {
      ...question,
      images: question.images
        ? question.images.map((img: string) => {
            return {
              type: 'fromCloud',
              uri: img,
            };
          })
        : [],
    };
  });
};

export const questionsCollectionName = 'questions';
export const categoriesCollectionName = 'categories';
export const rowsCollectionName = 'rows';
export const jobSitesCollectionName = 'jobSites';

@singleton()
export class GCPTemplateService extends TemplateService {
  private readonly firestore: Firestore;
  private readonly storage: FirebaseStorage;
  private readonly templatesCollectionName = 'verbalTemplates';
  protected readonly tenant: string;
  private readonly templatesRef: CollectionReference;

  constructor(@inject('Tenant') tenant: string) {
    super();
    this.tenant = tenant;
    this.firestore = getFirestore();
    this.storage = getStorage();
    this.templatesRef = collection(
      doc(this.firestore, 'tenants', this.tenant),
      this.templatesCollectionName
    );
  }

  /**
   * Get all templates from Firebase
   *
   * @returns TemplateModel[]
   * */

  public getTemplates = async (): Promise<VerbalTemplate[] | false> => {
    try {
      let templates: VerbalTemplate[] = [];
      const templatesQuery = query(this.templatesRef, orderBy('name', 'asc'));
      const templatesSnapshot = await getDocs(templatesQuery);
      for (const t of templatesSnapshot.docs) {
        const allData = t.data();
        const categories = await getCategoriesObj(allData.categories);
        // @ts-ignore
        templates.push({ ...allData, id: t.id, categories });
      }
      return templates;
    } catch (err: unknown) {
      console.error('Fetch dei template fallito', err);
      return false;
    }
  };

  public getTemplatesFiltered = async (filters: {
    categories: Partial<Category>[];
  }): Promise<VerbalTemplate[] | false> => {
    try {
      let templates: VerbalTemplate[] = [];
      const categoriesToFind = getCategoriesRef(
        this.firestore,
        filters.categories,
        this.tenant
      );
      let templatesQuery;
      if (categoriesToFind.length > 0) {
        templatesQuery = query(
          this.templatesRef,
          where('categories', 'array-contains-any', categoriesToFind),
          orderBy('name', 'asc')
        );
      } else {
        templatesQuery = query(this.templatesRef, orderBy('name', 'asc'));
      }
      const templatesSnapshot = await getDocs(templatesQuery);
      for (const t of templatesSnapshot.docs) {
        const allData = t.data();
        const categories = await getCategoriesObj(allData.categories);
        // @ts-ignore
        templates.push({ ...allData, id: t.id, categories });
      }
      return templates;
    } catch (err: unknown) {
      console.error('Fetch dei template fallito', err);
      return false;
    }
  };

  /**
   * Get one single Template from Firebase with the given Id
   * Variables conventions:
   * fooSnapshot is a snapshot variable: it contains 'data()' method and 'id' field.
   * fooReference is a reference variable: it doesn't contain any data!
   *
   * @param id: the template id to fetch
   * @throws If the fetch fails
   * @returns the template fetched
   * */
  public getTemplate = async (id: string): Promise<VerbalTemplate | false> => {
    try {
      const templateReference = doc(this.templatesRef, id);
      const templateSnapshot = await getDoc(templateReference);
      const templateData = templateSnapshot.data();
      const sections = this.getSections(templateData!.sections);
      const categories = await getCategoriesObj(templateData!.categories);
      const jobSite = await getJobSiteDataFromReference(templateData!.jobSite);
      // @ts-ignore
      return {
        ...templateData,
        categories,
        id: templateSnapshot.id,
        sections,
        jobSite: jobSite.id,
      };
    } catch (err: unknown) {
      console.error(`Fetch Template fallito`, err);
      return false;
    }
  };

  /**
   * Get and update, if necessary, the template Sections
   * @param sections: the original template sections
   */
  private getSections = (sections: SectionModel[]): SectionModel[] => {
    const _sections: SectionModel[] = [];
    sections.forEach((section) => {
      const newSection = {
        ...section,
        questions: this.getQuestions(section.questions),
      };
      _sections.push(newSection);
    });
    return _sections;
  };

  /**
   * Get questions and update, if necessary, the questions rows
   * A question must be updated if:
   * (1) - is a radio question: in firebase must be MULTICHOICE id _isRadio is true
   * (1) because the firebase data structure is different from react model structure
   */
  private getQuestions = (questions: QuestionModel[]): QuestionModel[] => {
    return adaptQuestionsInReading(questions);
  };

  /**
   * Create Template via Transaction operation.
   *
   * @param template - template to create
   * @throws If the deletion fails
   * @returns Promise<true> if all went well
   * */
  public create = async (
    template: VerbalTemplate,
    is_copying_images = false
  ): Promise<VerbalTemplate | false> => {
    const nowTimestamp = new Date();
    const valuesWithTimestamps = {
      ...template,
      created_at: nowTimestamp,
      updated_at: nowTimestamp,
    };
    const valuesWithRefs = {
      ...valuesWithTimestamps,
      ...(is_copying_images && { is_copying_images: true }),
      jobSite: doc(
        collection(
          doc(this.firestore, 'tenants', this.tenant),
          jobSitesCollectionName
        ),
        template.jobSite
      ),
      categories: getCategoriesRef(
        this.firestore,
        template.categories,
        this.tenant
      ),
    };
    try {
      await runTransaction(this.firestore, async (transaction) => {
        const { id, sections, ...templateData } = valuesWithRefs;
        const sectionsData = await this.prepareSections(
          valuesWithRefs.sections
        );
        const templateReference = doc(this.templatesRef);
        transaction.set(templateReference, {
          ...templateData,
          sections: sectionsData,
        });
      });
      return Promise.resolve(valuesWithTimestamps);
    } catch (err: unknown) {
      console.error('Errore in fase di creazione nuovo Template', err);
      return Promise.resolve(false);
    }
  };

  /**
   * Prepare template Sections.
   * @param sections - template sections
   */
  private async prepareSections(
    sections: SectionModel[]
  ): Promise<SectionModel[]> {
    const sectionsData: SectionModel[] = [];
    for (const section of sections) {
      const { questions, id, ...sectionData } = section;
      const questionsData = await this.prepareQuestions(questions);
      delete sectionData.expanded;
      sectionsData.push({
        ...sectionData,
        questions: questionsData,
      });
    }
    return sectionsData;
  }

  /**
   * Prepare template Questions.
   * (1) - If is a radio question the type have to be changed (multichoice)
   * (2) - If there are rows (table type): these have to be changed to the correct format
   * (3) - Handle images deletion if the question type IS NOT instruction (for example because initially the question
   * is instruction type and after no. The images must be deleted)
   */
  private async prepareQuestions(
    questions: QuestionModel[]
  ): Promise<QuestionModel[]> {
    // @ts-ignore
    return await adaptQuestionsInCreation(questions, this.storage);
  }

  /**
   * Delete Template via transaction operations
   *
   * @param template - template to delete
   * @throws If the deletion fails
   * @returns Promise<true> if all went well
   * */
  public deleteTemplate = async (
    template: VerbalTemplate
  ): Promise<boolean> => {
    try {
      await runTransaction(this.firestore, async (transaction) => {
        const templateReference = doc(this.templatesRef, template.id!);
        transaction.delete(templateReference);
      });
      return Promise.resolve(true);
    } catch (err: unknown) {
      console.error(`Errore in fase di cancellazione del template`, err);
      return Promise.resolve(false);
    }
  };

  deleteTemplateImages = async (template: VerbalTemplate): Promise<void> => {
    for (const section of template.sections) {
      for (const question of section.questions) {
        if (question.type !== QuestionType.instruction) {
          return;
        }
        // @ts-ignore
        await deleteImagesFromCloud(question.images);
      }
    }
  };

  /**
   * Update Template via transaction operations
   *
   * @param template - template to update
   * @throws If the update fails
   * @returns Promise<true> if all went well
   */
  public async update(template: VerbalTemplate): Promise<boolean> {
    try {
      const nowTimestamp = new Date();
      const templateWithUpdatedAt = {
        ...template,
        updated_at: nowTimestamp,
        categories: getCategoriesRef(
          this.firestore,
          template.categories,
          this.tenant
        ),
        jobSite: doc(
          collection(
            doc(this.firestore, 'tenants', this.tenant),
            jobSitesCollectionName
          ),
          template.jobSite
        ),
      };
      const templateReference = doc(
        this.templatesRef,
        templateWithUpdatedAt.id!
      );
      await runTransaction(this.firestore, async (transaction) => {
        const { id, sections, ...templateData } = templateWithUpdatedAt;
        const sectionsData = await this.prepareSections(
          templateWithUpdatedAt.sections
        );
        transaction.update(templateReference, {
          ...templateData,
          sections: sectionsData,
        });
      });
      return Promise.resolve(true);
    } catch (err: unknown) {
      console.error('Errore in fase di aggiornamento template', err);
      return Promise.resolve(false);
    }
  }
}
