/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import clsx from 'clsx';
import { FC, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { toAbsoluteUrl } from '../../../helpers';
import {
  intlConfigState,
  setIntlConfigStorage,
} from '../../../i18n/state/intlConfig';
import { Langs } from '../../../i18n/types/langs';

type langSetting = {
  lang: Langs;
  name: string;
  flag: string;
};
export const languages: langSetting[] = [
  {
    lang: 'it',
    name: 'Italiano',
    flag: toAbsoluteUrl('/media/flags/italy.svg'),
  },
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'es',
    name: 'Espanol',
    flag: toAbsoluteUrl('/media/flags/spain.svg'),
  },
  {
    lang: 'de',
    name: 'Deutsch',
    flag: toAbsoluteUrl('/media/flags/germany.svg'),
  },
  {
    lang: 'fr',
    name: 'Français',
    flag: toAbsoluteUrl('/media/flags/france.svg'),
  },
];

const Languages: FC = () => {
  const [intlConfig, setIntlConfigState] = useRecoilState(intlConfigState);
  const intl = useIntl();
  const currentIntl = useRef(intlConfig);
  const currentLanguage = languages.find(
    (x) => x.lang === intlConfig.selectedLang
  );
  useEffect(() => {
    if (currentIntl.current.selectedLang !== intlConfig.selectedLang)
      setIntlConfigStorage(intlConfig);
    currentIntl.current = intlConfig;
  }, [intlConfig]);

  const menuItemJSX = (l: langSetting, isActive: boolean): ReactJSXElement => {
    return (
      <div
        className='menu-item px-3'
        data-testid={`${l.lang}-switch-div`}
        key={l.lang}
        onClick={() => {
          setIntlConfigState({ ...intlConfig, selectedLang: l.lang as Langs });
        }}
      >
        <a
          href='#'
          className={clsx('menu-link d-flex px-5', {
            active: isActive,
          })}
        >
          <span className='symbol symbol-20px me-4'>
            <img className='rounded-1' src={l.flag} alt='metronic' />
          </span>
          {l.name}
        </a>
      </div>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderMenuItem = (languages: langSetting[]): ReactJSXElement[] => {
    return [languages[0]].map((l, index) =>
      menuItemJSX(l, l.lang === currentLanguage?.lang)
    );
  };

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
      data-testid='switch-language-div'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          {intl.formatMessage({ id: 'MENU.HEADER.USERDIV.LANGUAGE' })}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='metronic'
            />
          </span>
        </span>
      </a>
      {/* Remove the comment from next lines when you want switch between all languages*/}
      {/* <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {renderMenuItem(languages)}
      </div> */}
    </div>
  );
};

export { Languages };
