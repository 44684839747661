import { Chip } from '@mui/material';
import { Timestamp } from 'firebase/firestore';

/**
 * Return an acronym from the text received as parameter
 * @param text: the text to be analyzed
 * @param maxLetters: max number of letter to include in the acronym
 * @return the acronym
 */
export const getAcronym = (text: any, maxLetters = 2): string => {
  if (!text) {
    return '';
  }
  text = text.replace(/\./gi, ' ');
  // If the text is an only word => return the first to letters
  if (text.split(' ').length === 1) {
    return text.substring(0, 2);
  }
  return text
    .split(/\s/)
    .reduce((accumulator: string, word: string, currentIndex: number) => {
      if (maxLetters && currentIndex >= maxLetters) {
        return accumulator;
      }
      return accumulator + word.charAt(0).toLocaleUpperCase();
    }, '');
};

const showStringDateInItFormat = (dateInIsoFormat: string): string => {
  if (!isValidDateString(dateInIsoFormat)) {
    return '';
  }
  return new Date(dateInIsoFormat! as string).toLocaleString('it-IT');
};

const showTimestampInItFormat = (timestamp: number) => {
  return new Date(timestamp).toLocaleString('it-IT');
};

const showDateObjectInItFormat = (date: Date) => {
  return date.toLocaleString('it-IT');
};

export const showDateInItFormat = (
  date?: string | number | Timestamp
): string => {
  if (!date) {
    return '';
  }
  if (typeof date === 'string') {
    return showStringDateInItFormat(date);
  }
  if (typeof date === 'number') {
    return showTimestampInItFormat(date);
  }
  return showDateObjectInItFormat(date.toDate());
};

export const isValidDateString = (dateString: string): boolean => {
  return !isNaN(Date.parse(dateString));
};

export const handleRenderCategoryChip = (
  labelToProcess: string,
  characterLimit: number
) => {
  const firstCharacters = labelToProcess.substring(0, characterLimit);
  const label =
    labelToProcess.length >= characterLimit
      ? firstCharacters + '...'
      : firstCharacters;
  return <Chip label={label} variant='outlined'></Chip>;
};
