import { ReactElement } from 'react';
import { DataGridCustomized } from '../../../../../../../../theme/DataGrid';
import { UserModel } from '../../../../../../../models/UserModel';

const PermissionsTable = ({
  permissions,
  props,
  columnsSchema,
}: {
  permissions: Partial<UserModel>[];
  props: any;
  columnsSchema: any;
}): ReactElement => {
  return (
    <div className='h-full'>
      <DataGridCustomized
        columns={columnsSchema}
        rows={permissions}
        props={props}
      />
    </div>
  );
};

export { PermissionsTable };
