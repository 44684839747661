import { FC } from 'react';
import { useIntl } from 'react-intl';
import { formMode } from '../../../../../../models/creationMode.model';
import { Button } from '../../../../../../../_metronic/components/Button';
import MuiTextField from '../../../../../../components/TextField';
import { Stack } from '@mui/material';
import FormProvider from '../../../../../../components/FormProvider';
import { schema } from './schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { JobSiteModel } from '../../../../../../models/JobSite';

type FormValuesProps = {
  name: string;
};
type Props = {
  mode?: formMode;
  values: JobSiteModel;
  submitEvent: (data: JobSiteModel) => void;
};
const JobSiteDetailsForm: FC<Props> = ({ mode, values, submitEvent }) => {
  const intl = useIntl();
  const { id, ...defaultValues } = values;

  const methods = useForm<FormValuesProps>({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema(intl)),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      data = {
        ...data,
        ...(mode === formMode.edit ? { id } : {}),
      };
      await submitEvent(data as JobSiteModel);
    } catch (error: unknown) {
      reset();
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <div className='form w-100'>
        <div className='w-full md:w-1/3'>
          <Stack direction='row' spacing={2}>
            <MuiTextField
              name='name'
              label={intl.formatMessage({
                id: 'JOB_SITE.FIELD_NAME_LABEL',
              })}
            />
          </Stack>
        </div>
      </div>
      <div
        className={
          'flex flex-col sm:flex-row align-middle justify-center sm:justify-end mt-5'
        }
      >
        <div>
          <Button
            id='submit-new-template'
            classes='w-full sm:w-auto'
            innerText={intl.formatMessage({ id: 'GENERAL.FORM.SAVE' })}
            otherProps={{ type: 'submit' }}
          />
        </div>
      </div>
    </FormProvider>
  );
};

export { JobSiteDetailsForm };
