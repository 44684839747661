import { Theme } from '@mui/material/styles';
import DataGrid from './DataGrid';
import TextField from './TextField';
import Paper from './Paper';
import Autocomplete from "./Autocomplete";

/**
 * Override the default MUI theme
 */
export default function componentsOverrides(theme: Theme) {
  return Object.assign(Paper(theme), DataGrid(theme), TextField(theme), Autocomplete(theme));
}
