import { NonConformityTemplate } from '../app/modules/non_conformity/models/nonConformity.model';
import ValidationService from './ValidationService';
import { Error } from './ValidationService';

export class NCValidationService extends ValidationService {
  public evaluateTemplate(template: NonConformityTemplate): Error[] {
    let errors: Error[] = [];
    if (this.areThereQuestionsBoxWithoutAQuestion([template])) {
      errors.push(this.getAtLeastAQuestionErrorObject());
    }
    errors = [
      ...errors,
      ...this.evaluateCommonProperties(template),
      ...this.evaluateQuestionsBoxes([template]),
    ];
    return errors;
  }

  protected getAtLeastAQuestionErrorObject() {
    return {
      field: 'sections',
      message: this.intl.formatMessage({
        id: 'NC.ERRORS_AL_LEAST_ONE_SECTION',
      }),
    };
  }
}

export default NCValidationService;
