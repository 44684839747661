import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { RolesSection } from './content/RolesSection';

const RolesPage: FC = () => {
  const intl = useIntl();

  return (
    <>
      {/* Title */}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.ROLES' })}
      </PageTitle>
      {/* Content */}
      <div className='container mw-100 px-0'>
        {/* Roles Section */}
        <RolesSection />
      </div>
    </>
  );
};

export { RolesPage };
