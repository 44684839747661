import * as Yup from 'yup';

export const schema = (intl: any) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(
        { id: 'GENERAL.VALIDATOR.FIELD_REQUIRED' },
        {
          field: intl.formatMessage({
            id: 'JOB_SITE.FORM.LABELS.NAME',
          }),
        }
      )
    ),
  });
