import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { JobSitesSection } from './content/JobSitesSection';

const JobSitesPage: FC = () => {
  const intl = useIntl();
  return (
    <>
      {/* Title */}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.JOB_SITES' })}
      </PageTitle>
      {/* Content */}
      <div className='container mw-100 px-0'>
        {/* Job Sites Section */}
        <JobSitesSection />
      </div>
    </>
  );
};

export { JobSitesPage };
