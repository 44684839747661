import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { formMode } from '../../../../models/creationMode.model';
import { RolesTabsWrapper } from './tabs/RolesTabsWrapper';
type Props = {
  mode: formMode;
};
const RoleForm: FC<Props> = ({ mode }): ReactElement => {
  const intl = useIntl();
  const handleRenderTitle = (): string => {
    if (mode === formMode.create) {
      return intl.formatMessage({ id: 'JOB_SITE.FORM_CREATION_TITLE_LABEL' });
    }
    return intl.formatMessage({ id: 'JOB_SITE.FORM_EDIT_TITLE_LABEL' });
  };

  return (
    <>
      {/* Titolo */}
      <PageTitle breadcrumbs={[]}>{handleRenderTitle()}</PageTitle>
      {/* Form a tabs */}
      <div className={'container primary-bg rounded-xl mw-100 p-10 h-auto'}>
        <RolesTabsWrapper mode={mode} />
      </div>
    </>
  );
};

export { RoleForm };
