import { FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DataGridLocale } from '../_metronic/components/datagrid/DataGridLocale';
import { defaultDataGridProps } from './overrides/DataGrid';
import { useIntl } from 'react-intl';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

type Props = {
  rows: any[];
  columns: GridColDef[];
  props?: {
    [key: string]: any;
  };
};
export const DataGridCustomized: FC<Props> = ({ rows, columns, props }) => {
  const intl = useIntl();
  if (!!props && props.loading) {
    return (
      <Stack spacing={1}>
        <Skeleton
          variant='rectangular'
          width={'auto'}
          height={60}
          sx={{ borderRadius: 2 }}
        />
        <Skeleton
          variant='rectangular'
          width={'auto'}
          height={200}
          sx={{ borderRadius: 2 }}
        />
      </Stack>
    );
  }
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      {...props}
      {...defaultDataGridProps}
      {...DataGridLocale(intl)}
    />
  );
};
