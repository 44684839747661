import { loginOutputType } from '../../service/contracts/AuthService';

/**
 * Update Recoil and Local Storage State
 * @return True if token has been expired
 */
export function updateTokenData(
  tokenData: loginOutputType,
  updateToken: (tokenData: {
    token: string;
    expiresAt: number;
    refreshToken: string;
  }) => void
): void {
  const data = {
    token: tokenData.token,
    expiresAt: tokenData.expiresAt,
    refreshToken: tokenData.refreshToken,
  };
  // Update Recoil State
  updateToken(data);
  // Update Local Storage State
  localStorage.setItem('session', JSON.stringify(data));
}
