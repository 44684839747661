import { Permission } from './Permission.model';

export type Role = {
  id: string;
  roleName: string;
  permissions: Permission[];
};

export const emptyRole: Partial<Role> = {
  roleName: '',
  permissions: [],
};
