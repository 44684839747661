import { FC, ReactElement, useContext, useState } from 'react';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { JobSiteDetailsFormWrapper } from './details/JobSiteDetailsFormWrapper';
import { useIntl } from 'react-intl';
import { UsersSection } from './users/UsersSection';
import { formMode } from '../../../../../models/creationMode.model';
import { JobSiteTabsContext } from './context';
import { TabPanel, a11yProps } from '../../../../../components/tabs/TabPanel';

const JobSiteTabs: FC = (): ReactElement => {
  const [value, setValue] = useState(0);
  const intl = useIntl();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { mode } = useContext(JobSiteTabsContext);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={intl.formatMessage({ id: 'GENERIC.TABS.DETAILS' })}
            {...a11yProps('jobsite', 0)}
          />
          {mode === formMode.edit && (
            <Tab
              label={intl.formatMessage({
                id: 'JOB_SITES.TABS.USERS_ASSOCIATED',
              })}
              {...a11yProps('jobsite', 1)}
            />
          )}
        </Tabs>
      </Box>
      <TabPanel idPrefix='jobsite' value={value} index={0}>
        <JobSiteDetailsFormWrapper />
      </TabPanel>
      <TabPanel idPrefix='jobsite' value={value} index={1}>
        <UsersSection />
      </TabPanel>
    </>
  );
};

export { JobSiteTabs };
