import { UserModel } from './UserModel';

export type JobSiteModel = {
  id?: string;
  name: string;
  created_at?: Date;
  users: UserModel[];
};

export const jobSiteString = (jobSite: JobSiteModel | undefined): string => {
  if (!jobSite) return '';
  return jobSite.name;
};

export const orderJobSites = (jobSites: JobSiteModel[]): JobSiteModel[] => {
  return jobSites.sort((a, b) => {
    if (a.created_at === b.created_at) {
      return 0;
    }
    return a.created_at! > b.created_at! ? -1 : 1;
  });
};
