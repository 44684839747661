import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { IntlShape } from 'react-intl';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
export const columnsDefinition = (
  intl: IntlShape,
  onDelete: CallableFunction,
  setOpenUpsertModal: CallableFunction
): GridColDef[] => [
  {
    field: 'tag',
    headerName: intl.formatMessage({ id: 'CATEGORIES.MODEL.TAG' }),
    sortable: true,
    flex: 1,
  },
  {
    field: 'sub',
    headerName: intl.formatMessage({ id: 'GENERAL.TABLE.ACTIONS_LABEL' }),
    renderCell: (param: GridRenderCellParams<string>): ReactJSXElement => (
      <>
        <IconButton onClick={() => setOpenUpsertModal(param.row)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => onDelete(param.row)}>
          <DeleteIcon />
        </IconButton>
      </>
    ),
    sortable: false,
    filterable: false,
    hide: false,
    disableColumnMenu: true,
  },
];
