import { atom } from 'recoil';
import { NonConformityTemplate } from '../models/nonConformity.model';

export const defaultNonConformity: NonConformityTemplate = {
  id: null,
  name: '',
  description: '',
  categories: [],
  questions: [],
  jobSite: '',
  autoEnding: false,
};

export const nonConformityState = atom({
  key: 'nonConformity',
  default: defaultNonConformity,
});
