import { atom } from 'recoil';
import { container } from 'tsyringe';
import { UsersService } from '../../../../../service/contracts/UsersService';

export const userState = atom({
  key: 'userData',
  default: JSON.parse(localStorage.getItem('userData') as string),
});
// await usersService.getUser(userId) as UserModel;
export const getFullName = async (user: any): Promise<string> => {
  const response: any = await container
    .resolve<UsersService>('UsersService')
    .get(user.uid);
  if (!response.display_name) {
    return user.email ?? '';
  }
  return response.display_name;
};
