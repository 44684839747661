import { FC } from 'react';
import { useIntl } from 'react-intl';
import { formMode } from '../../../../../../models/creationMode.model';
import { Button } from '../../../../../../../_metronic/components/Button';
import FormProvider from '../../../../../../components/FormProvider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MuiTextField from '../../../../../../components/TextField';
import { Stack } from '@mui/material';
import { emptyRole, Role } from '../../../../models/Role.model';
import { formSchema } from '../../config/formSchema';

type Props = {
  values: Role;
  handleSubmitEvent: (data: Partial<Role>) => void;
  mode?: formMode;
};
const RoleForm: FC<Props> = ({ values, handleSubmitEvent, mode }) => {
  const intl = useIntl();

  const { id, ...defaultValues } = values ?? emptyRole;

  // Methods
  const methods = useForm<Role>({
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema(intl)),
    defaultValues: defaultValues,
  });
  const onSubmit = async (data: Role) => {
    try {
      data = {
        ...data,
        ...(mode === formMode.edit ? { id } : {}),
      };
      await handleSubmitEvent(data);
    } catch (error: unknown) {
      reset();
    }
  };

  const { reset, handleSubmit } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <div className='form w-100'>
        <div className='py-5 w-full md:w-1/3'>
          <Stack direction='row' spacing={2}>
            <MuiTextField
              name='roleName'
              label={intl.formatMessage({
                id: 'ROLE.MODEL.LABELS.NAME',
              })}
            />
          </Stack>
        </div>
      </div>
      <div
        className={
          'flex flex-col sm:flex-row align-middle justify-center sm:justify-end mt-5'
        }
      >
        <div>
          <Button
            id='submit-new-role'
            classes='w-full sm:w-auto'
            innerText={intl.formatMessage({ id: 'GENERAL.FORM.SAVE' })}
            otherProps={{ type: 'submit' }}
          />
        </div>
      </div>
    </FormProvider>
  );
};

export { RoleForm };
