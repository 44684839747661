import React, { useEffect, useRef } from 'react';
import { AsideDefault } from './components/aside/AsideDefault';
import { Footer } from './components/Footer';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { Toolbar } from './components/toolbar/Toolbar';
import { ScrollTop } from './components/ScrollTop';
import { Content } from './components/Content';
import { MasterInit } from './MasterInit';
import { PageDataProvider } from './core';
import { getPagesConfiguration } from '../../app/PagesConfiguration';
import {
  ActivityDrawer,
  DrawerMessenger,
  InviteUsers,
  Main,
  UpgradePlan,
} from '../partials';
import { AsideMenuItemWithSub } from './components/aside/AsideMenuItemWithSub';
import { Page, PageChild } from '../types/PageModel';
import { useIntl } from 'react-intl';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Route } from 'react-router-dom';
import { PrivateRoutes } from '../../app/routing/PrivateRoutes';
import { FieldInputProps } from 'formik';
import { updateTokenData } from '../hooks/useUpdateTokenData';
import { container } from 'tsyringe';
import {
  AuthErrorType,
  AuthService,
  loginOutputType,
} from '../../service/contracts/AuthService';
import { useIsTokenExpired } from '../hooks/useIsTokenExpired';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  sessionState,
  userState,
} from '../../app/modules/sikuro_auth/state/atoms';

const MasterLayout: React.FC = () => {
  const intl = useIntl();
  const authService = container.resolve<AuthService>('AuthService');
  const tokenIsExpired = useIsTokenExpired();
  const [session, setSession] = useRecoilState(sessionState); //expiresAt
  const userData = useRecoilValue(userState);
  const pagesConfiguration = getPagesConfiguration(userData.permissions);
  const timer = useRef<NodeJS.Timeout | undefined>();

  const iterateOverChilds = (
    childs: PageChild[] | undefined,
    func: Function
  ): ReactJSXElement[] | ReactJSXElement => {
    if (!childs) return <></>;
    return childs.reduce(
      (acc: any[], pc: PageChild, indexChild: number): ReactJSXElement[] => {
        return [...acc, func(pc, { key: indexChild })];
      },
      []
    );
  };

  /**
   * Handle render main menu item in the sidebar
   * @param pagesConfiguration: The menu configuration
   */
  const renderAsideLinks = (pagesConfiguration: Page[]): JSX.Element[] => {
    return pagesConfiguration.map((page, index) => {
      return (
        <AsideMenuItemWithSub
          key={index}
          to={`${page.to}/overview`}
          title={intl.formatMessage({ id: page.title })}
          icon={page.icon}
        ></AsideMenuItemWithSub>
      );
    });
  };

  /**
   * Token expiration management: if token is expired => refresh it!
   */
  useEffect(() => {
    if (tokenIsExpired) {
      refreshToken();
      return;
    }
    // console.log('Scado il: ', new Date(session.expiresAt));
    timer.current = setTimeout(async () => {
      await refreshToken();
      timer.current = undefined;
    }, session.expiresAt - new Date().getTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const refreshToken = async () => {
    const refreshTokenResponse: loginOutputType | AuthErrorType =
      await authService.getRefreshToken(
        process.env.REACT_APP_FIREBASE_CONFIG_APIKEY!,
        session.refreshToken!
      );
    if ('isError' in refreshTokenResponse) {
      return;
    }
    updateTokenData(refreshTokenResponse, setSession);
  };

  const renderContent = (pagesConfiguration: Page[]) => {
    const funcRouteJSX = (
      c: PageChild,
      props: FieldInputProps<any>
    ): ReactJSXElement => (
      <Route {...props} path={`${c.to}`} component={c.component} />
    );
    const contents = pagesConfiguration.map((page, index) => (
      <Route key={index} path={`${page.to}`} component={page.component} />
    ));
    const contentChilds = pagesConfiguration
      .filter((p) => p.childs)
      .map((page) => {
        return iterateOverChilds(page.childs, funcRouteJSX);
      });
    return (
      <PrivateRoutes>
        {contents}
        {contentChilds}
      </PrivateRoutes>
    );
  };

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault>{renderAsideLinks(pagesConfiguration)}</AsideDefault>
          <div
            className='wrapper d-flex flex-column flex-row-fluid'
            id='kt_wrapper'
          >
            <HeaderWrapper />

            <div
              id='kt_content'
              className='content d-flex flex-column flex-column-fluid'
            >
              <Toolbar />
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>{renderContent(pagesConfiguration)}</Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  );
};

export { MasterLayout };
