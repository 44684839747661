import * as Yup from 'yup';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { formMode } from '../../../models/creationMode.model';
import { Button } from '../../../../_metronic/components/Button';
import FormProvider from '../../../components/FormProvider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MuiTextField from '../../../components/TextField';
import { Stack } from '@mui/material';
import { Company } from '../models/Company.model';

type FormValuesProps = {
  name: string;
  vat_code: string;
};

type Props = {
  values: Partial<Company>;
  handleSubmitEvent: (data: Company) => void;
  mode?: formMode;
};
const CompanyForm: FC<Props> = ({
  values,
  mode = formMode.create,
  handleSubmitEvent,
}) => {
  const { id, ...defaultValues } = values;
  const intl = useIntl();

  // Title
  const handleRenderTitle = (): string => {
    if (mode === formMode.create) {
      return intl.formatMessage({ id: 'COMPANY.FORM.NEW' });
    }
    return intl.formatMessage({ id: 'COMPANY.FORM.EDIT' });
  };

  // Form Schema
  const schema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(
        { id: 'GENERAL.VALIDATOR.FIELD_REQUIRED' },
        {
          field: intl.formatMessage({
            id: 'COMPANY.MODEL.LABELS.NAME',
          }),
        }
      )
    ),
    vat_code: Yup.string().required(
      intl.formatMessage(
        { id: 'GENERAL.VALIDATOR.FIELD_REQUIRED' },
        {
          field: intl.formatMessage({
            id: 'COMPANY.MODEL.LABELS.VAT_CODE',
          }),
        }
      )
    ),
  });

  // Methods
  const methods = useForm<FormValuesProps>({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (data: FormValuesProps) => {
    try {
      // @ts-ignore
      data = {
        ...data,
        ...(mode === formMode.edit ? { id } : {}),
      };
      await handleSubmitEvent(data as Company);
    } catch (error: unknown) {
      reset();
    }
  };

  const { reset, handleSubmit } = methods;

  return (
    <div className={'container primary-bg rounded-xl mw-100 p-10 h-auto'}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div>
            <div className='text-2xl mb-10'>
              <h3 className='card-title font-semibold capitalize primary-text'>
                {handleRenderTitle()}
              </h3>
            </div>
            <Stack direction='row' spacing={2}>
              <MuiTextField
                InputLabelProps={{ shrink: true }}
                name='name'
                label={intl.formatMessage({
                  id: 'COMPANY.MODEL.LABELS.NAME',
                })}
              />
              <MuiTextField
                InputLabelProps={{ shrink: true }}
                name='vat_code'
                label={intl.formatMessage({
                  id: 'COMPANY.MODEL.LABELS.VAT_CODE',
                })}
              />
            </Stack>
          </div>
        </div>
        <div
          className={
            'flex flex-col sm:flex-row align-middle justify-center sm:justify-end mt-5'
          }
        >
          <div>
            <Button
              id='submit-new-template'
              classes='w-full sm:w-auto'
              innerText={intl.formatMessage({ id: 'GENERAL.FORM.SAVE' })}
              otherProps={{ type: 'submit' }}
            />
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export { CompanyForm };
