import axios from "axios";

export default function setupAxios(): void {
    axios.interceptors.request.use( (config: any) => {
        const token = JSON.parse(localStorage.getItem('session') as string).token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
        (err: any) => Promise.reject(err)
    )
}
