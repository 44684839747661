import React, {FC} from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import {VerbalsTable} from "./VerbalsTable";



const VerbalsTableWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.VERBALS' })}</PageTitle>
            <VerbalsTable />
        </>
    )
}

export { VerbalsTableWrapper }
