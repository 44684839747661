import React, {FC} from 'react';
import {PageTitle} from '../../../../_metronic/layout/core';
import {formMode} from "../../../models/creationMode.model";
import {useIntl} from "react-intl";
import {NonConformity} from "./NonConformity";

type Props = {
    mode: formMode
}
const NonConformityWrapper: FC<Props> = ({mode}) => {
    const intl = useIntl()

    return (
        <>
            <NonConformity mode={mode}/>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CREATE_NON_CONFORMITY' })}</PageTitle>
        </>
    )
}

export {NonConformityWrapper}
