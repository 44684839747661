import { connectHits } from 'react-instantsearch-dom';
import { UserModel } from '../../../../../../../../models/UserModel';
import { AlgoliaUser } from '../../../../../../../users/models/UserAlgolia';
import { BindUsersTable } from './utils/BindUserTable';

export const CustomUsersTable = connectHits(
  ({ hits }: { hits: AlgoliaUser[] }) => {
    const hitsConverted: Partial<UserModel>[] = hits.map((r) => ({
      displayName: r.displayName,
      email: r.email,
      id: r.objectID,
    }));
    return <BindUsersTable users={hitsConverted}></BindUsersTable>;
  }
);
