import { IntlShape } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  GridCellValue,
  GridColDef,
  GridValueGetterParams,
} from '@mui/x-data-grid';

export const permissionsDGColumnsDefinition = (
  intl: IntlShape
): GridColDef[] => [
  {
    field: 'permissionValue',
    headerName: intl.formatMessage({ id: 'PERMISSION.MODEL.LABELS.NAME' }),
    sortable: true,
    flex: 1,
    renderCell: (params: GridValueGetterParams): GridCellValue => (
      <Box>
        <Typography className={'w-full block'}>
          {params.row.label}
        </Typography>
        <Typography className={'w-full block'}>
          <i>{params.row.description}</i>
        </Typography>
      </Box>
    ),
  },
];
