import {ChangeEvent, FC} from "react";
import {isColorDark} from "../../../../utilities/dialogUtilities";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import {Input} from "../../../../../../../_metronic/layout/components/form/Input";
import * as React from "react";
import {TextAndColorItem} from "../../../../models/textAndColorItem.model";
import {useIntl} from "react-intl";
import { QuestionModel } from "../../../../models/question.model";


type Props = {
    choice: TextAndColorItem,
    openPopover: (e: any, index: number) => void,
    deleteChoice: (index: number) => void,
    onChangeInput: (event: ChangeEvent<HTMLInputElement>, key: keyof QuestionModel) => void,
    index: number
}
const Choice: FC<Props> = ({choice, openPopover, deleteChoice, index, onChangeInput}) => {
    const intl = useIntl()


    return (
        <div className={'grid grid-cols-10 gap-5 justify-items-center items-center mb-5'}>
            <div className={'col-span-1'}>
                <div onClick={(e) => openPopover(e, index)}>
                    <div className="rounded-full h-12 w-12 flex items-center justify-center font-bold
                            cursor-pointer" style={{backgroundColor: choice.color, color: isColorDark(choice.color)
                            ? 'white' : 'black'}}>
                        <div>
                            <PaletteOutlinedIcon/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-span-7 w-full'}>
                <Input label={intl.formatMessage({id: "QUESTION.QUESTION_TITLE" })}
                       withLabel={false} id={`choice-${index}`} otherProps={{choiceindex: index}}
                       onChangeInput={onChangeInput} value={choice.text}/>
            </div>
            <div className={'col-span-2 w-full text-center'}>
                <button className='font-bold' onClick={() => deleteChoice(index)}>
                    {intl.formatMessage({id: "GENERAL.FORM.DELETE" })}
                </button>
            </div>
        </div>
    )
}
export {Choice}
