import { FC, ReactElement } from 'react';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { Box } from '@mui/material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { getAlgoliaParameters } from '../../modules/users/algoliaUtility';
import { CloseButton } from '../dialogs/CloseButton';
import { SearchBox } from '../../modules/users/components/content/SearchBox/SearchBox';

type Props = {
  setDialogState: (state: boolean) => void;
  isOpen: boolean;
  title: string;
  ActionButton: ReactElement;
  filters: string[][];
};
const UsersDialogWithRowsSelection: FC<Props> = ({
  setDialogState,
  isOpen,
  title,
  ActionButton,
  children,
  filters,
}): ReactElement => {
  const { index, searchClient } = getAlgoliaParameters();
  const searchClientD = {
    ...searchClient,
    search(requests: any) {
      // @ts-ignore
      // If no string to search, avoid to call algolia
      if (requests.every(({ params }) => !params.query)) {
        return;
      }
      return searchClient.search(requests);
    },
  };

  return (
    <Dialog
      onClose={() => setDialogState(false)}
      aria-labelledby='customized-dialog-title'
      open={isOpen}
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {title}
        <CloseButton setDialogState={setDialogState} />
      </DialogTitle>
      <DialogContent dividers>
        {/* Algolia Search Component */}
        <InstantSearch
          indexName={index}
          searchClient={searchClientD}
          refresh={true}
        >
          {/* Facet config */}
          <Configure facetFilters={filters} />
          {/* Box rierca utente */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '300px' }}>
              <SearchBox />
            </Box>
            <Box>{ActionButton}</Box>
          </Box>
          {/* Tabella risultati */}
          <Box sx={{ marginTop: '15px' }}>{children}</Box>
        </InstantSearch>
      </DialogContent>
    </Dialog>
  );
};

export { UsersDialogWithRowsSelection };
