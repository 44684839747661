import { ChangeEvent, FC, useEffect, useState } from 'react';
import { formMode } from '../../../models/creationMode.model';
import { Input } from '../../../../_metronic/layout/components/form/Input';
import { Textarea } from '../../../../_metronic/layout/components/form/Textarea';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import {
  getLastEntitiesOrder,
  newQuestion,
} from '../../../pages/templates/state/template';
import {
  defaultNonConformity,
  nonConformityState,
} from '../state/nonConformity';
import DragDropQuestions from '../../../../_metronic/components/DragDropQuestions';
import { QuestionWrapper } from './QuestionWrapper';
import { AlertErrors } from '../../../../_metronic/components/alerts/Errors';
import { TemplateModalErrors } from '../../../pages/templates/components/TemplateModalErrors';
import { useHistory, useParams } from 'react-router-dom';
import { container } from 'tsyringe';
import {
  ErrorType,
  NonConformityTemplateService,
} from '../../../../service/contracts/NonConformityTemplateService';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { NonConformityTemplate } from '../models/nonConformity.model';
import { QuestionModel } from '../../../pages/templates/models/question.model';
import { sortQuestions } from '../../../pages/templates/utilities/generalUtilities';
import { Select } from '../../../../_metronic/layout/components/form/Select';
import { JobSitesService } from '../../../../service/contracts/JobSitesService';
import { getNewValue } from '../../../pages/templates/components/question/QuestionWrapper';
import { Category } from '../../categories/models/Category.model';
import { CategoriesService } from '../../../../service/contracts/CategoriesService';
import FabActions from '../../../pages/templates/components/FabActions';
import { JobSiteModel } from '../../../models/JobSite';
import NCValidationService from '../../../../service/NCValidationService';
import { CategoriesAutocompleteWrapper } from '../../../pages/templates/components/CategoriesAutocompleteWrapper';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  mode: formMode;
};
const NonConformity: FC<Props> = ({ mode }) => {
  const intl = useIntl();
  let history = useHistory();
  // @ts-ignore
  const { id } = useParams();
  const ncValidator = new NCValidationService(intl);
  const nonConformityService = container.resolve<NonConformityTemplateService>(
    'NonConformityTemplateService'
  );
  const jobSitesService = container.resolve<JobSitesService>('JobSitesService');
  const categoriesService =
    container.resolve<CategoriesService>('CategoriesService');
  const [categories, setCategories] = useState<Partial<Category>[]>([]);
  const [nonConformity, setNonConformity] = useRecoilState(nonConformityState);
  const updateState = (
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    key: keyof QuestionModel
  ): void => {
    const value = getNewValue(event); // Don't remove me: i'm here because of autoEnding field
    setNonConformity({ ...nonConformity, [key]: value });
  };
  const [errors, setErrors] = useState<{ field: string; message: string }[]>(
    []
  );
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
  const [jobSites, setJobSites] = useState<JobSiteModel[]>([]);
  // const [loading, setLoading] = useState<boolean>(false);

  const getJobsites = async () => {
    const jobSites: JobSiteModel[] | ErrorType = await jobSitesService.getAll();
    if (!('isError' in jobSites)) return setJobSites(jobSites);
  };

  const getCategories = async () => {
    let response: Partial<Category>[] | false =
      await categoriesService.getAll();
    if (typeof response === 'boolean') {
      return;
    }
    setCategories(
      response.map((category) => ({ id: category.id, tag: category.tag }))
    );
  };

  const handleRenderTitle = (): string => {
    if (mode === formMode.create) {
      return intl.formatMessage({
        id: 'NON_CONFORMITY.FORM_CREATION_TITLE_LABEL',
      });
    }
    return intl.formatMessage({ id: 'NON_CONFORMITY.FORM_EDIT_TITLE_LABEL' });
  };

  const saveNonConformity = async () => {
    const errors: boolean = checkErrors();
    if (errors) return;
    let result: true | ErrorType;
    const nowISOString = new Date();
    if (mode === formMode.create) {
      const valuesWithCreatedAt = {
        ...nonConformity,
        created_at: nowISOString,
        updated_at: nowISOString,
      };
      result = await nonConformityService.create(valuesWithCreatedAt);
    } else {
      const valuesWithUpdatedAt = {
        ...nonConformity,
        updated_at: nowISOString,
      };
      result = await nonConformityService.update(valuesWithUpdatedAt);
    }
    if (typeof result === 'boolean') {
      history.push('/non-conformities-list');
    }
  };

  const checkErrors = (): boolean => {
    const response = ncValidator.evaluateTemplate(nonConformity);
    if (response.length === 0) return false;
    setErrors(response);
    setShowErrorsModal(true);
    return true;
  };

  const addQuestion = () => {
    const newOrderQuestion = getLastEntitiesOrder(nonConformity.questions);
    const newQuestions = [
      ...nonConformity.questions,
      newQuestion(newOrderQuestion),
    ];
    setNonConformity({ ...nonConformity, questions: newQuestions }); // oldSections here is updated
  };

  /**
   * Gestione del salvataggio ordine al termine del drag
   * */
  const saveEntitiesAfterDrag = (questionsReordered: any[]): void => {
    const newQuestions = questionsReordered.map(
      (q: QuestionModel, index: number) => {
        return { ...q, order: index };
      }
    );
    setNonConformity({ ...nonConformity, questions: newQuestions });
  };

  useEffect(() => {
    const fetchAll = async () => {
      await getJobsites();
      await getCategories();
      if (mode === formMode.create)
        return setNonConformity(defaultNonConformity);
      if (!id) return;
      const getTemplate = async (id: string) => {
        const nonConformityFetched: NonConformityTemplate | ErrorType =
          await nonConformityService.get(id);
        if ('isError' in nonConformityFetched) {
          setErrors([
            {
              field: 'nonConformityFetched',
              message: intl.formatMessage({
                id: nonConformityFetched.intlIdCode,
              }),
            },
          ]);
        }
        return setNonConformity(nonConformityFetched as NonConformityTemplate);
      };
      getTemplate(id);
    };
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, mode]);

  const handleRenderJobsiteOptions = (): ReactJSXElement[] => {
    const options: ReactJSXElement[] = [];
    jobSites.forEach((js: JobSiteModel, index: number) => {
      options.push(
        <option key={index} value={js.id}>
          {js.name}
        </option>
      );
    });
    return options;
  };

  const onCategoriesChange = (event: any, values: any) => {
    try {
      setNonConformity({ ...nonConformity, categories: values });
    } catch (e) {
      console.error(e);
    }
  };

  const getError = (field: string): string | undefined =>
    errors.find((e) => e.field === field)?.message;

  return (
    <div className={'container primary-bg rounded-xl mw-100 p-10 h-auto mb-20'}>
      <form>
        <div>
          <div>
            <div className='text-2xl mb-10'>
              <h3 className='card-title font-semibold capitalize primary-text'>
                {handleRenderTitle()}
              </h3>
            </div>
            <div className='mb-3'>
              <Input
                onChangeInput={updateState}
                label={intl.formatMessage({
                  id: 'NON_CONFORMITY.FORM_TITLE_LABEL',
                })}
                id='name'
                value={nonConformity.name}
                errorMsg={getError('name')}
                thereIsError={!!getError('name')}
              />
            </div>
            <div className='mb-3'>
              <Textarea
                id='description'
                onChangeInput={updateState}
                label={intl.formatMessage({
                  id: 'NON_CONFORMITY.FORM_DESCRIPTION_LABEL',
                })}
                value={nonConformity.description}
                errorMsg={getError('description')}
                thereIsError={!!getError('description')}
              />
            </div>
            <div className='mb-3'>
              <Select
                id='jobSite'
                label={intl.formatMessage({
                  id: 'NON_CONFORMITY.FORM_JOBSITE_LABEL',
                })}
                value={nonConformity.jobSite}
                onChangeInput={updateState}
                errorMsg={getError('jobSite')}
                thereIsError={!!getError('jobSite')}
              >
                {handleRenderJobsiteOptions()}
              </Select>
            </div>
            <div className='mb-3'>
              <div>
                <label htmlFor={id} className='text-lg'>
                  {intl.formatMessage({
                    id: 'TEMPLATE.FORM_CATEGORIES_LABEL',
                  })}
                </label>
                <Tooltip
                  title={intl.formatMessage({
                    id: 'CATEGORIES-ATOCOMPLETE.HELP_INFO',
                  })}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <CategoriesAutocompleteWrapper
                categories={categories}
                onCategoriesChange={onCategoriesChange}
                value={nonConformity.categories}
                errorMsg={getError('categories')}
              />
            </div>
            {/* Auto Ending */}
            <div className='mb-3'>
              <div className='relative flex items-start'>
                <label htmlFor='autoEnding' className='text-lg'>
                  {intl.formatMessage({
                    id: 'NON_CONFORMITY.AUTOENDING_LABEL',
                  })}
                </label>
                <div className='flex items-center h-5 ml-3 mt-1'>
                  <input
                    id='autoEnding'
                    name='autoEnding'
                    type='checkbox'
                    className='border-gray-300 w-7 h-7'
                    checked={nonConformity.autoEnding}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      //@ts-ignore
                      updateState(e, 'autoEnding')
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div>
        <DragDropQuestions
          initialEntities={sortQuestions(nonConformity.questions)}
          saveEntitiesAfterDrag={saveEntitiesAfterDrag}
          innerComponentProps={{ mode, sectionIndex: 0 }}
        >
          <QuestionWrapper
            mode={mode}
            sectionIndex={0}
            // @ts-ignore
            entity={[]}
            errors={errors}
          />
        </DragDropQuestions>
      </div>
      <TemplateModalErrors
        open={showErrorsModal}
        handleClose={() => setShowErrorsModal(false)}
      >
        <AlertErrors
          errors={[...Array.from(new Set(errors.map((e) => e.message)))]}
        />
      </TemplateModalErrors>
      <FabActions
        add={addQuestion}
        save={saveNonConformity}
        addTitle='NON_CONFORMITY.ADD_QUESTION_BUTTON'
      />
    </div>
  );
};

export { NonConformity };
