import { Actor } from '../../app/models/Actor.model';

export abstract class ActorsService {
  abstract get(id: string): Promise<Actor>;
  abstract create(userId: string, data: Partial<Actor>): Promise<string>;
  abstract update(userId: string, data: Partial<Actor>): Promise<void>;
  abstract delete(id: string): Promise<void>;
  abstract getByUser(userId: string): Promise<Actor | null>;
  abstract upsert(userId: string, roleId: string): Promise<void>;
}
