import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {useIntl} from "react-intl";

export function useRenderEnumInSelect(enumType: Object, intl_prefix: string, typesToExcludes: string[] = [])
    : ReactJSXElement[]{
    const intl = useIntl();
    const options: ReactJSXElement[] = [];
    for(const [key, value] of Object.entries(enumType)) {
        if(typeof value === 'number') continue;
        if(typesToExcludes.includes(key.toUpperCase())) continue;
        const label = value.toUpperCase();
        options.push(
            <option key={key} value={key.toUpperCase()}>
                {intl.formatMessage({id: `${intl_prefix}${label}`})}
            </option>
        );
    }
    return options;
}
