import { Verbal } from '../../app/modules/verbals/models/VerbalModel';

export type ErrorType = {
  isError: true;
  code: string;
  intlIdCode: string; // code da fornire a intl per una successiva traduzione
};
export abstract class VerbalsService {
  abstract get(id: string): Promise<Verbal | ErrorType>;
  abstract getAll(): Promise<Partial<Verbal>[] | false>;
  abstract delete(verbal: Verbal): Promise<boolean>;
}
