import {ChangeEvent, FC, useState} from "react";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {useIntl} from "react-intl";
import {Popover} from "@mui/material";
import {PalettePopover} from "./PalettePopover";
import {TextAndColorItem} from "../../../../models/textAndColorItem.model";
import {Choice} from './Choice';
import {newChoice} from "../../../../state/template";
import {isValidIndex} from "../../../../utilities/dialogUtilities";
import {AlertErrors} from "../../../../../../../_metronic/components/alerts/Errors";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "../../../../../../../_metronic/components/Button";


type Props = {
    choiceFamily?: TextAndColorItem[],
    closePopover: () => void,
    onSave: (items: TextAndColorItem[]) => void,
    maxItems?: number,
}
const ListForm: FC<Props> = ({choiceFamily = [newChoice()], closePopover, onSave, maxItems}) => {
    const intl = useIntl()
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [choices, setChoices] = useState(choiceFamily)
    const [paletteIndexChose, setPaletteIndexChose] = useState<number>(0)
    const [errors, setErrors] = useState<string[]>([])

    const openPopoverPalette = (event: any, index: number) => {
        setAnchorEl(event.currentTarget);
        setPaletteIndexChose(index)
    };

    const handleClosePalette = (): void => {
        setAnchorEl(null);
    };

    const addChoice = (): void => {
        setChoices([...choices, newChoice()])
    }

    const deleteChoice = (index: number) => {
        if(choices.length === 1) return // ci deve essere ALMENO una scelta
        const choicesClone = [...choices]
        choicesClone.splice(index, 1)
        setChoices(choicesClone)
    }

    const editChoiceColor = (index: number, color: string): void => {
        const choicesClone = [...choices]
        const choiceClone = {...choicesClone[index], color}
        choicesClone.splice(index, 1, choiceClone)
        setChoices(choicesClone)
        handleClosePalette()
    }

    const editChoiceText = (event: ChangeEvent<HTMLInputElement>): void => {
        const indexString = event.target.getAttribute('choiceindex'); // per risalire alla choice selezionata
        if(!indexString) return
        const index = parseInt(indexString);
        if(!isValidIndex(index)) return
        const choicesClone = [...choices]
        const choiceClone= {...choicesClone[index], text: event.target.value}
        choicesClone.splice(index, 1, choiceClone)
        setChoices(choicesClone)
    }

    const openPalette = Boolean(anchorEl);

    const handleRenderPalette: ReactJSXElement = (
        <Popover key={'palettePopover'} id={'list-form-dialog-popover'} open={openPalette} anchorEl={anchorEl}
                 onClose={handleClosePalette} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
            <PalettePopover choiceIndex={paletteIndexChose} onClickPicker={editChoiceColor}/>
        </Popover>
    )

    const handleRenderChoices = (): ReactJSXElement[] => {
        let res: ReactJSXElement[] = []
        choices.map( (choice: TextAndColorItem, index: number) => {
            return res.push(<Choice choice={choice} key={index} deleteChoice={deleteChoice}
                             openPopover={openPopoverPalette} index={index} onChangeInput={editChoiceText}/>)
        })
        res.push(handleRenderPalette)
        return res
    }

    const thereAreMissingDescription = (): boolean => {
        if(choices.some((c: TextAndColorItem) => !(c.text))){
            setErrors([intl.formatMessage({ id: 'MULTICHOICE.LIST_FORM.MISSING_DESCRIPTIONS' })])
            return true
        }
        return false
    }

    const formIsValid = (): boolean => {
        if(thereAreMissingDescription()) return false
        return true
    }

    const validateForm = (): void => {
        if(!formIsValid()) return;
        closePopover()
        onSave(choices)
    }

    const handleRenderAddChoiceButton = () => {
        const canAddAnotherChoice: boolean = !maxItems || choices.length < maxItems;
        return (
            <div className={'cursor-pointer my-5 sm:my-0'} {...(canAddAnotherChoice && {onClick: addChoice})}>
                <div>
                    {canAddAnotherChoice && (
                        <>{intl.formatMessage({ id: 'QUESTION.MULTICHOICE.LISTFORM.ADD_CHOICE' })}<AddIcon/></>
                    )}
                </div>
            </div>
        );
    }

    

    return (
        <div>
            <div>
                {handleRenderChoices()}
            </div>
            <AlertErrors errors={errors} />
            <div className={'flex flex-col sm:flex-row align-middle sm:justify-between mt-5'}>
                { handleRenderAddChoiceButton() }
                <div>
                    <Button
                        id='submit-new-template'
                        classes="w-full sm:w-auto"
                        innerText={intl.formatMessage({ id: 'GENERAL.FORM.SAVE' })}
                        otherProps={{type: 'submit', onClick: validateForm}}
                    />
                </div>
            </div>
        </div>
    )
}
export {ListForm}
