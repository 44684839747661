import { FC, ReactElement, useContext, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { RolesWrapper } from './details/RolesWrapper';
import { TabPanel, a11yProps } from '../../../../../components/tabs/TabPanel';
import { RoleTabsContext } from './context';
import { formMode } from '../../../../../models/creationMode.model';
import { PermissionsSection } from './permissions/PermissionsSection';

const RolesTabs: FC = (): ReactElement => {
  const [value, setValue] = useState(0);
  const intl = useIntl();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { mode } = useContext(RoleTabsContext);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={intl.formatMessage({ id: 'GENERIC.TABS.DETAILS' })}
            {...a11yProps('role', 0)}
          />
          {mode === formMode.edit && (
            <Tab
              label={intl.formatMessage({
                id: 'ROLES.TABS.PERMISSIONS_ASSOCIATED',
              })}
              {...a11yProps('role', 1)}
            />
          )}
        </Tabs>
      </Box>
      {/* Tab Ruoli */}
      <TabPanel idPrefix='role' value={value} index={0}>
        <RolesWrapper mode={mode} />
      </TabPanel>
      {/* Permessi associati */}
      <TabPanel idPrefix='role' value={value} index={1}>
        <PermissionsSection />
      </TabPanel>
    </>
  );
};

export { RolesTabs };
