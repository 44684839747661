import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoutes } from '../../routing/PrivateRoutes';
import { formMode } from '../../models/creationMode.model';
import { RoleForm } from './components/role_form/RoleFormPage';

const RolesRoutes: FC = () => {
  let match = useRouteMatch();

  return (
    <>
      <PrivateRoutes>
        <Switch>
          <Route exact path={`${match.path}/edit/:id`}>
            <RoleForm mode={formMode.edit} />
          </Route>
          <Route exact path={match.path}>
            <RoleForm mode={formMode.create} />
          </Route>
        </Switch>
      </PrivateRoutes>
    </>
  );
};

export { RolesRoutes };
