import { Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  idPrefix: string;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, idPrefix, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`${idPrefix}-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: '5px' }}>{children}</Box>}
    </div>
  );
}

export function a11yProps(idPrefix: string, index: number) {
  return {
    id: `${idPrefix}-tab-${index}`,
  };
}
