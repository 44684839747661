/**
 * Check Token Expiration
 * Session Data is read from Local Storage and Not from Recoil State because in this way is possible to check token
 * validity even if the user leaves the tab inactive.
 * @return True if token has been expired
 */
export function useIsTokenExpired(): boolean {
    const tokenRecoilState = JSON.parse(localStorage.getItem('session') as string);
    if(!tokenRecoilState){ return true; }
    const {expiresAt: expiresInValue } = tokenRecoilState;
    return Date.now() - expiresInValue >= 0;
}
