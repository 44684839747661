import { ReactElement, useContext } from 'react';
import { useIntl } from 'react-intl';
import { DataGridCustomized } from '../../../../../../theme/DataGrid';
import { UserModel } from '../../../../../models/UserModel';
import { UsersContext } from '../context';
import { usersDGColumnsDefinition } from './DataGrid';

const UnbindUsersTable = ({
  users,
}: {
  users: Partial<UserModel>[];
}): ReactElement => {
  const intl = useIntl();
  const { setUsersSelected, usersToUnbind } = useContext(UsersContext);

  const onRowSelection = (usersIds: string[]) => {
    setUsersSelected({
      usersToBind: usersIds,
      usersToUnbind,
    });
  };

  return (
    <div className='h-full'>
      <DataGridCustomized
        columns={usersDGColumnsDefinition(intl)}
        rows={users}
        props={{
          checkboxSelection: true,
          density: 'compact',
          onSelectionModelChange: (newSelectionModel: string[]) =>
            onRowSelection(newSelectionModel),
        }}
      />
    </div>
  );
};

export { UnbindUsersTable };
