import {
  getFirestore,
  Firestore,
  collection,
  getDoc,
  doc,
  runTransaction,
  CollectionReference,
} from 'firebase/firestore';
import { inject, singleton } from 'tsyringe';
import { Actor } from '../../app/models/Actor.model';
import { ActorsService } from '../contracts/ActorsService';
import { actorsCollectionName } from '../GCPUtilities';

@singleton()
export class GCPActorsService extends ActorsService {
  private readonly firestore: Firestore;
  actorsCollectionName = actorsCollectionName;
  private readonly actorsRef: CollectionReference;
  protected readonly tenant: string;

  constructor(@inject('Tenant') tenant: string) {
    super();
    this.tenant = tenant;
    this.firestore = getFirestore();
    this.actorsRef = collection(
      doc(this.firestore, 'tenants', tenant),
      this.actorsCollectionName
    );
  }

  /**
   * Get the role of the current user
   */
  public async getByUser(userId: string): Promise<Actor | null> {
    try {
      const actorRef = doc(this.actorsRef, userId);
      const actorSnapshot = await getDoc(actorRef);
      if (actorSnapshot.exists()) {
        return { ...actorSnapshot.data(), id: userId } as Actor;
      }
      return null;
    } catch (error: unknown) {
      console.error('Errore in fase di recupero ruolo utente');
      return Promise.reject(error);
    }
  }

  /**
   * Get an actor starting from its id
   * @param id: the actor id
   */
  public get = async (id: string): Promise<Actor> => {
    try {
      const actorReference = doc(this.actorsRef, id);
      const actorSnapshot = await getDoc(actorReference);
      // @ts-ignore
      return { ...actorSnapshot.data(), id: actorSnapshot.id };
    } catch (error: unknown) {
      console.error('Fetch Actor fallito');
      return Promise.reject(error);
    }
  };

  public delete = async (id: string): Promise<void> => {
    try {
      await runTransaction(this.firestore, async (transaction) => {
        const templateReference = doc(this.actorsRef, id);
        transaction.delete(templateReference);
      });
    } catch (error: unknown) {
      console.error(`Errore in fase di cancellazione della categoria`, error);
      return Promise.reject(error);
    }
  };

  /**
   * Create a new category via transaction operation
   * @param categoryData: the category to create
   * @returns the created category or false if errors
   **/
  public create = async (
    userId: string,
    data: Partial<Actor>
  ): Promise<string> => {
    try {
      const newActorReference = doc(this.actorsRef, userId);
      await runTransaction(this.firestore, async (transaction) => {
        transaction.set(newActorReference, data);
      });
      return Promise.resolve(newActorReference.id);
    } catch (err: unknown) {
      console.error('Errore in fase di creazione actor');
      return Promise.reject(false);
    }
  };

  public update = async (
    userId: string,
    data: Partial<Actor>
  ): Promise<void> => {
    try {
      const actorReference = doc(this.actorsRef, userId);
      await runTransaction(this.firestore, async (transaction) => {
        transaction.update(actorReference, data);
      });
    } catch (error: unknown) {
      console.error('Errore in fase di aggiornamento actor');
      return Promise.reject(error);
    }
  };

  public upsert = async (userId: string, roleId: string): Promise<void> => {
    try {
      const actorReference = await getDoc(doc(this.actorsRef, userId));
      if (actorReference.exists()) {
        this.update(userId, { roleId });
      } else {
        this.create(userId, { roleId });
      }
    } catch (error: unknown) {
      console.error('Errore in fase di aggiornamento actor');
      return Promise.reject(error);
    }
  };
}
