import { createContext } from "react";

const initialUsersSelections: {
  usersToBind: string[];
  usersToUnbind: string[];
  setUsersSelected: CallableFunction;
  bindTenantFn: CallableFunction;
} = {
  usersToBind: [],
  usersToUnbind: [],
  setUsersSelected: () => {},
  bindTenantFn: (usersIds: string[]) => {},
};

export const UsersContext = createContext(initialUsersSelections);