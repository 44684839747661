import { JobSiteModel } from '../../app/models/JobSite';

export type ErrorType = {
  isError: true;
  code: string;
  intlIdCode: string; // code da fornire a intl per una successiva traduzione
};
export abstract class JobSitesService {
  abstract get(id: string): Promise<JobSiteModel | ErrorType>;
  abstract create(jobSite: JobSiteModel): Promise<true | ErrorType>;
  abstract delete(jobSite: JobSiteModel): Promise<true | ErrorType>;
  abstract update(jobSite: JobSiteModel): Promise<true | ErrorType>;
  abstract getAll(): Promise<JobSiteModel[] | ErrorType>;
  abstract unbindUser(jobSiteId: string, userId: string): Promise<boolean>;
  abstract bindUsers(jobSiteId: string, userIds: string[]): Promise<boolean>;
}
