import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { ChoicesListDialog } from './partials/ChoicesListDialog';
import {
  handleRenderChips,
  handleRenderRemainingChips,
} from '../../../utilities/dialogUtilities';
import { TextAndColorItem } from '../../../models/textAndColorItem.model';
import { updateQuestionType } from '../../../models/updateQuestionType';
import {
  newMultichoiceTextColor,
  newRadioTextColorItem,
} from '../../../state/template';

/**
 * Return currect selected option otherwise the defaults (*)
 * @param options: the selected options
 * @param radioMode: true if is radio type
 */
const setOptions = (
  options: TextAndColorItem[],
  radioMode: boolean
): TextAndColorItem[] => {
  let _options = options;
  if (options.length === 0) {
    // (*)
    _options =
      radioMode && options.length === 0
        ? newRadioTextColorItem()
        : newMultichoiceTextColor();
  }
  return _options;
};

type Props = {
  options: TextAndColorItem[];
  onChangeInput: updateQuestionType;
  radioMode?: boolean;
};
const MultiChoiceType: FC<Props> = ({ options, onChangeInput, radioMode }) => {
  const isRadio = radioMode ?? false;
  const intl = useIntl();
  const [modalState, setModalState] = useState<boolean>(false);
  options = setOptions(options, !!radioMode);

  const openModal = (): void => setModalState(true);

  const closeModal = (): void => setModalState(false);

  return (
    <div className={'pb-5'}>
      <div className={'w-full xl:w-1/2'}>
        {/* Chooses label */}
        <h3 className='text-lg'>
          {intl.formatMessage({ id: 'MULTICHOICE.CHOICES_LABEL' })}
        </h3>
        {/* TextAndColorItem Choosed + Edit Button */}
        <div className='mt-5'>
          <div className='rounded-md bg-gray-50 px-2 py-5 flex justify-between'>
            <div className='flex items-center gap-x-2'>
              {handleRenderChips(options)} {/* e.g. (alto), (medio), (basso) */}
              {handleRenderRemainingChips(options)} {/* e.g. '+1'*/}
            </div>
            <div>
              <button
                type='button'
                className='inline-flex items-center px-5 py-3 border border-gray-300
                             shadow-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2
                             focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
                onClick={openModal}
              >
                {intl.formatMessage({ id: 'GENERAL.FORM.EDIT' })}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ChoicesListDialog
        open={modalState}
        handleClose={closeModal}
        onChangeInput={onChangeInput}
        items={options}
        radioMode={isRadio}
      />
    </div>
  );
};
export { MultiChoiceType };
