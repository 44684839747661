import 'reflect-metadata';
import ReactDOM from 'react-dom';
import * as _redux from './setup';
import { Chart, registerables } from 'chart.js';

// Apps
import { App } from './app/App';
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
import './_metronic/assets/sass/style.scss';
import { RecoilRoot } from 'recoil';
import './index.css';
import AppModule from './di/AppModule';
import { initializeFirebase } from './firebase';
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

_redux.setupAxios();
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

Chart.register(...registerables);
initializeFirebase();
AppModule();

ReactDOM.render(
  <RecoilRoot>
    <MetronicI18nProvider>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      {/* <PersistGate persistor={persistor} loading={<div>Loading...</div>}> */}
      <App basename={PUBLIC_URL} />
      {/* </PersistGate> */}
    </MetronicI18nProvider>
  </RecoilRoot>,
  document.getElementById('root')
);
