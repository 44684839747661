import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { formMode } from '../../../models/creationMode.model';
import { CompaniesSummary } from './CompaniesSummary';

type Props = {
  mode?: formMode;
};

const CompaniesSummaryWrapper: FC<Props> = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.COMPANIES' })}
      </PageTitle>
      <CompaniesSummary />
    </>
  );
};

export { CompaniesSummaryWrapper };
