import { Theme } from '@mui/material/styles';
// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          right: '6px'
        },
        inputRoot: {
          border: '1px solid #e4e6ef',
          borderRadius: '0.375rem',
          backgroundColor: '#f5f8fa',
          padding: '.3rem .75rem .3rem .75rem'
        },
      },
    },
  };
}
