import { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { CloseButton } from '../../../../../../../components/dialogs/CloseButton';

type Props = {
  setDialogState: (state: boolean) => void;
  isOpen: boolean;
  title: string;
  ActionButton: ReactElement;
};
const PermissionsDialogWithRowsSelection: FC<Props> = ({
  setDialogState,
  isOpen,
  title,
  ActionButton,
  children,
}): ReactElement => {
  return (
    <Dialog
      onClose={() => setDialogState(false)}
      aria-labelledby='customized-dialog-title'
      open={isOpen}
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {title}
        <CloseButton setDialogState={setDialogState} />
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Box>{ActionButton}</Box>
        </Box>
        {/* Tabella risultati */}
        <Box sx={{ marginTop: '15px' }}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
};

export { PermissionsDialogWithRowsSelection };
