import { FC, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { container } from 'tsyringe';
import { UsersService } from '../../../../../service/contracts/UsersService';
import { UsersTableWrapper } from './UsersTable/UsersTableWrapper';
import { AddUserSection } from './AddUser/AddUserSection';
import { useIntl } from 'react-intl';
import { UsersContext } from './context';

const UsersSection: FC = (): ReactElement => {
  const intl = useIntl();
  const usersService = container.resolve<UsersService>('UsersService');
  const unbindButtonElement = useRef(null);
  const [dialogState, setDialogState] = useState(false);
  const [usersSelected, setUsersSelected] = useState({
    usersToBind: [],
    usersToUnbind: [],
  });
  const [trigger, setTrigger] = useState(false);

  const handleRenderUnbindButton = (): ReactElement => {
    if (usersSelected.usersToUnbind?.length > 0) {
      return (
        <Box>
          {/* Pulsante Disassocia utenti */}
          <button
            onClick={async () => {
              // @ts-ignore
              unbindButtonElement.current!.setAttribute('disabled', true);
              await usersService.unbindTenants(usersSelected.usersToUnbind);
              // @ts-ignore
              unbindButtonElement.current!.removeAttribute('disabled');
              setUsersSelected({ ...usersSelected, usersToUnbind: [] });
              setTrigger(!trigger);
            }}
            ref={unbindButtonElement}
            className={`items-center px-10 py-3 border rounded-md bg-blue-500 disabled:opacity-25 font-bold`}
          >
            <span className='text-white'>
              {intl.formatMessage({ id: 'USERS.UNBIND_USERS' })}
            </span>
          </button>
        </Box>
      );
    }
    return <></>;
  };

  const bindTenants = async (usersIds: string[]) => {
    await usersService.bindTenants(usersIds);
    setTrigger(!trigger);
  };

  return (
    <UsersContext.Provider
      value={{
        ...usersSelected,
        setUsersSelected,
        bindTenantFn: bindTenants,
      }}
    >
      {/* (1) Disassocia selezionati e Aggiunta Utente + (2) Tabella Utenti */}
      <Box sx={{ width: '100%' }}>
        {/* (1) */}
        <Box
          sx={{
            marginBottom: '7px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <AddUserSection
              setDialogState={setDialogState}
              isOpen={dialogState}
            />
          </Box>
          <Box>{handleRenderUnbindButton()}</Box>
        </Box>
        {/* (2) */}
        <Box>
          <UsersTableWrapper trigger={trigger} />
        </Box>
      </Box>
    </UsersContext.Provider>
  );
};

export { UsersSection };
