import { NonConformity } from '../../app/modules/non_conformities/models/NonConformityModel';

export type ErrorType = {
  isError: true;
  code: string;
  intlIdCode: string; // code da fornire a intl per una successiva traduzione
};
export abstract class NonConformityService {
  abstract get(id: string): Promise<NonConformity | ErrorType>;
  abstract getAll(): Promise<Partial<NonConformity>[] | false>;
  abstract delete(nonConformity: NonConformity): Promise<boolean>;
}
