import { inject, singleton } from 'tsyringe';
import {
  runTransaction,
  Firestore,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  collection,
  CollectionReference,
  query,
  updateDoc,
  DocumentReference,
} from 'firebase/firestore';
import { RolesService } from '../contracts/RolesService';
import { Role } from '../../app/modules/roles/models/Role.model';
import { rolesCollectionName, tenantsCollectionName } from '../GCPUtilities';
import { Permission } from '../../app/modules/roles/models/Permission.model';

@singleton()
export class GCPRolesService extends RolesService {
  private readonly firestore: Firestore;
  private readonly tenantsCollectionName = tenantsCollectionName;
  private readonly rolesCollectionName = rolesCollectionName;
  private readonly rolesCollectionRef: CollectionReference;
  protected readonly tenant: string;

  constructor(@inject('Tenant') tenant: string) {
    super();
    this.tenant = tenant;
    this.firestore = getFirestore();
    this.rolesCollectionRef = collection(
      this.firestore,
      this.tenantsCollectionName,
      this.tenant,
      this.rolesCollectionName
    );
  }

  public async getMany(): Promise<Role[]> {
    try {
      let roles: Role[] = [];
      const rolesQuerySnapshot = await getDocs(query(this.rolesCollectionRef));
      rolesQuerySnapshot.forEach((t: any) => {
        roles.push({ ...t.data(), id: t.id });
      });
      return roles;
    } catch (error: unknown) {
      return Promise.reject(error);
    }
  }

  public async getManyNotInThisIds(ids: string[]): Promise<Role[]> {
    try {
      const roles = await this.getMany();
      return roles.filter((r) => !ids.includes(r.id));
    } catch (error: unknown) {
      return Promise.reject(error);
    }
  }

  public async get(id: string): Promise<Role> {
    try {
      const roleReference = doc(this.rolesCollectionRef, id);
      const roleSnapshot = await getDoc(roleReference);
      // @ts-ignore
      return { ...roleSnapshot.data(), id: roleSnapshot.id };
    } catch (error: unknown) {
      console.log('Fetch ruolo fallito');
      return Promise.reject(error);
    }
  }

  public async create(role: Role): Promise<string> {
    try {
      const rolesDocuments = doc(this.rolesCollectionRef);
      await runTransaction(this.firestore, async (transaction) => {
        transaction.set(rolesDocuments, role);
      });
      return Promise.resolve(rolesDocuments.id);
    } catch (error: unknown) {
      console.error('Errore in fase di creazione utente:');
      return Promise.reject(error);
    }
  }

  public async unbindPermission(
    roleId: string,
    permissionId: string
  ): Promise<void> {
    try {
      const role = await this.get(roleId);
      const permissionsUpdated = role.permissions.filter(
        (u) => u.id !== permissionId
      );
      const roleReference = doc(this.rolesCollectionRef, roleId);
      await updateDoc(roleReference, {
        ...role,
        permissions: permissionsUpdated,
      });
    } catch (error: unknown) {
      return Promise.reject(false);
    }
  }

  public async bindPermissions(
    roleId: string,
    permissions: Permission[]
  ): Promise<void> {
    try {
      // (1)
      const role = await this.get(roleId);
      const existingPermissions = role.permissions ?? [];
      const existingPermissionsIds = existingPermissions.map((u) => u.id);
      // (2)
      const newPermissions = permissions.filter(
        (p) => !existingPermissionsIds.includes(p.id)
      );
      const permissionsUpdated = [...existingPermissions, ...newPermissions];
      await updateDoc(doc(this.rolesCollectionRef, roleId!), {
        ...role,
        permissions: permissionsUpdated,
      });
    } catch (error: unknown) {
      return Promise.reject(error);
    }
  }

  public async update(role: Role): Promise<void> {
    try {
      const roleReference = this.getRoleReference(role.id);
      await runTransaction(this.firestore, async (transaction) => {
        transaction.update(roleReference, role);
      });
    } catch (error: unknown) {
      console.error('Errore in fase di Aggiornamento utente');
      return Promise.reject(error);
    }
  }

  private getRoleReference = (roleId: string): DocumentReference<any> => {
    return doc(this.rolesCollectionRef, roleId);
  };

  public async delete(roles: Partial<Role>[]): Promise<boolean> {
    try {
      await runTransaction(this.firestore, async (transaction) => {
        roles.forEach((role) => {
          const roleReference = doc(this.rolesCollectionRef, role.id);
          transaction.delete(roleReference);
        });
      });
      return Promise.resolve(true);
    } catch (error: unknown) {
      console.error('Errore in fase di cancellazione utente:', error);
      return Promise.reject(error);
    }
  }
}
