export const getErrorMsg = (element: any, formik: any): string | undefined => {
    if(formik.touched[element] && formik.errors[element])
        return formik.errors[element]
    return undefined
}

export const getNewErrorMsg = (element: any, formik: any): string | undefined => {
    if(formik.touched[element] && formik.errors[element])
        return formik.errors[element]
    return undefined
}

export const thereIsError = (element: any, formik : any) : boolean => {
    return !!(formik.touched[element] && formik.errors[element]);
}

export const isTouched = (element: any, formik: any): boolean => {
    return !!(formik.touched[element]);
}
