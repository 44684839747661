import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { IntlShape } from 'react-intl';
import { DataGridActionsColumn } from '../../../../_metronic/components/datagrid/DataGridActionsColumn';
import { showDateInItFormat } from '../../../../_metronic/utilities/utilities';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

export const nonConformityListDataGridSettings = (
  intl: IntlShape,
  onDelete: CallableFunction,
  handleDownloadUrl: CallableFunction,
  handleVerbalDownloadUrl: CallableFunction
): GridColDef[] => [
  {
    field: 'name',
    headerName: intl.formatMessage({
      id: 'NON_CONFORMITY.GENERAL_DESCRIPTION_LABEL',
    }),
    sortable: true,
    flex: 1,
    renderCell: (params: GridValueGetterParams): GridCellValue => (
      <Box>
        <Typography sx={{ fontWeight: 600 }}>{params.row.name}</Typography>
      </Box>
    ),
  },
  {
    field: 'updatedAt',
    headerName: intl.formatMessage({ id: 'GENERAL.UPDATED_AT' }),
    sortable: true,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => {
      // @ts-ignore
      return showDateInItFormat(params.value);
    },
  },
  {
    field: 'linkedVerbal',
    headerName: intl.formatMessage({ id: 'NON_CONFORMITY.DATAGRID.VERBAL' }),
    sortable: true,
    flex: 1,
    renderCell: (params: GridValueGetterParams): GridCellValue => {
      if (params.row.linkedVerbal === '') {
        return <></>;
      }
      return (
        <>
          <Button
            href={handleVerbalDownloadUrl(params.row)}
            target='_blank'
            download
          >
            <IconButton>
              <DownloadIcon />
            </IconButton>
          </Button>
          {params.row.linkedVerbal}
        </>
      );
    },
  },
  {
    field: 'sub',
    headerName: intl.formatMessage({ id: 'GENERAL.TABLE.ACTIONS_LABEL' }),
    renderCell: (param: GridRenderCellParams<string>): ReactJSXElement => (
      <DataGridActionsColumn
        param={param}
        onDelete={onDelete}
        downloadPath={handleDownloadUrl}
      />
    ),
    sortable: false,
    filterable: false,
    hide: false,
    disableColumnMenu: true,
  },
];
