import AddIcon from '@mui/icons-material/Add';
import { Button } from '../../../../_metronic/components/Button';
import { FC } from 'react';
import { useIntl } from 'react-intl';
type Props = {
  submitAction: () => void;
  addSubEntityLabel: string;
  addSubEntityAction: () => void;
  withoutSave?: boolean;
  loading?: boolean;
};

const FormActions: FC<Props> = ({
  submitAction,
  addSubEntityLabel,
  addSubEntityAction,
  withoutSave = false,
  loading,
}) => {
  const intl = useIntl();

  const handleRenderButtons = () => {
    if (withoutSave) {
      return <></>;
    }
    if (loading) {
      return (
        <div>
          <button className={`items-center px-10 py-1 border rounded-md`}>
            <div className='spinner-border text-muted' role='status'></div>
          </button>
        </div>
      );
    }
    return (
      <div>
        <Button
          id='submit-new-template'
          classes='w-full sm:w-auto'
          innerText={intl.formatMessage({ id: 'GENERAL.FORM.SAVE' })}
          otherProps={{ type: 'submit', onClick: submitAction }}
        />
      </div>
    );
  };
  return (
    <div
      className={
        'flex flex-col sm:flex-row align-middle sm:justify-between mt-5'
      }
    >
      <div
        className={'cursor-pointer my-5 sm:my-0'}
        onClick={addSubEntityAction}
      >
        <div>
          {addSubEntityLabel}
          <AddIcon />
        </div>
      </div>
      {handleRenderButtons()}
    </div>
  );
};

export { FormActions };
