import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { container } from 'tsyringe';
import { DeletionModal } from '../../../../_metronic/components/modals/DeletionModal';
import { usersDGColumnsDefinition } from '../settings/DataGrid';
import { VerbalsService } from '../../../../service/contracts/VerbalsService';
import { AlertErrors } from '../../../../_metronic/components/alerts/Errors';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Verbal } from '../models/VerbalModel';
import { DataGridCustomized } from '../../../../theme/DataGrid';
import { useRecoilValue } from 'recoil';
import { userState } from '../../sikuro_auth/state/atoms/user';

const VerbalsTable: FC = () => {
  const intl = useIntl();
  const verbalsService = container.resolve<VerbalsService>('VerbalsService');
  const [verbals, setVerbals] = useState<Verbal[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [verbalToDelete, setVerbalToDelete] = useState<Verbal>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const userData = useRecoilValue(userState);

  useEffect(() => {
    async function getVerbals() {
      let response: Partial<Verbal>[] | false = await verbalsService.getAll();
      setLoading(false);
      if (response) return setVerbals(response as Verbal[]);
      setError('Qualcosa è andato male');
    }
    setLoading(true);
    getVerbals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verbalToDelete, userData]);

  const handleRenderErrors = (error: string | undefined): ReactJSXElement => {
    if (!error) return <></>;
    return <AlertErrors errors={[error]} />;
  };

  const deleteVerbal = async () => {
    if (!verbalToDelete) return;
    if (!verbalToDelete.id)
      return console.log('Qualcosa è andato storto, Id Assente!');
    await verbalsService.delete(verbalToDelete);
    setOpenDeleteModal(false);
    setVerbalToDelete(undefined);
  };

  const verbalToDeleteString = (): string => {
    if (!verbalToDelete) return '';
    return verbalToDelete.name;
  };

  const openDeletionModal = (verbal: Verbal) => {
    setOpenDeleteModal(true);
    setVerbalToDelete(verbal);
  };

  const handleDownloadUrl = (verbal: Verbal) => {
    return verbal.pdfUrl;
  };

  const columns = usersDGColumnsDefinition(
    intl,
    openDeletionModal,
    handleDownloadUrl
  );

  return (
    <div className='container mw-100 px-0'>
      <div className='h-full'>
        <div className='my-5'>{handleRenderErrors(error)}</div>
        <DataGridCustomized
          columns={columns}
          rows={verbals}
          props={{
            loading,
          }}
        />
      </div>
      <DeletionModal
        open={openDeleteModal}
        setModalState={setOpenDeleteModal}
        onConfirm={deleteVerbal}
        entityString={verbalToDeleteString()}
      />
    </div>
  );
};

export { VerbalsTable };
