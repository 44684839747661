import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as formUtils from '../utils/formUtils';
import { IntermediateMessage } from './IntermediateMessage';
import { ButtonText } from '../../../../_metronic/components/ButtonText';
import { ButtonWrapper } from '../../../../_metronic/components/ButtonWrapper';
import { Input } from '../../../../_metronic/layout/components/form/Input';
import { container } from 'tsyringe';
import {
  AuthErrorType,
  AuthService,
  loginOutputType,
} from '../../../../service/contracts/AuthService';

export function Registration() {
  const intl = useIntl();
  const authService = container.resolve<AuthService>('AuthService');
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    changepassword: '',
    acceptTerms: false,
  };

  const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(
        2,
        intl.formatMessage({
          id: 'AUTH.REGISTRATION.FORM_NAME_MIN_LENGTH_MSG_ERROR',
        })
      )
      .max(
        50,
        intl.formatMessage({ id: 'AUTH.GENERAL.FIELD_MAX_50_CHARACTERS' })
      )
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' })),
    lastname: Yup.string()
      .min(
        2,
        intl.formatMessage({
          id: 'AUTH.REGISTRATION.FORM_SURNAME_MIN_LENGTH_MSG_ERROR',
        })
      )
      .max(
        50,
        intl.formatMessage({ id: 'AUTH.GENERAL.FIELD_MAX_50_CHARACTERS' })
      )
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' })),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'AUTH.GENERAL.FORMAT_NOT_VALID' }))
      .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.FIELD_MIN_3_CHARACTERS' }))
      .max(
        50,
        intl.formatMessage({ id: 'AUTH.GENERAL.FIELD_MAX_50_CHARACTERS' })
      )
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' })),
    password: Yup.string().required(
      intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' })
    ),
    changepassword: Yup.string()
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' }))
      .when('password', {
        is: (val: string) => !!(val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          intl.formatMessage({ id: 'AUTH.GENERAL.PASSWORD_MUST_BE_EQUALS' })
        ),
      }),
    acceptTerms: Yup.bool().required(
      intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' })
    ),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      authService
        .register(
          values.email,
          values.firstname,
          values.lastname,
          values.password
        )
        .then((response: loginOutputType | AuthErrorType) =>
          manageSuccess(response, setStatus, setSubmitting)
        );
    },
  });

  const manageError = (
    error: AuthErrorType,
    setStatus: Function,
    setSubmitting: Function
  ) => {
    setLoading(false);
    setSubmitting(false);
    setHasErrors(true); // per disabilitare pulsanti "Registrati"
    setStatus(intl.formatMessage({ id: error.intlIdCode }));
  };

  const manageSuccess = (
    response: loginOutputType | AuthErrorType,
    setStatus: Function,
    setSubmitting: Function
  ) => {
    if ('isError' in response)
      return manageError(response, setStatus, setSubmitting); // narrowing seguendo linee guida doc. TS
    setLoading(false);
    setSubmitting(true);
    setHasErrors(false);
    setStatus(intl.formatMessage({ id: 'AUTH.REGISTRATION.FORM_SUCCESS_MSG' }));
  };

  const formStatus = (): JSX.Element => {
    return (
      formik.status && (
        <div className='mb-lg-15'>
          <div
            className={clsx(
              'font-weight-bold',
              { 'alert alert-danger': hasErrors },
              { 'alert alert-success': !hasErrors }
            )}
          >
            {formik.status}
          </div>
        </div>
      )
    );
  };

  if (hasErrors === false)
    return (
      <IntermediateMessage
        type='success'
        message={intl.formatMessage({
          id: 'AUTH.REGISTRATION.FORM_SUCCESS_MSG',
        })}
      />
    );

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3 text-2xl font-bold'>
          {intl.formatMessage({ id: 'AUTH.REGISTRATION.FORM_TITLE' })}
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          {intl.formatMessage({ id: 'AUTH.REGISTRATION.FORM_MSG' })}
          <Link
            to='/login'
            className='primary-text font-bold ms-1'
            style={{ marginLeft: '5px' }}
          >
            {intl.formatMessage({ id: 'AUTH.REGISTRATION.FORM_BUTTON' })}
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {formStatus()}

      {/* begin::Form group (Firstname, Lastname) */}
      <div className='row mb-5'>
        <Input
          label={intl.formatMessage({ id: 'AUTH.GENERAL.USER_NAME' })}
          id='firstname'
          formikProps={{ ...formik.getFieldProps('firstname') }}
          className='col-xl-6'
          errorMsg={formUtils.getErrorMsg('firstname', formik)}
          isTouched={formUtils.isTouched('firstname', formik)}
          thereIsError={formUtils.thereIsError('firstname', formik)}
        />
        <Input
          label={intl.formatMessage({ id: 'AUTH.GENERAL.USER_SURNAME' })}
          id='lastname'
          formikProps={{ ...formik.getFieldProps('lastname') }}
          className='col-xl-6'
          errorMsg={formUtils.getErrorMsg('lastname', formik)}
          isTouched={formUtils.isTouched('lastname', formik)}
          thereIsError={formUtils.thereIsError('lastname', formik)}
        />
      </div>
      {/* end::Form group (Firstname, Lastname) */}

      {/* begin::Form Email */}
      <div className='mb-5'>
        <Input
          label={intl.formatMessage({ id: 'AUTH.REGISTRATION.FORM_EMAIL' })}
          id='email'
          type='email'
          formikProps={{ ...formik.getFieldProps('email') }}
          errorMsg={formUtils.getErrorMsg('email', formik)}
          isTouched={formUtils.isTouched('email', formik)}
          thereIsError={formUtils.thereIsError('email', formik)}
        />
      </div>
      {/* end:: Form Email */}
      {/* begin::Form Password */}
      <div className='mb-5'>
        <Input
          label={intl.formatMessage({ id: 'AUTH.REGISTRATION.FORM_PASSWORD' })}
          id='email'
          type='password'
          formikProps={{ ...formik.getFieldProps('password') }}
          errorMsg={formUtils.getErrorMsg('password', formik)}
          isTouched={formUtils.isTouched('password', formik)}
          thereIsError={formUtils.thereIsError('password', formik)}
        />
      </div>
      {/* end:: Form Password */}
      {/* begin::Form Confirm Password */}
      <div className='mb-5'>
        <Input
          label={intl.formatMessage({
            id: 'AUTH.REGISTRATION.FORM_CONFIRM_PASSWORD',
          })}
          id='changepassword'
          type='password'
          formikProps={{ ...formik.getFieldProps('changepassword') }}
          errorMsg={formUtils.getErrorMsg('changepassword', formik)}
          isTouched={formUtils.isTouched('changepassword', formik)}
          thereIsError={formUtils.thereIsError('changepassword', formik)}
        />
      </div>
      {/* end::Form Confirm Password */}
      {/* begin::Form Accept Terms */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            {intl.formatMessage({ id: 'AUTH.REGISTRATION.FORM_PRIVACY_MSG' })}
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form Accept Terms */}

      {/* begin::Form Submit */}
      <div className='text-center'>
        <ButtonWrapper
          id='kt_sign_up_submit'
          classes='w-full'
          otherProps={[
            {
              disabled:
                formik.isSubmitting ||
                !formik.isValid ||
                !formik.values.acceptTerms ||
                hasErrors,
            },
            { type: 'submit' },
          ]}
        >
          {!loading && (
            <ButtonText
              title={intl.formatMessage({
                id: 'AUTH.REGISTRATION.FORM_SUBMIT',
              })}
            />
          )}
          {loading && (
            <ButtonText
              classes='indicator-progress block'
              title={intl.formatMessage({ id: 'AUTH.LOGIN.WAIT_MSG' })}
            >
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </ButtonText>
          )}
        </ButtonWrapper>
      </div>
      {/* end::Form Submit */}
    </form>
  );
}
