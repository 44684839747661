import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { updateQuestionType } from '../../../models/updateQuestionType';
import { useRenderEnumInSelect } from '../../../../../../_metronic/hooks/useRenderEnumInSelect';
import { Select } from '../../../../../../_metronic/layout/components/form/Select';
import { EntityTypeEnum } from '../../../models/entityType.enum';

type Props = {
  correlationType: EntityTypeEnum;
  onChangeInput: updateQuestionType;
};
const CorrelationType: FC<Props> = ({ correlationType, onChangeInput }) => {
  const intl = useIntl();

  return (
    <div className={'pb-5'}>
      <div className={'w-full sm:w-1/3 flex gap-5 mt-2'}>
        <div>
          <Select
            id='correlationType'
            label={intl.formatMessage({ id: 'QUESTION.CORRELATION_TITLE' })}
            onChangeInput={onChangeInput}
            value={correlationType}
          >
            {useRenderEnumInSelect(EntityTypeEnum, 'QUESTION.ENTITIES_')}
          </Select>
        </div>
      </div>
    </div>
  );
};
export { CorrelationType };
