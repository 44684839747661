import { FC } from 'react';
import { useRecoilState } from 'recoil';
import { nonConformityState } from '../state/nonConformity';
import { QuestionModel } from '../../../pages/templates/models/question.model';
import { Question } from '../../../pages/templates/components/question/Question';
import { updateQuestionType } from '../../../pages/templates/models/updateQuestionType';
import { formMode } from '../../../models/creationMode.model';
import {
  getNewValue,
  getQuestionCleaned,
} from '../../../pages/templates/components/question/QuestionWrapper';

type Props = {
  mode: formMode;
  otherProps?: Object;
  entity: QuestionModel;
  sectionIndex: number;
  errors: { field: string, message: string }[]
};

/**
 * Wrapper intorno al componente question. Gestisce il suo stato: in questo caso TEMPLATE
 * */
const QuestionWrapper: FC<Props> = ({
  mode,
  otherProps,
  entity,
  sectionIndex,
    errors
}) => {
  const [nonConformity, setNonConformity] = useRecoilState(nonConformityState);
  const oldQuestions = [...nonConformity.questions];
  const questionIndex = oldQuestions.findIndex((q) => q.order === entity.order);

  const deleteQuestion = () => {
    oldQuestions.splice(questionIndex, 1);
    setNonConformity({ ...nonConformity, questions: oldQuestions });
  };

  const updateQuestionState: updateQuestionType = (
    eventOrSpecialCase: any,
    key: keyof QuestionModel,
    value?: any
  ) => {
    const valueAdapted = getNewValue(eventOrSpecialCase, value);
    const newQuestionCleaned = getQuestionCleaned(
      oldQuestions[questionIndex],
      mode,
      key,
      eventOrSpecialCase.target?.type === 'number'
        ? Number(valueAdapted)
        : valueAdapted
    );
    oldQuestions[questionIndex] = newQuestionCleaned;
    setNonConformity({ ...nonConformity, questions: oldQuestions });
  };

  return (
    <Question
      otherProps={otherProps}
      sectionIndex={sectionIndex}
      question={entity}
      templateErrors={errors}
      deleteQuestion={deleteQuestion}
      updateQuestion={updateQuestionState}
      isNc={true}
    />
  );
};
export { QuestionWrapper };
