import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { container } from 'tsyringe';
import { DeletionModal } from '../../../../_metronic/components/modals/DeletionModal';
import { nonConformityListDataGridSettings } from '../settings/DataGrid';
import { AlertErrors } from '../../../../_metronic/components/alerts/Errors';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { DataGridCustomized } from '../../../../theme/DataGrid';
import { NonConformity } from '../models/NonConformityModel';
import { NonConformityService } from '../../../../service/contracts/NonConformityService';
import { useRecoilValue } from 'recoil';
import { userState } from '../../sikuro_auth/state/atoms/user';

const NonConformitiesTable: FC = () => {
  const intl = useIntl();
  const nonConformityService = container.resolve<NonConformityService>(
    'NonConformityService'
  );
  const [nonConformities, setNonConformities] = useState<NonConformity[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [nonConformityToDelete, setNonConformityToDelete] =
    useState<NonConformity>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const userData = useRecoilValue(userState);

  useEffect(() => {
    async function getNonConformities() {
      let response: Partial<NonConformity>[] | false =
        await nonConformityService.getAll();
      setLoading(false);
      if (response) return setNonConformities(response as NonConformity[]);
      setError('Qualcosa è andato male');
    }
    setLoading(true);
    getNonConformities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonConformityToDelete, userData]);

  const handleRenderErrors = (error: string | undefined): ReactJSXElement => {
    if (!error) return <></>;
    return <AlertErrors errors={[error]} />;
  };

  const deleteNonConformities = async () => {
    if (!nonConformityToDelete) return;
    if (!nonConformityToDelete.id)
      return console.log('Qualcosa è andato storto, Id Assente!');
    await nonConformityService.delete(nonConformityToDelete);
    setOpenDeleteModal(false);
    setNonConformityToDelete(undefined);
  };

  const nonConformitiesToDeleteString = (): string => {
    if (!nonConformityToDelete) return '';
    return nonConformityToDelete.name;
  };

  const openDeletionModal = (nonConformity: NonConformity) => {
    setOpenDeleteModal(true);
    setNonConformityToDelete(nonConformity);
  };

  const handleDownloadUrl = (nonConformity: NonConformity) => {
    return nonConformity.pdfUrl;
  };

  const handleVerbalDownloadUrl = (nonConformity: NonConformity): string => {
    return nonConformity.linkedVerbalUrl;
  };

  const columns = nonConformityListDataGridSettings(
    intl,
    openDeletionModal,
    handleDownloadUrl,
    handleVerbalDownloadUrl
  );

  return (
    <div className='container mw-100 px-0'>
      <div className='h-full'>
        <div className='my-5'>{handleRenderErrors(error)}</div>
        <DataGridCustomized
          columns={columns}
          rows={nonConformities}
          props={{
            loading,
          }}
        />
      </div>
      <DeletionModal
        open={openDeleteModal}
        setModalState={setOpenDeleteModal}
        onConfirm={deleteNonConformities}
        entityString={nonConformitiesToDeleteString()}
      />
    </div>
  );
};

export { NonConformitiesTable };
