import React, {FC} from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import {NonConformityTable} from "./NonConformityTable";


const NonConformityTableWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.NON_CONFORMITY' })}</PageTitle>
            <NonConformityTable />
        </>
    )
}

export { NonConformityTableWrapper }
