import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '../../../../_metronic/components/Button';
import { container } from 'tsyringe';
import { Link } from 'react-router-dom';
import { dgColumnsDefinition } from '../settings/DataGrid';
import { CompaniesService } from '../../../../service/contracts/CompaniesService';
import { GeneralTable } from '../../../components/GeneralTable';
import { Company } from '../models/Company.model';

const CompaniesSummary: FC = () => {
  const intl = useIntl();
  const companyService =
    container.resolve<CompaniesService>('CompaniesService');
  const columns = dgColumnsDefinition(intl);

  return (
    <div className='container mw-100 px-0'>
      <div className={'mb-2'}>
        <Link to={'/company'}>
          <Button
            classes='mr-2'
            id='new-job-site'
            innerText={intl.formatMessage({
              id: 'COMPANY.BUTTONS.NEW:COMPANY',
            })}
          />
        </Link>
      </div>
      <GeneralTable
        entityName='company'
        entitiesService={companyService}
        columns={columns}
        toEntityString={(row: Company) => row.name}
      />
    </div>
  );
};

export { CompaniesSummary };
