/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Registration } from './components/Registration';
import { ForgotPassword } from './components/ForgotPassword';
import { LoginWrapper } from './components/Login/LoginWrapper';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { ForgotPasswordSubmit } from './components/ForgotPasswordSubmit';
import { IntermediateMessage } from './components/IntermediateMessage';
import logo from './../../public/logos/logo_nero.png';

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('site-background-color');
    return () => {
      document.body.classList.remove('site-background-color');
    };
  }, []);

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          '/media/illustrations/auth_background.png'
        )})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          {/* begin::Logo */}
          <div className='d-flex justify-content-center mb-12'>
            <a href='/'>
              <img alt='Logo' src={logo} className='h-100px' />
            </a>
          </div>
          {/* end::Logo */}
          <Switch>
            <Route path='/login' component={LoginWrapper} />
            <Route path='/registration' component={Registration} />
            <Route path='/forgot-password' component={ForgotPassword} />
            <Route
              path='/forgot-password-submit'
              component={ForgotPasswordSubmit}
            />
            <Route
              path='/intermediate-message'
              component={IntermediateMessage}
            />
            <Redirect from='/auth' exact={true} to='/login' />
            <Redirect to='/login' />
          </Switch>
          {/* begin::Footer */}
          <div className='d-flex flex-center flex-column-auto p-10'>
            <div className='d-flex align-items-center fw-bold fs-6'>
              <a href='#' className='text-muted text-hover-primary px-2'>
                About
              </a>

              <a href='#' className='text-muted text-hover-primary px-2'>
                Contact
              </a>

              <a href='#' className='text-muted text-hover-primary px-2'>
                Contact Us
              </a>
            </div>
          </div>
          {/* end::Footer */}
        </div>

        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  );
}
