import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoutes } from '../../routing/PrivateRoutes';
import { formMode } from '../../models/creationMode.model';
import { UserPage } from './components/user-form/UserPage';

const UsersRoutes: FC = () => {
  let match = useRouteMatch();

  return (
    <>
      <PrivateRoutes>
        <Switch>
          <Route exact path={`${match.path}/edit/:id`}>
            <UserPage mode={formMode.edit} />
          </Route>
          <Route exact path={match.path}>
            <UserPage mode={formMode.create} />
          </Route>
        </Switch>
      </PrivateRoutes>
    </>
  );
};

export { UsersRoutes };
