import { FC } from 'react';
import { QuestionModel } from '../../../models/question.model';
import { formMode } from '../../../../../models/creationMode.model';
import DragDropQuestions from '../../../../../../_metronic/components/DragDropQuestions';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { QuestionTableWrapper } from './partials/QuestionTableWrapper';
import { nonConformityState } from '../../../../../modules/non_conformity/state/nonConformity';
import {
  _addColumn,
  _saveEntitiesAfterDrag,
  _updateTableTypeQuestions,
  _deleteTableTypeQuestion,
} from './TableTypeWrapper';

type Props = {
  question: QuestionModel;
  editingMode: formMode;
  columns: QuestionModel[];
};
const NcVerbalTableType: FC<Props> = ({ question, editingMode, columns }) => {
  const intl = useIntl();
  const [template, setTemplate] = useRecoilState(nonConformityState);
  const templateQuestionIndex = template.questions.findIndex(
    (q) => q.order === question.order
  );
  const oldQuestions = [...template.questions];

  const addColumn = (): void => {
    save(_addColumn(columns));
  };

  const saveEntitiesAfterDrag = (questionsReordered: QuestionModel[]) => {
    save(_saveEntitiesAfterDrag(questionsReordered));
  };

  const updateTableTypeQuestions = (
    eventOrSpecialCase: any,
    key: keyof QuestionModel,
    value: any,
    order: number
  ) => {
    save(
      _updateTableTypeQuestions(
        eventOrSpecialCase,
        columns,
        editingMode,
        key,
        value,
        order
      )
    );
  };

  const deleteTableTypeQuestion = (order: number) => {
    save(_deleteTableTypeQuestion(columns, order));
  };

  const save = (columns: QuestionModel[]) => {
    oldQuestions.splice(templateQuestionIndex, 1, {
      ...question,
      columns,
    });
    setTemplate({ ...template, questions: oldQuestions });
  };

  return (
    <div className={'pl-10 py-2'}>
      {/* Drag And Drop Stuffs */}
      <DragDropQuestions
        initialEntities={columns}
        saveEntitiesAfterDrag={saveEntitiesAfterDrag}
        innerComponentProps={{
          editingMode,
          templateQuestionIndex,
          updateTableTypeQuestions,
          deleteTableTypeQuestion,
        }}
      >
        {/* Columns */}
        <QuestionTableWrapper
          /* @ts-ignore */
          entity={[]}
          mode={editingMode}
          sectionIndex={0}
        />
      </DragDropQuestions>
      {/* Add Question Button */}
      <div
        className={
          'flex flex-col sm:flex-row align-middle sm:justify-between mt-5'
        }
      >
        <div className={'cursor-pointer my-5 sm:my-0'} onClick={addColumn}>
          <div>
            {intl.formatMessage({ id: 'QUESTION.QUESTION_TABLE_ADD_COLUMN' })}
            <AddIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
export { NcVerbalTableType };
