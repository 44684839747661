import { GridLocaleText, GridSlotsComponentsProps } from '@mui/x-data-grid';
import { IntlShape } from 'react-intl';

export const DataGridLocale = (
  intl: IntlShape
): {
  localeText: Partial<GridLocaleText>;
  componentsProps: GridSlotsComponentsProps;
} => {
  return {
    localeText: {
      columnMenuFilter: intl.formatMessage({
        id: 'GENERAL.TABLE.FILTER_LABEL',
      }),
      columnMenuSortAsc: intl.formatMessage({ id: 'GENERAL.TABLE.SORT_ASC' }),
      columnMenuSortDesc: intl.formatMessage({ id: 'GENERAL.TABLE.SORT_DESC' }),
      columnMenuUnsort: intl.formatMessage({ id: 'GENERAL.TABLE.UNSORT' }),
      columnMenuHideColumn: intl.formatMessage({
        id: 'GENERAL.TABLE.HIDE_COLUMN',
      }),
      columnMenuShowColumns: intl.formatMessage({
        id: 'GENERAL.TABLE.SHOW_COLUMNS',
      }),
      filterOperatorContains: intl.formatMessage({
        id: 'GENERAL.TABLE.OPERATOR_CONTAINS',
      }),
      filterOperatorEquals: intl.formatMessage({
        id: 'GENERAL.TABLE.OPERATOR_EQUALS',
      }),
      filterOperatorStartsWith: intl.formatMessage({
        id: 'GENERAL.TABLE.OPERATOR_STARTS_WITH',
      }),
      filterOperatorEndsWith: intl.formatMessage({
        id: 'GENERAL.TABLE.OPERATOR_ENDS_WITH',
      }),
      filterOperatorIs: intl.formatMessage({ id: 'GENERAL.TABLE.OPERATOR_IS' }),
      filterOperatorNot: intl.formatMessage({
        id: 'GENERAL.TABLE.OPERATOR_IS_NOT',
      }),
      filterOperatorAfter: intl.formatMessage({
        id: 'GENERAL.TABLE.OPERATOR_IS_AFTER',
      }),
      filterOperatorOnOrAfter: intl.formatMessage({
        id: 'GENERAL.TABLE.OPERATOR_IS_ON_OR_AFTER',
      }),
      filterOperatorBefore: intl.formatMessage({
        id: 'GENERAL.TABLE.OPERATOR_IS_BEFORE',
      }),
      filterOperatorOnOrBefore: intl.formatMessage({
        id: 'GENERAL.TABLE.OPERATOR_IS_ON_OR_BEFORE',
      }),
      filterOperatorIsEmpty: intl.formatMessage({
        id: 'GENERAL.TABLE.OPERATOR_IS_EMPTY',
      }),
      filterOperatorIsNotEmpty: intl.formatMessage({
        id: 'GENERAL.TABLE.OPERATOR_IS_NOT_EMPTY',
      }),
      noRowsLabel: intl.formatMessage({ id: 'GENERAL.TABLE.NO_ROWS_LABEL' }),
      footerRowSelected: (count: number) =>
        count !== 1
          ? count.toLocaleString() +
            ' ' +
            intl.formatMessage({ id: 'GENERAL.TABLE.ROWS_SELECTED' })
          : count.toLocaleString() +
            ' ' +
            intl.formatMessage({ id: 'GENERAL.TABLE.ROW_SELECTED' }),
    },
    componentsProps: {
      pagination: {
        labelRowsPerPage: intl.formatMessage({
          id: 'GENERAL.TABLE.ROWS_PER_PAGE',
        }),
      },
    },
  };
};
