import React, {FC} from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {PrivateRoutes} from "../../routing/PrivateRoutes";
import {formMode} from "../../models/creationMode.model";
import {NonConformityWrapper} from "./components/NonConformityWrapper";

const NonConformitiesRoutes: FC = () => {
    let match = useRouteMatch();

    return (
        <>
            <PrivateRoutes>
                <Switch>
                    <Route exact path={`${match.path}/edit/:id`}>
                        <NonConformityWrapper mode={formMode.edit}/>
                    </Route>
                    <Route exact path={match.path}>
                        <NonConformityWrapper mode={formMode.create}/>
                    </Route>
                </Switch>
            </PrivateRoutes>
        </>
    )
}

export {NonConformitiesRoutes}
