import { FC, ReactElement, useContext, useState } from 'react';
import { Box } from '@mui/material';
import { RoleTabsContext } from '../context';
import { useIntl } from 'react-intl';
import { PermissionsTable } from './table/PermissionsTable';
import { permissionsDGColumnsDefinition } from './table/DataGrid';
import { AddPermissionSection } from './dialog/AddPermissionSection';
const PermissionsSection: FC = (): ReactElement => {
  const intl = useIntl();
  const { data, unbindPermission, loading } = useContext(RoleTabsContext);
  const [dialogState, setDialogState] = useState(false);

  return (
    <div className='h-full mt-5'>
      <Box sx={{ width: '100%' }}>
        {/* (1) DIALOG: Sezione 'aggiungi utente' */}
        <Box
          sx={{
            marginBottom: '7px',
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <Box>
            <AddPermissionSection
              isOpen={dialogState}
              setDialogState={setDialogState}
            />
          </Box>
        </Box>
        {/* (2) TABLE: Tabella permessi già associati */}
        <Box>
          <PermissionsTable
            props={{ loading }}
            permissions={data.permissions!}
            columnsSchema={permissionsDGColumnsDefinition(
              intl,
              unbindPermission
            )}
          />
        </Box>
      </Box>
    </div>
  );
};

export { PermissionsSection };
