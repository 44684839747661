import React, { ChangeEvent } from 'react';
import { FieldInputProps } from 'formik';
import clsx from 'clsx';

type Props = {
  label?: string;
  id: any;
  type?: string;
  autocomplete?: string;
  formikProps?: FieldInputProps<any>;
  isTouched?: boolean;
  className?: string;
  errorMsg?: string | undefined;
  thereIsError?: boolean;
  withLabel?: boolean;
  value?: string | number | any[];
  onChangeInput?: (event: ChangeEvent<HTMLInputElement>, key: any) => void;
  otherProps?: any;
};

const Input: React.FC<Props> = ({
  label,
  id,
  type = 'text',
  autocomplete = 'off',
  formikProps,
  errorMsg = '',
  isTouched = false,
  thereIsError = false,
  className = 'fv-row',
  children,
  withLabel = true,
  value,
  onChangeInput,
  otherProps,
}) => {
  const getElementClass = (
    isTouched: boolean,
    thereIsError: boolean
  ): string => {
    /* form-control form-control-lg form-control-solid => NON sono classi BS */
    return clsx(
      'input-background form-control-solid w-full',
      {
        'is-invalid': isTouched || thereIsError,
      },
      {
        'is-valid': isTouched && !thereIsError,
      }
    );
  };

  const getElementErrorDiv = (errorMsg: string): JSX.Element => {
    return errorMsg ? (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          <span role='alert'>{errorMsg}</span>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div className={className}>
      {withLabel && (
        <label htmlFor={id} className='text-lg'>
          {label}
        </label>
      )}
      <input
        type={type}
        id={id}
        data-testid={id}
        name={id}
        placeholder={label}
        className={clsx(
          'rounded-md',
          `border-${thereIsError ? 'red' : 'grey'}-300`,
          getElementClass(isTouched, thereIsError),
        )}
        // TODO Impostare il bordo dinamicamente
        autoComplete={autocomplete}
        value={value}
        {...formikProps}
        {...(onChangeInput && {
          onChange: (e: ChangeEvent<HTMLInputElement>) => onChangeInput(e, id),
        })}
        {...otherProps}
      />
      {getElementErrorDiv(errorMsg)}
      {children}
    </div>
  );
};

export { Input };
