import { Page, PermissionsCode } from '../_metronic/types/PageModel';
import { TemplatesListWrapper } from './pages/templates/components/TemplatesListWrapper';
import { TemplateWrapper } from './pages/templates/components/TemplateWrapper';
import { UsersPage } from './modules/users/components/UsersPage';
import { JobSitesRoutes } from './modules/job-sites/JobSitesRoutes';
import { VerbalsTableWrapper } from './modules/verbals/components/VerbalsTableWrapper';
import { NonConformityTableWrapper } from './modules/non_conformity/components/NonConformityTableWrapper';
import { NonConformitiesRoutes } from './modules/non_conformity/NonConformitiesRoutes';
import { CategoriesWrapper } from './modules/categories/CategoriesListWrapper';
import { CompaniesSummaryWrapper } from './modules/companies/components/CompaniesSummaryWrapper';
import { CompaniesRoutes } from './modules/companies/CompaniesRoutes';
import { NonConformitiesTableWrapper } from './modules/non_conformities/components/NonConformitiesTableWrapper';
import { JobSitesPage } from './modules/job-sites/components/job_site_page/JobSitesPage';
import { RolesRoutes } from './modules/roles/RolesRoutes';
import { RolesPage } from './modules/roles/components/entry_page/RolesPage';
import { UsersRoutes } from './modules/users/UserRoutes';

export const getPagesConfiguration = (
  permissions: PermissionsCode[]
): Page[] => {
  const pages = [];
  pages.push({
    to: '/users-list',
    component: UsersPage,
    icon: '/media/icons/yards/aside/utente.png',
    title: 'MENU.USERS',
    childs: [
      {
        to: '/user',
        component: UsersRoutes,
        title: 'MENU.EDIT_USER',
      },
    ],
  });
  pages.push({
    to: '/roles-list',
    component: RolesPage,
    icon: '/media/icons/yards/aside/categories.svg',
    title: 'MENU.ROLES',
    childs: [
      {
        to: '/role',
        component: RolesRoutes,
        title: 'MENU.CREATE_COMPANY',
      },
    ],
  });
  pages.push({
    to: '/categories-list',
    component: CategoriesWrapper,
    icon: '/media/icons/yards/aside/categories.svg',
    title: 'MENU.CATEGORIES',
  });
  pages.push({
    to: '/companies-summary',
    component: CompaniesSummaryWrapper,
    icon: '/media/icons/yards/aside/azienda.png',
    title: 'MENU.COMPANIES',
    childs: [
      {
        to: '/company',
        component: CompaniesRoutes,
        title: 'MENU.CREATE_COMPANY',
      },
    ],
  });
  pages.push({
    to: '/job-sites-list',
    component: JobSitesPage,
    icon: '/media/icons/yards/aside/commessa.png',
    title: 'MENU.JOB_SITES',
    childs: [
      {
        to: '/job-site',
        component: JobSitesRoutes,
        title: 'MENU.CREATE_JOB_SITE',
      },
    ],
  });
  if (permissions.includes('REPORTS')) {
    pages.push({
      to: '/template-list',
      component: TemplatesListWrapper,
      icon: '/media/icons/yards/aside/azienda.png',
      title: 'MENU.TEMPLATES',
      childs: [
        {
          to: '/template',
          component: TemplateWrapper,
          title: 'MENU.CREATE_TEMPLATE',
        },
      ],
    });
  }
  if (permissions.includes('NONCOMPLIANCES')) {
    pages.push({
      to: '/non-conformities-list',
      component: NonConformityTableWrapper,
      icon: '/media/icons/yards/aside/non_conformity.png',
      title: 'MENU.NON_CONFORMITY',
      childs: [
        {
          to: '/non-conformity',
          component: NonConformitiesRoutes,
          title: 'MENU.CREATE_NON_CONFORMITY',
        },
      ],
    });
  }
  if (permissions.includes('REPORTS')) {
    pages.push({
      to: '/verbals-list',
      component: VerbalsTableWrapper,
      icon: '/media/icons/yards/aside/scadenziario.png',
      title: 'MENU.VERBALS',
    });
  }
  if (permissions.includes('NONCOMPLIANCES')) {
    pages.push({
      to: '/nc-list',
      component: NonConformitiesTableWrapper,
      icon: '/media/icons/yards/aside/scadenziario.png',
      title: 'MENU.NON_CONFORMITIES',
    });
  }
  return pages;
};
