import {FC} from 'react'

type Props = {
    title?: string,
    classes?: string
}

const ButtonText: FC<Props> = ({title, classes, children}) => {


    return <span className={`secondary-text ${classes}`}>
        {title}
        {children}
    </span>
}

export {ButtonText}
