import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
  connectAuthEmulator,
} from 'firebase/auth';
import {
  AuthErrorType,
  AuthService,
  loginOutputType,
} from '../contracts/AuthService';
import { Auth, UserCredential } from '@firebase/auth/dist/auth-public';
import axios from 'axios';

const refreshTokenRequestUrl = 'https://securetoken.googleapis.com/v1/token';

export class GCPAuthService implements AuthService {
  private readonly auth: Auth;

  constructor() {
    this.auth = getAuth();
  }

  public login = async (
    email: string,
    password: string
  ): Promise<loginOutputType> => {
    try {
      const userCredential: UserCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      const token = await userCredential.user.getIdToken();
      const tokenResult = await userCredential.user.getIdTokenResult();
      const attributes = {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
        name: userCredential.user.displayName,
      };
      return {
        user: attributes,
        token,
        refreshToken: userCredential.user.refreshToken,
        expiresAt: new Date(tokenResult.expirationTime).getTime(),
      };
    } catch (error: any) {
      console.log('Errore in fase di login', error);
      return Promise.reject(this.decodeErrorCode(error));
    }
  };

  public getRefreshToken = async (
    apiKey: string,
    refreshToken: string
  ): Promise<loginOutputType | AuthErrorType> => {
    try {
      const unInterceptedAxiosInstance = axios.create();
      const tokenRefreshed = await unInterceptedAxiosInstance.post(
        `${refreshTokenRequestUrl}?key=${apiKey}`,
        { grant_type: 'refresh_token', refresh_token: refreshToken },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const secondsToAdd = parseInt(tokenRefreshed.data.expires_in, 10);
      let expiredDate = new Date();
      expiredDate.setSeconds(expiredDate.getSeconds() + secondsToAdd);
      return {
        token: tokenRefreshed.data.id_token,
        refreshToken: tokenRefreshed.data.refresh_token,
        expiresAt: expiredDate.getTime(),
      };
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  public logout = async (): Promise<boolean | AuthErrorType> => {
    try {
      await signOut(this.auth);
      return true;
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  private manageErrorCode(error: any): AuthErrorType {
    return {
      isError: true,
      code: error.code,
      intlIdCode: this.decodeErrorCode(error),
    };
  }

  public register(email: string, password: string): Promise<any> {
    return Promise.resolve(true);
  }

  public requestResetPassword = async (
    email: string
  ): Promise<boolean | AuthErrorType> => {
    try {
      /*
        TODO: Nell'emulatore non è possibile emulare "codeInApp"
        const backUrl = 'http://localhost/forgot-password-submit' {url: backUrl}
        {handleCodeInApp: false, url: 'http://localhost:3000/forgot-password-submit'}
        */
      await sendPasswordResetEmail(this.auth, email);
      return true;
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  public sendNewPassword = async (
    email: string,
    code: string,
    newPassword: string
  ): Promise<boolean | AuthErrorType> => {
    try {
      if (
        process.env.REACT_APP_FIREBASE_USE_EMULATORS!.toLowerCase() === 'true'
      ) {
        const authEmulatorHost = `${process.env.REACT_APP_FIREBASE_EMULATOR_HOST}
                              :${process.env.REACT_APP_FIREBASE_EMULATOR_FIRESTORE_PORT}`;
        connectAuthEmulator(this.auth, authEmulatorHost, {
          disableWarnings: true,
        });
      } else await confirmPasswordReset(this.auth, code, newPassword);
      return true;
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  public updateUserData = (data: any): Promise<string> => {
    return Promise.resolve('Foo!');
  };

  private decodeErrorCode = (e: any): string => {
    if (e?.code === 'auth/network-request-failed') {
      return 'AUTH.GCP.NETWORK_REQUEST_FAILED';
    }
    if (e?.code === 'auth/user-not-found') {
      return 'AUTH.GCP.USER_NOT_FOUND';
    }
    if (e?.code === 'auth/wrong-password') {
      return 'AUTH.GCP.WRONG_PASSWORD';
    }
    if (e?.code === 'auth/user-disabled') {
      return 'AUTH.GCP.USER_DISABLED';
    }
    return 'AUTH.GENERAL.SOMETHING_WENT_WRONG';
  };

  public getUserString = (user: any): string => {
    if (user.name) {
      return user.name;
    }
    // Può accadere che non si specifichi un nome da console firebase => lavoro sulla mail
    const emailSplitted = user.email.split('@');
    return `${emailSplitted[0]} ${emailSplitted[1]}`;
  };
}
