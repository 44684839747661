import { Box } from '@mui/material';
import { FC, ReactElement, useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { Button } from '../../../../../../_metronic/components/Button';
import { UsersDialogWithRowsSelection } from '../../../../../components/UsersDialog/UsersDialogWithRowsSelection';
import { userState } from '../../../../sikuro_auth/state/atoms/user';
import { UsersContext } from '../context';
import { CustomUsersTable } from './CustomUsersTable';
type Props = {
  isOpen: boolean;
  setDialogState: (state: boolean) => void;
};
const AddUserSection: FC<Props> = ({
  isOpen,
  setDialogState,
}): ReactElement => {
  const intl = useIntl();
  const { tenant } = useRecoilValue(userState);
  const bindButtonElement = useRef(null);
  const { setUsersSelected, usersToBind, usersToUnbind, bindTenantFn } =
    useContext(UsersContext);

  const handleRenderBindButton = (): ReactElement => {
    if (usersToBind?.length > 0) {
      return (
        <Box>
          {/* Pulsante aggiungi utente */}
          <button
            className={`items-center px-10 py-3 border rounded-md bg-blue-500 font-bold disabled:opacity-25`}
            onClick={async () => {
              // @ts-ignore
              bindButtonElement.current!.setAttribute('disabled', true);
              await bindTenantFn(usersToBind);
              // @ts-ignore
              bindButtonElement.current!.removeAttribute('disabled');
              setUsersSelected({ usersToUnbind, usersToBind: [] });
              setDialogState(false);
            }}
            ref={bindButtonElement}
          >
            <span className='text-white'>
              {intl.formatMessage({ id: 'USERS.ADD_USER_DIALOG.BIND_USERS' })}
            </span>
          </button>
        </Box>
      );
    }
    return <></>;
  };

  return (
    <>
      {/* Pulsante aggiungi utente */}
      <Button
        id='bind-users'
        innerText={intl.formatMessage({ id: 'USERS.BIND_NEW_USERS' })}
        otherProps={{
          onClick: () => setDialogState(true),
        }}
      />
      <UsersDialogWithRowsSelection
        setDialogState={() => setDialogState(false)}
        isOpen={isOpen}
        title={intl.formatMessage({ id: 'ADD_USERS_DIALOG.TITLE.BIND_USERS' })}
        ActionButton={handleRenderBindButton()}
        filters={[[`tenantIDs:-${tenant}`]]}
      >
        <CustomUsersTable />
      </UsersDialogWithRowsSelection>
    </>
  );
};

export { AddUserSection };
