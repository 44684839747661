import React, { ChangeEvent } from 'react';
import { FieldInputProps } from 'formik';
import clsx from 'clsx';

type Props = {
  label: string;
  id: string;
  autocomplete?: string;
  formikProps?: FieldInputProps<any>;
  errorMsg?: string | undefined;
  isTouched?: boolean;
  className?: string;
  thereIsError?: boolean;
  value?: string | number;
  onChangeInput?: (event: ChangeEvent<HTMLTextAreaElement>, key: any) => void;
};

const Textarea: React.FC<Props> = ({
  label,
  id,
  autocomplete = 'off',
  formikProps,
  errorMsg = '',
  isTouched = false,
  thereIsError = false,
  className = 'fv-row',
  children,
  value,
  onChangeInput,
}) => {
  const getElementClass = (
    isTouched: boolean,
    thereIsError: boolean
  ): string => {
    /* form-control form-control-lg form-control-solid => NON sono classi BS */
    return clsx(
      'form-control form-control-lg form-control-solid',
      {
        'is-invalid': isTouched && thereIsError,
      },
      {
        'is-valid': isTouched && !thereIsError,
      }
    );
  };

  const getElementErrorDiv = (errorMsg: string): JSX.Element => {
    return errorMsg ? (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          <span role='alert'>{errorMsg}</span>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div className={className}>
      <label htmlFor={id} className='text-lg'>
        {label}
      </label>
      <textarea
        id={id}
        data-testid={id}
        placeholder={label}
        name={id}
        className={clsx(
          'rounded-md',
          `border-${thereIsError ? 'red' : 'grey'}-300`,
          getElementClass(isTouched, thereIsError)
        )}
        autoComplete={autocomplete}
        rows={3}
        {...formikProps}
        value={value}
        {...(onChangeInput && {
          onChange: (e: ChangeEvent<HTMLTextAreaElement>) =>
            onChangeInput(e, id),
        })}
      />
      {getElementErrorDiv(errorMsg)}
      {children}
    </div>
  );
};

export { Textarea };
