import { FC, ReactElement } from 'react';
import { useRecoilValue } from 'recoil';
import { container } from 'tsyringe';
import { CategoriesService } from '../../../../service/contracts/CategoriesService';
import { Category } from '../../../modules/categories/models/Category.model';
import { userState } from '../../../modules/sikuro_auth/state/atoms/user';
import { CategoriesAutocomplete } from './CategoriesAutocomplete';
type Props = {
  categories: Partial<Category>[];
  onCategoriesChange: (event: any, values: any) => void;
  value: Partial<Category>[];
  errorMsg?: string;
};
const CategoriesAutocompleteWrapper: FC<Props> = ({
  categories,
  onCategoriesChange,
  value,
  errorMsg,
}): ReactElement => {
  const user = useRecoilValue(userState);
  const categoriesService =
    container.resolve<CategoriesService>('CategoriesService');

  const _onCategoriesChange = async (
    event: any,
    values: Partial<Category>[]
  ) => {
    const categoriesInserted = await handlePrepareCategories(values);
    onCategoriesChange(event, categoriesInserted);
  };

  /**
   * Prepare categories before to be saved
   * (1): If was selected an already existing category
   * (2): If was selected a new category, it need to be created and then added to the others
   */
  const handlePrepareCategories = async (
    categoriesInserted: (Partial<Category> | string)[]
  ): Promise<Partial<Category>[]> => {
    const valuesMapped: Partial<Category>[] = [];
    for (let index = 0; index < categoriesInserted.length; index++) {
      // (1)
      if (typeof categoriesInserted[index] !== 'string') {
        valuesMapped.push(categoriesInserted[index] as Partial<Category>);
        continue;
      }
      // (2)
      const id = await categoriesService.create({
        categoryData: { inputValue: categoriesInserted[index] as 'string' },
        userId: user.uid,
      });
      categoriesInserted.push({
        id,
        tag: categoriesInserted[index] as 'string',
      });
    }
    return valuesMapped;
  };

  return (
    <CategoriesAutocomplete
      categories={categories}
      onCategoriesChange={_onCategoriesChange}
      value={value}
      errorMsg={errorMsg}
    />
  );
};

export { CategoriesAutocompleteWrapper };
