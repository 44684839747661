import { IntlShape } from 'react-intl';
import { Category } from '../app/modules/categories/models/Category.model';
import { QuestionModel } from '../app/pages/templates/models/question.model';
import { QuestionType } from '../app/pages/templates/models/questionType.enum';

export type Error = {
  field: string;
  message: string;
};

export class ValidationService {
  intl: IntlShape;

  constructor(intl: IntlShape) {
    this.intl = intl;
  }

  protected evaluateCommonProperties<
    Y extends { name: string; categories: Partial<Category>[]; jobSite: string }
  >(template: Y): Error[] {
    const errors: Error[] = [];
    if (!template.name) {
      errors.push(this.getMissingTitleErrorObject());
    }
    if (!this.areThereCategories(template.categories)) {
      errors.push(this.getMissingCategoriesErrorObject());
    }
    if (!this.isThereJobSite(template.jobSite)) {
      errors.push(this.getMissingJobsiteErrorObject());
    }
    return errors;
  }

  /**
   * Checks over questions within a question box (verbaltemplate or nctemplate)
   * The controls are:
   * (1): At least a question
   * (2): All questions with titles
   * (3): Slider questions checks
   * (4): Table questions checks
   */
  protected evaluateQuestionsBoxes<T extends { questions: QuestionModel[] }>(
    questionsBoxes: T[]
  ): Error[] {
    const errors: Error[] = [];
    // (1)
    if (!this.areThereAtLeastAQuestionPerQuestionBox(questionsBoxes)) {
      this.handleNoAQuestionPerQuestionBox(questionsBoxes);
    }
    // (2)
    if (this.areThereQuestionsWithoutTitle(questionsBoxes)) {
      errors.push(...this.handleQuestionsWithoutTitle(questionsBoxes));
    }
    // (3)
    if (this.sliderQuestionsAreInvalid(questionsBoxes)) {
      errors.push(...this.handleSliderQuestionsInvalid(questionsBoxes));
    }
    // (4)
    if (this.tableQuestionsAreInvalid(questionsBoxes)) {
      errors.push(...this.handleInvalidTableQuestions(questionsBoxes));
    }
    return errors;
  }

  /**
   * Check over questions table type
   * The controls are:
   * (1): At least a column
   * (2): All columns with titles
   * (3): Percentage columns width is valid
   */
  protected handleInvalidTableQuestions<
    T extends { questions: QuestionModel[] }
  >(questionsBoxes: T[]): Error[] {
    const errors: Error[] = [];
    questionsBoxes.some((section, si) =>
      section.questions
        .filter((x) => x.type === QuestionType.table)
        .some((question: QuestionModel) => {
          // (1)
          if (question.columns.length === 0) {
            errors.push(this.getTableWithNoColumnsErrorObject(si));
            return true;
          }
          // (2)
          question.columns.forEach((tableQuestion) => {
            if (!tableQuestion.content) {
              errors.push(this.getTableNoValidErrorObject(si));
            }
          });
          // (3)
          return question.columns.some((tableQuestion) => {
            // if (
            //   !tableQuestion.width_perc &&
            //   typeof tableQuestion.width_perc !== 'number'
            // ) {
            //   errors.push(this.getTableNoValidSingleWidthErrorObject(si));
            // }
            // if (isNaN(tableQuestion.width_perc!)) {
            //   errors.push(this.getTableNoValidSingleWidthErrorObject(si));
            // }
            if (
              tableQuestion.width_perc! < 0 ||
              tableQuestion.width_perc! > 100
            ) {
              errors.push(this.getTableNoValidWidthErrorObject(si));
            }
            const totalWidth = question.columns.reduce((acc, curr) => {
              const currentValue = curr.width_perc ? curr.width_perc : 0;
              return acc + currentValue;
            }, 0);
            if (totalWidth > 100) {
              errors.push(this.getTableNoValidTotalWidthErrorObject(si));
            }
            return false;
          });
        })
    );
    return errors;
  }

  protected areThereQuestionsBoxWithoutAQuestion<
    T extends { questions: QuestionModel[] }
  >(questionsBoxes: T[]): boolean {
    return questionsBoxes.some(
      (qb) => qb.questions.length === 0 || !qb.questions
    );
  }

  /**
   * Checks over Slider questions type
   * The controls are:
   * (1) Check limits: min can't be less than max....
   * (2) Step must be grater than 0
   * @param questionsBoxes
   * @returns
   */
  protected handleSliderQuestionsInvalid<
    T extends { questions: QuestionModel[] }
  >(questionsBoxes: T[]): Error[] {
    const errors: Error[] = [];
    // (1)
    const noValidLimits = (question: QuestionModel): boolean => {
      return (
        question.max <= question.min ||
        question.step >= question.max - question.min
      );
    };
    // (2)
    const noValidStep = (question: QuestionModel): boolean => {
      return question.step <= 0;
    };
    const checkRules = [noValidLimits, noValidStep];
    questionsBoxes.some((section, si) =>
      section.questions
        .filter((x) => x.type === QuestionType.slider)
        .some((question: QuestionModel) => {
          if (checkRules.some((rule) => rule(question))) {
            errors.push(this.getSliderQuestionsInvalidErrorObject(si));
          }
          return true;
        })
    );
    return errors;
  }

  protected areThereCategories(categories: Partial<Category>[]): boolean {
    if (!categories) {
      return false;
    }
    return categories.length > 0;
  }

  protected isThereJobSite(jobsite: string): boolean {
    return !!jobsite;
  }

  protected handleQuestionsWithoutTitle<
    T extends { questions: QuestionModel[] }
  >(questionsBoxes: T[]): Error[] {
    const errors: Error[] = [];
    questionsBoxes.some((section, si) => {
      return section.questions.some((question) => {
        if (!question.content) {
          errors.push(this.getQuestionsWithoutTitleErrorObject(si));
          return true;
        }
        return false;
      });
    });
    return errors;
  }

  protected handleNoAQuestionPerQuestionBox<
    T extends { questions: QuestionModel[] }
  >(questionsBoxes: T[]): void {
    questionsBoxes.map((s, i) => {
      if (s.questions.length === 0) {
        this.getAtLeastAQuestionPerQuestionBoxErrorObject(i);
      }
      return true;
    });
  }

  protected areThereAtLeastAQuestionPerQuestionBox<
    T extends { questions: QuestionModel[] }[]
  >(questionsBox: T): boolean {
    return questionsBox.some((qb) => qb.questions.length === 0);
  }

  protected areThereQuestionsWithoutTitle<
    T extends { questions: QuestionModel[] }
  >(questionsBoxes: T[]): boolean {
    return questionsBoxes.some((qbox) => {
      return qbox.questions.some((question) => {
        if (!question.content) {
          return true;
        }
        return false;
      });
    });
  }

  protected sliderQuestionsAreInvalid<T extends { questions: QuestionModel[] }>(
    questionsBoxes: T[]
  ): boolean {
    return questionsBoxes.some((questionBox) =>
      questionBox.questions
        .filter((x) => x.type === QuestionType.slider)
        .some((question) => {
          const isInvalid =
            question.max <= question.min ||
            question.step >= question.max - question.min;
          return isInvalid;
        })
    );
  }

  protected tableQuestionsAreInvalid<T extends { questions: QuestionModel[] }>(
    questionsBoxes: T[]
  ): boolean {
    // Assenza di colonne
    const noColumns = questionsBoxes.some((questionBox) => {
      return questionBox.questions
        .filter((q: QuestionModel) => q.type === QuestionType.table)
        .some((q: QuestionModel) => {
          return q.columns.length === 0;
        });
    });
    if (noColumns) {
      return true;
    }
    // Alcune colonne senza titolo
    const columnsWithoutTitle = questionsBoxes.some((questionBox) => {
      return questionBox.questions
        .filter((q: QuestionModel) => q.type === QuestionType.table)
        .some((q: QuestionModel) => {
          return q.columns.some((tableQuestion) => !tableQuestion.content);
        });
    });
    if (columnsWithoutTitle) {
      return true;
    }
    return questionsBoxes.some((questionBox) => {
      return questionBox.questions
        .filter((q: QuestionModel) => q.type === QuestionType.table)
        .some((q: QuestionModel) => {
          const totalWidth = q.columns.reduce((acc, curr) => {
            const currentValue = curr.width_perc ? curr.width_perc : 0;
            return acc + currentValue;
          }, 0);
          return totalWidth > 100;
        });
    });
  }

  protected getMissingTitleErrorObject() {
    return {
      field: 'name',
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_TITLE_MANDATORY',
      }),
    };
  }

  protected getMissingCategoriesErrorObject() {
    return {
      field: 'categories',
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_CATEGORY_MANDATORY',
      }),
    };
  }

  protected getMissingJobsiteErrorObject(): Error {
    return {
      field: 'jobSite',
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_JOBSITE_MANDATORY',
      }),
    };
  }

  protected getSectionsWithoutTitleErrorObject(i: number): Error {
    return {
      field: `sections.${i}.title`,
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_SECTION_TITLE_MANDATORY',
      }),
    };
  }

  protected getAtLeastAQuestionPerQuestionBoxErrorObject(i: number): Error {
    return {
      field: `sections.${i}.questions`,
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_QUESTIONS_MANDATORY',
      }),
    };
  }

  protected getQuestionsWithoutTitleErrorObject(i: number): Error {
    return {
      field: `sections.${i}.questions.content`,
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_QUESTION_TITLE_MANDATORY',
      }),
    };
  }

  protected getSliderQuestionsInvalidErrorObject(i: number): Error {
    return {
      field: `sections.${i}.questions.slider`,
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_QUESTION_SLIDER_NOT_VALID',
      }),
    };
  }

  protected getTableWithNoColumnsErrorObject(i: number): Error {
    return {
      field: `sections.${i}.questions.columns`,
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_QUESTION_TAB_WITH_NO_COLUMNS',
      }),
    };
  }

  protected getTableNoValidErrorObject(i: number): Error {
    return {
      field: `sections.${i}.questions.columns.content`,
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_QUESTION_TAB_NOT_VALID',
      }),
    };
  }

  protected getTableNoValidWidthErrorObject(i: number): Error {
    return {
      field: `sections.${i}.questions.columns.total_width_perc`,
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_QUESTION_TAB_WIDTH_NOT_VALID',
      }),
    };
  }

  protected getTableNoValidTotalWidthErrorObject(i: number): Error {
    return {
      field: `sections.${i}.questions.columns`,
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_QUESTION_TAB_WIDTH_NOT_VALID',
      }),
    };
  }

  protected getTableNoValidSingleWidthErrorObject(i: number): Error {
    return {
      field: `sections.${i}.questions.width_perc`,
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_QUESTION_TAB_SINGLE_WIDTH_NOT_VALID',
      }),
    };
  }
}

export default ValidationService;
