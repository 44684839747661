import {FC} from 'react'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/it'
import deMessages from './messages/de.json'
import enMessages from './messages/en.json'
import esMessages from './messages/es.json'
import frMessages from './messages/fr.json'
import itMessages from './messages/it.json'
import { useRecoilValue } from 'recoil'
import { intlConfigState } from './state/intlConfig'
import { intlConfig } from './types/intlConfig'

const allMessages = {
  de: deMessages,
  en: enMessages,
  es: esMessages,
  fr: frMessages,
  it: itMessages
}

const I18nProvider: FC = ({children}) => {
  const intlConfig: intlConfig = useRecoilValue(intlConfigState)
  const messages = allMessages[intlConfig.selectedLang]

  return (
    <IntlProvider locale={intlConfig.selectedLang} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
