import React, {FC} from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import {TemplatesListPage} from "./TemplatesList";


const TemplatesListWrapper: FC = () => {
  const intl = useIntl()
  return (
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.TEMPLATES' })}</PageTitle>
        <TemplatesListPage />
      </>
  )
}

export { TemplatesListWrapper }
