import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  GridCellValue,
  GridColDef,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { IntlShape } from 'react-intl';
import { Tenant } from '../../../models/Tenant.model';

export const dgColumnsDefinition = (
  intl: IntlShape,
  currentTenant: string,
  onClickOnTenant: (tenant: Tenant) => void
): GridColDef[] => [
  {
    field: 'name',
    headerName: intl.formatMessage({
      id: 'CHANGE_TENANT_DIALOG.COLUMN_NAME.FIELD',
    }),
    sortable: true,
    flex: 7,
    renderCell: (params: GridValueGetterParams): GridCellValue => (
      <Box>
        <Typography>{params.row.name}</Typography>
      </Box>
    ),
  },
  {
    field: 'sub',
    headerName: intl.formatMessage({
      id: 'CHANGE_TENANT_DIALOG.COLUMN_ACTION.FIELD',
    }),
    renderCell: (params: GridValueGetterParams): GridCellValue => {
      if (params.row.id === currentTenant) {
        return <></>;
      }
      return (
        <Typography
          sx={{ fontWeight: 'bold', cursor: 'pointer', paddingLeft: 1 }}
          onClick={() => onClickOnTenant(params.row)}
        >
          {intl.formatMessage({
            id: 'CHANGE_TENANT_DIALOG.ROW_ACTION.FIELD',
          })}
        </Typography>
      );
    },
    sortable: false,
    filterable: false,
    hide: false,
    flex: 1,
    disableColumnMenu: true,
  },
];
