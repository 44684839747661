import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import { UsersSection } from './content/UsersSection';

const UsersPage: FC = () => {
  const intl = useIntl();
  return (
    <>
      {/* Title */}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.USERS' })}
      </PageTitle>
      {/* Content */}
      <div className='container mw-100 px-0'>
        {/* Users Section */}
        <UsersSection />
      </div>
    </>
  );
};

export { UsersPage };
