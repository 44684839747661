import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function TextField(theme: Theme) {
  return {
    MuiInput: {
      defaultProps: {
        disableUnderline: true
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: '#e4e6ef',
          backgroundColor: '#f5f8fa',
        },
      },
    },
    // MuiInput: {
    //   styleOverrides: {
    //     underline: {
    //       '&:before': {
    //         borderBottomColor: 'red', //theme.palette.grey[500_56],
    //       },
    //     },
    //   },
    // },
    // MuiFilledInput: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'red', //theme.palette.grey[500_12],
    //       '&:hover': {
    //         backgroundColor: 'red', //theme.palette.grey[500_16],
    //       },
    //       '&.Mui-focused': {
    //         backgroundColor: theme.palette.action.focus,
    //       },
    //       '&.Mui-disabled': {
    //         backgroundColor: theme.palette.action.disabledBackground,
    //       },
    //     },
    //     underline: {
    //       '&:before': {
    //         borderBottomColor: 'red', //theme.palette.grey[500_56],
    //       },
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiOutlinedInput-notchedOutline': {
    //         borderColor: 'red', // theme.palette.grey[500_32],
    //       },
    //       '&.Mui-disabled': {
    //         '& .MuiOutlinedInput-notchedOutline': {
    //           borderColor: theme.palette.action.disabledBackground,
    //         },
    //       },
    //     },
    //   },
    // },
  };
}
