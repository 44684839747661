import { Autocomplete, TextField } from '@mui/material';
import { FC } from 'react';
import { Category } from '../../../modules/categories/models/Category.model';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Chip from '@mui/material/Chip';

type Props = {
  categories: Partial<Category>[];
  onCategoriesChange: (event: any, values: any) => void;
  value: Partial<Category>[];
  errorMsg?: string;
};
export const CategoriesAutocomplete: FC<Props> = ({
  categories,
  onCategoriesChange,
  value,
  errorMsg,
}) => {
  if (!categories || categories.length === 0) {
    return <></>;
  }
  return (
    <Autocomplete
      freeSolo
      multiple
      id='categories'
      options={categories}
      onChange={onCategoriesChange}
      getOptionLabel={(option: Partial<Category>) => option.tag as string}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!errorMsg}
          helperText={errorMsg}
          InputProps={{ disableUnderline: true, ...params.InputProps }}
          variant='standard'
        />
      )}
      renderTags={(value: readonly Partial<Category>[], getTagProps) => {
        return value.map((option: Partial<Category>, index: number) => (
          <Chip
            variant='outlined'
            label={option.tag}
            {...getTagProps({ index })}
          />
        ));
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      popupIcon={<KeyboardArrowDownIcon />}
    />
  );
};
