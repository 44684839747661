import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { IntlShape } from 'react-intl';
import { DataGridActionsColumn } from '../../../../_metronic/components/datagrid/DataGridActionsColumn';
import { formMode } from '../../../models/creationMode.model';
import { showDateInItFormat } from '../../../../_metronic/utilities/utilities';

export const usersDGColumnsDefinition = (
  intl: IntlShape,
  onDelete: CallableFunction
): GridColDef[] => [
  {
    field: 'name',
    headerName: intl.formatMessage({ id: 'JOB_SITE.FIELD_NAME_LABEL' }),
    sortable: true,
    flex: 1,
  },
  {
    field: 'created_at',
    headerName: intl.formatMessage({ id: 'GENERAL.CREATED_AT' }),
    sortable: true,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => {
      return showDateInItFormat(params.value as string);
    },
  },
  {
    field: 'sub',
    headerName: intl.formatMessage({ id: 'GENERAL.TABLE.ACTIONS_LABEL' }),
    renderCell: (param: GridRenderCellParams<string>): ReactJSXElement => (
      <DataGridActionsColumn
        param={param}
        editPath={`/job-site/${formMode.edit}/${param.row.id}`}
        onDelete={onDelete}
      />
    ),
    sortable: false,
    filterable: false,
    hide: false,
    disableColumnMenu: true,
  },
];
