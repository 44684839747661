import {
  GridColDef,
  GridRenderCellParams,
  GridCellValue,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { IntlShape } from 'react-intl';
import { ReactElement } from 'react';
import { Permission } from '../../../../../models/Permission.model';
import Typography from '@mui/material/Typography';
import { DataGridActionsColumn } from '../../../../../../../../_metronic/components/datagrid/DataGridActionsColumn';

export const permissionsDGColumnsDefinition = (
  intl: IntlShape,
  unbindPermission: (permission: Permission) => void
): GridColDef[] => [
  {
    field: 'permissionValue',
    headerName: intl.formatMessage({ id: 'PERMISSION.MODEL.LABELS.NAME' }),
    sortable: true,
    flex: 1,
    renderCell: (params: GridValueGetterParams): GridCellValue => (
      <Box>
        <Typography className={'w-full block'}>
          {params.row.label}
        </Typography>
        <Typography className={'w-full block'}>
          <i>{params.row.description}</i>
        </Typography>
      </Box>
    ),
  },
  {
    field: 'sub',
    headerName: intl.formatMessage({ id: 'GENERAL.TABLE.ACTIONS_LABEL' }),
    renderCell: (param: GridRenderCellParams<string>): ReactElement => (
      <DataGridActionsColumn param={param} onDelete={unbindPermission} />
    ),
    sortable: false,
    filterable: false,
    hide: false,
    disableColumnMenu: true,
  },
];
