import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
// import { Link } from 'react-router-dom'
// import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { HeaderUserMenu } from '../../../partials';
import { useRecoilValue } from 'recoil';
import {
  getFullName,
  userState,
} from '../../../../app/modules/sikuro_auth/state/atoms/user';
import { getAcronym } from '../../../utilities/utilities';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  // toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';
// toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const user = useRecoilValue(userState);
  const [userInfo, setUserInfo] = useState<string>('');

  useEffect(() => {
    const getUserInfo = async () => {
      const displayName = await getFullName(user);
      setUserInfo(displayName);
    };
    if (!userInfo) {
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
      {/* NOTIFICATIONS */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
      >
        {/* begin::Drawer toggle */}
        {/*<div*/}
        {/*  className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}*/}
        {/*  id='kt_activities_toggle'*/}
        {/*>*/}
        {/*  <Link to='/dashboard'>*/}
        {/*    <img alt='Logo' src={toAbsoluteUrl('/media/icons/companies/header_menu/notification.png')} className='h-15px' />*/}
        {/*  </Link>*/}
        {/*</div>*/}
        {/* end::Drawer toggle */}
      </div>
      {/* DOCUMENTATION */}
      {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
      {/*  /!* begin::Menu- wrapper *!/*/}
      {/*  <div*/}
      {/*    className={clsx(*/}
      {/*      'btn btn-icon btn-active-light-primary position-relative',*/}
      {/*      toolbarButtonHeightClass*/}
      {/*    )}*/}
      {/*    data-kt-menu-trigger='click'*/}
      {/*    data-kt-menu-attach='parent'*/}
      {/*    data-kt-menu-placement='bottom-end'*/}
      {/*    data-kt-menu-flip='bottom'*/}
      {/*  >*/}
      {/*    <Link to='/dashboard'>*/}
      {/*      <img alt='Manuale' src={toAbsoluteUrl('/media/icons/companies/header_menu/manuale.png')} className='h-15px' />*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*  /!* <HeaderNotificationsMenu /> *!/*/}
      {/*  /!* end::Menu wrapper *!/*/}
      {/*</div>*/}
      {/* VIDEO TUTORIAL */}
      {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
      {/*  /!* begin::Menu wrapper *!/*/}
      {/*  <div*/}
      {/*    className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}*/}
      {/*    data-kt-menu-trigger='click'*/}
      {/*    data-kt-menu-attach='parent'*/}
      {/*    data-kt-menu-placement='bottom-end'*/}
      {/*    data-kt-menu-flip='bottom'*/}
      {/*  >*/}
      {/*    <Link to='/dashboard'>*/}
      {/*      <img alt='Logo' src={toAbsoluteUrl('/media/icons/companies/header_menu/tutorial.png')} className='h-15px' />*/}
      {/*    </Link>*/}
      {/*    /!* <KTSVG*/}
      {/*      path='/media/icons/duotone/Layout/Layout-4-blocks.svg'*/}
      {/*      className={toolbarButtonIconSizeClass}*/}
      {/*    /> *!/*/}
      {/*  </div>*/}
      {/*  /!* <QuickLinks /> *!/*/}
      {/*  /!* end::Menu wrapper *!/*/}
      {/*</div>*/}

      {/* CHAT */}
      {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
      {/*  /!* begin::Menu wrapper *!/*/}
      {/*  <div*/}
      {/*    className={clsx(*/}
      {/*      'btn btn-icon btn-active-light-primary position-relative',*/}
      {/*      toolbarButtonHeightClass*/}
      {/*    )}*/}
      {/*    // id='kt_drawer_chat_toggle'*/}
      {/*  >*/}
      {/*    <KTSVG*/}
      {/*      path='/media/icons/duotone/Communication/Group-chat.svg'*/}
      {/*      className={toolbarButtonIconSizeClass}*/}
      {/*    />*/}

      {/*    <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'/>*/}
      {/*  </div>*/}
      {/*  /!* end::Menu wrapper *!/*/}
      {/*</div>*/}

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx(
            'cursor-pointer symbol',
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <div className='rounded-full h-12 w-12 flex items-center justify-center secondary-bg font-bold'>
            <div>
              <span>{getAcronym(userInfo)}</span>
            </div>
          </div>
        </div>
        <HeaderUserMenu userDisplayName={userInfo} />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/*{config.header.left === 'menu' && (*/}
      {/*  <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>*/}
      {/*    <div*/}
      {/*      className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'*/}
      {/*      id='kt_header_menu_mobile_toggle'*/}
      {/*    >*/}
      {/*      <KTSVG path='/media/icons/duotone/Text/Toggle-Right.svg' className='svg-icon-1' />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export { Topbar };
