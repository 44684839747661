/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { userState } from '../modules/sikuro_auth/state/atoms/user';
import { useRecoilValue } from 'recoil';
import { AuthPage } from '../modules/sikuro_auth/AuthPage';
import { Logout } from '../modules/sikuro_auth/Logout';
import { container } from 'tsyringe';
import { settingsState } from '../modules/sikuro_auth/state/atoms';

const Routes: FC = () => {
  const settings = useRecoilValue(settingsState);
  const user = useRecoilValue(userState);
  container.register('Tenant', { useValue: settings?.defaultTenant ?? '' });

  return (
    <Switch>
      {!user ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {!user ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/login' />
      ) : (
        <MasterLayout />
      )}
    </Switch>
  );
};

export { Routes };
