import { connectHits } from 'react-instantsearch-dom';
import { AlgoliaUser } from '../../../models/UserAlgolia';
import { UserModel } from '../../../../../models/UserModel';
import { UnbindUsersTable } from './UnbindUsersTable';

export const CustomUsersTable = connectHits(
  ({ hits }: { hits: AlgoliaUser[] }) => {
    const hitsConverted: Partial<UserModel>[] = hits.map((r) => ({
      displayName: r.displayName,
      email: r.email,
      id: r.objectID,
    }));
    return <UnbindUsersTable users={hitsConverted}></UnbindUsersTable>;
  }
);
