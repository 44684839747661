import { Box } from '@mui/material';
import { FC, ReactElement, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { JobSiteTabsContext } from '../context';
import { AddUserSection } from './dialog/AddUserSection';
import { usersDGColumnsDefinition } from './dialog/content/utils/DataGrid';
import { UsersTable } from './table/UsersTable';

const UsersSection: FC = (): ReactElement => {
  const intl = useIntl();
  const { data, unbindUser, loading } = useContext(JobSiteTabsContext);
  const [dialogState, setDialogState] = useState(false);

  return (
    <div className='h-full mt-5'>
      {/* (1) DIALOG: Sezione 'aggiungi utente' + (2) TABLE: Tabella utenti già associati */}
      <Box sx={{ width: '100%' }}>
        {/* (1) */}
        <Box
          sx={{
            marginBottom: '7px',
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <Box>
            <AddUserSection
              isOpen={dialogState}
              setDialogState={setDialogState}
            />
          </Box>
        </Box>
        {/* (2) */}
        <Box>
          <UsersTable
            loading={loading}
            users={data.users}
            columnsSchema={usersDGColumnsDefinition(intl, unbindUser)}
          />
        </Box>
      </Box>
    </div>
  );
};

export { UsersSection };
