import { ChangeEvent, FC, useState } from 'react';
import { Input } from '../../../../_metronic/layout/components/form/Input';
import DropdownCrud from '../../../../_metronic/components/DropdownCrud';
import { useIntl } from 'react-intl';
import { SectionModel } from '../models/section.model';
import { useRecoilState } from 'recoil';
import {
  getLastEntitiesOrder,
  newQuestion,
  templateState,
} from '../state/template';
import { FormActions } from './FormActions';
import { formMode } from '../../../models/creationMode.model';
import { QuestionWrapper } from './question/QuestionWrapper';
import DragDropQuestions from '../../../../_metronic/components/DragDropQuestions';
import { QuestionModel } from '../models/question.model';

type Props = {
  mode: formMode;
  section: SectionModel;
  submitAction: () => void;
  templateErrors: { field: string; message: string }[];
};

const Section: FC<Props> = ({
  mode,
  section,
  submitAction,
  templateErrors,
}) => {
  const intl = useIntl();
  const [template, setTemplate] = useRecoilState(templateState);
  const sectionIndex = template.sections.findIndex(
    (s) => s.order === section.order
  );
  const oldSections = [...template.sections];
  const updateSection = (
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    key: keyof QuestionModel
  ): void => {
    oldSections.splice(sectionIndex, 1, {
      ...section,
      [key]: event.target.value,
    });
    setTemplate({ ...template, sections: oldSections }); //oldSections here is updated
  };

  const saveEntitiesAfterDrag = (questionsReordered: any[]) => {
    const oldSection = { ...oldSections[sectionIndex] };
    const newSection = {
      ...oldSection,
      questions: questionsReordered.map((q, index) => {
        return { ...q, order: index };
      }),
    };
    oldSections.splice(sectionIndex, 1, newSection);
    setTemplate({ ...template, sections: oldSections });
  };

  const addQuestion = () => {
    const newOrderQuestion = getLastEntitiesOrder(section.questions);
    const newQuestions = [...section.questions, newQuestion(newOrderQuestion)];
    oldSections.splice(sectionIndex, 1, {
      ...section,
      questions: newQuestions,
    });
    setTemplate({ ...template, sections: oldSections }); // oldSections here is updated
  };

  const deleteSection = () => {
    oldSections.splice(sectionIndex, 1);
    setTemplate({ ...template, sections: oldSections });
  };
  const [loading, setLoading] = useState<boolean>(false);
  const save = async () => {
    try {
      setLoading(true);
      await submitAction();
    } finally {
      setLoading(false);
    }
  };

  const getError = (field: string): string | undefined =>
    templateErrors.find((e) => e.field === `sections.${sectionIndex}.${field}`)
      ?.message;

  return (
    <div className={'h-auto'}>
      <div className='flex justify-between'>
        <div className='flex items-center text-2xl w-full'>
          <div className='w-full pr-2'>
            <Input
              onChangeInput={updateSection}
              label={intl.formatMessage({ id: 'TEMPLATE.FORM_TITLE_LABEL' })}
              id='title'
              value={section.title}
              errorMsg={section.title ? undefined : getError('title')}
              thereIsError={!section.title && !!getError('title')}
            />
          </div>
          <div className='items-center'>
            <DropdownCrud removeAction={deleteSection} />
          </div>
        </div>
      </div>
      <div>
        <DragDropQuestions
          initialEntities={section.questions}
          saveEntitiesAfterDrag={saveEntitiesAfterDrag}
          innerComponentProps={{ mode, sectionIndex }}
        >
          <QuestionWrapper
            /* @ts-ignore* */
            entity={[]}
            mode={mode}
            sectionIndex={sectionIndex}
            templateErrors={templateErrors}
          />
        </DragDropQuestions>
        <FormActions
          submitAction={save}
          withoutSave
          addSubEntityLabel={intl.formatMessage({
            id: 'TEMPLATE.ADD_QUESTION_BUTTON',
          })}
          addSubEntityAction={addQuestion}
          loading={loading}
        />
      </div>
    </div>
  );
};
export { Section };
