import { FC, ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { container } from 'tsyringe';
import { PermissionsService } from '../../../../../../service/contracts/PermissionsService';
import { RolesService } from '../../../../../../service/contracts/RolesService';
import { formMode } from '../../../../../models/creationMode.model';
import { Permission } from '../../../models/Permission.model';
import { emptyRole, Role } from '../../../models/Role.model';
import { RoleTabsContext } from './context';
import { RolesTabs } from './RolesTabs';

type Props = {
  mode: formMode;
};
const RolesTabsWrapper: FC<Props> = ({ mode }): ReactElement => {
  // @ts-ignore
  const { id } = useParams();
  const rolesService = container.resolve<RolesService>('RolesService');
  const permissionsService =
    container.resolve<PermissionsService>('PermissionsService');
  const [values, setValues] = useState<Role>();
  const [permissionsToBind, setPermissionsToBind] = useState([]);
  const [otherPermissions, setOtherPermissions] = useState<Permission[]>([]);
  const [trigger, setTrigger] = useState(false);
  const [loading, setLoading] = useState(false);

  const unbindPermission = async (permission: Permission): Promise<void> => {
    setLoading(true);
    await rolesService.unbindPermission(values!.id!, permission.id);
    setLoading(false);
    setTrigger(!trigger);
  };

  const bindPermissions = async (permissions: Permission[]): Promise<void> => {
    await rolesService.bindPermissions(values!.id!, permissions);
    setTrigger(!trigger);
  };

  useEffect(() => {
    const getOtherPermissions = async (data: Role) => {
      const _otherPermissions = await permissionsService.getManyNotInThisIds(
        data?.permissions ? data.permissions!.map((e) => e.id) : []
      );
      setOtherPermissions(_otherPermissions);
    };
    getOtherPermissions(values!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  /**
   * Fetch role data, permissions for permission tab
   */
  useEffect(() => {
    if (mode === formMode.create) {
      return setValues(emptyRole as Role);
    }
    const getRole = async (id: string) => {
      try {
        const roleFetched = await rolesService.get(id);
        setValues({
          ...roleFetched,
          permissions: roleFetched.permissions ?? [],
        });
      } catch (e) {
        console.error(e);
      }
    };
    getRole(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  if (!values) {
    return <></>;
  }

  return (
    <RoleTabsContext.Provider
      value={{
        data: values,
        mode,
        permissionsToBind,
        setPermissionsToBind,
        bindPermissions,
        unbindPermission,
        loading,
        otherPermissions,
      }}
    >
      <RolesTabs />
    </RoleTabsContext.Provider>
  );
};

export { RolesTabsWrapper };
