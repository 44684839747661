import { ErrorType } from '../contracts/JobSitesService';
import { NonConformityTemplateService } from '../contracts/NonConformityTemplateService';
import { NonConformityTemplate } from '../../app/modules/non_conformity/models/nonConformity.model';
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  query,
  WriteBatch,
  runTransaction,
  orderBy,
  where,
  CollectionReference,
} from 'firebase/firestore';
import { AuthErrorType } from '../contracts/AuthService';
import { QuestionModel } from '../../app/pages/templates/models/question.model';
import {
  adaptQuestionsInCreation,
  adaptQuestionsInReading,
  getCategoriesObj,
  getCategoriesRef,
  getJobSiteDataFromReference,
  jobSitesCollectionName,
  questionsCollectionName,
} from './GCPTemplateService';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import { Category } from '../../app/modules/categories/models/Category.model';
import { inject, singleton } from 'tsyringe';

@singleton()
export class GCPNonConformityTemplateService extends NonConformityTemplateService {
  private readonly firestore: Firestore;
  private readonly nonConformityCollectionName = 'nonConformityTemplates';
  private readonly questionsCollectionName = questionsCollectionName;
  private readonly storage: FirebaseStorage;
  private readonly questionsRef: CollectionReference;
  protected readonly tenant: string;

  constructor(@inject('Tenant') tenant: string) {
    super();
    this.tenant = tenant;
    this.firestore = getFirestore();
    this.storage = getStorage();
    this.questionsRef = collection(
      doc(this.firestore, 'tenants', tenant),
      this.nonConformityCollectionName
    );
  }

  public create = async (
    nonConformity: NonConformityTemplate,
    is_copying_images = false
  ): Promise<true | ErrorType> => {
    try {
      await runTransaction(this.firestore, async (transaction) => {
        const { id, questions, ...ncData } = nonConformity;
        const questionsData = await adaptQuestionsInCreation(
          questions,
          this.storage
        );
        const templateReference = doc(this.questionsRef);
        transaction.set(templateReference, {
          ...ncData,
          ...(is_copying_images && { is_copying_images: true }),
          jobSite: doc(
            collection(
              doc(this.firestore, 'tenants', this.tenant),
              jobSitesCollectionName
            ),
            nonConformity.jobSite
          ),
          questions: questionsData,
          categories: getCategoriesRef(
            this.firestore,
            nonConformity.categories,
            this.tenant
          ),
        });
      });
      return Promise.resolve(true);
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  public get = async (
    id: string
  ): Promise<NonConformityTemplate | ErrorType> => {
    try {
      const ncReference = doc(this.questionsRef, id);
      const ncSnapshot = await getDoc(ncReference);
      const nonConformityData = ncSnapshot.data();
      const questions = adaptQuestionsInReading(nonConformityData!.questions);
      const categories = await getCategoriesObj(nonConformityData!.categories);
      const jobSite = await getJobSiteDataFromReference(
        nonConformityData!.jobSite
      );
      // @ts-ignore
      return {
        ...ncSnapshot.data(),
        questions,
        id: ncSnapshot.id,
        categories,
        jobSite: jobSite.id!,
      };
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  public getAll = async (): Promise<NonConformityTemplate[] | ErrorType> => {
    try {
      let nonConformities: NonConformityTemplate[] = [];
      const nonConformitiesQuery = query(
        this.questionsRef,
        orderBy('name', 'asc')
      );
      const nonConformitiesQuerySnapshot = await getDocs(nonConformitiesQuery);
      for (const t of nonConformitiesQuerySnapshot.docs) {
        const allData = t.data();
        const categories = await getCategoriesObj(allData.categories);
        // @ts-ignore
        nonConformities.push({ ...allData, id: t.id, categories });
      }
      return nonConformities;
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  public getFiltered = async (filters: {
    categories: Partial<Category>[];
  }): Promise<NonConformityTemplate[] | false> => {
    try {
      let nonConformities: NonConformityTemplate[] = [];
      const categoriesToFind = getCategoriesRef(
        this.firestore,
        filters.categories,
        this.tenant
      );
      let templatesQuery;
      if (categoriesToFind.length > 0) {
        templatesQuery = query(
          this.questionsRef,
          where('categories', 'array-contains-any', categoriesToFind),
          orderBy('name', 'asc')
        );
      } else {
        templatesQuery = query(this.questionsRef, orderBy('name', 'asc'));
      }
      const templatesSnapshot = await getDocs(templatesQuery);
      for (const t of templatesSnapshot.docs) {
        const allData = t.data();
        const categories = await getCategoriesObj(allData.categories);
        // @ts-ignore
        nonConformities.push({ ...allData, id: t.id, categories });
      }
      return nonConformities;
    } catch (err: unknown) {
      console.error('Fetch delle NC fallito', err);
      return false;
    }
  };

  public delete = async (
    nonConformity: NonConformityTemplate
  ): Promise<true | ErrorType> => {
    try {
      const nonConformityReference = doc(this.questionsRef, nonConformity.id!);
      await deleteDoc(nonConformityReference);
      return Promise.resolve(true);
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  };

  public async update(
    nonConformity: NonConformityTemplate
  ): Promise<true | ErrorType> {
    try {
      const ncReference = doc(this.questionsRef, nonConformity.id!);
      await runTransaction(this.firestore, async (transaction) => {
        const { id, questions, ...ncData } = nonConformity;
        const questionsData = await adaptQuestionsInCreation(
          questions,
          this.storage
        );
        transaction.update(ncReference, {
          ...ncData,
          categories: getCategoriesRef(
            this.firestore,
            nonConformity.categories,
            this.tenant
          ),
          questions: questionsData,
          jobSite: doc(
            collection(
              doc(this.firestore, 'tenants', this.tenant),
              jobSitesCollectionName
            ),
            nonConformity.jobSite
          ),
        });
      });
      return Promise.resolve(true);
    } catch (error: any) {
      return this.manageErrorCode(error);
    }
  }

  /**
   * Prepara la non conformità all'aggiornamento
   **/
  private prepareNonConformityForUpdate(
    batch: WriteBatch,
    nonConformity: NonConformityTemplate
  ): DocumentReference<DocumentData> {
    const { questions, id, ...nonConformityDocument } = nonConformity;
    const nonConformityReference = doc(this.questionsRef, nonConformity.id!);
    // @ts-ignore
    batch.update(nonConformityReference, nonConformityDocument);
    return nonConformityReference;
  }

  /*
   * Prepara le domande provenienti dalla UI all'aggiornamento.
   * @param batch -  A {@link WriteBatch} that can be used to atomically execute multiple
   * @param nonConformityReference - riferimento al documenti di Non Conformità
   * @param questions - le domande provenienti da UI
   **/
  private prepareQuestionsForUpdate(
    batch: WriteBatch,
    nonConformityReference: DocumentReference<DocumentData>,
    questions: QuestionModel[]
  ): void {
    const questionsIds = questions.filter((q: QuestionModel) => q.id);
    questionsIds.forEach((question: QuestionModel) => {
      const collectionQuestionsReference = doc(
        nonConformityReference,
        this.questionsCollectionName,
        question.id!
      );
      // @ts-ignore
      batch.update(collectionQuestionsReference, question);
    });
  }

  /*
   * Prepara le domande che sono state cancellate dalla UI alla prossima cancellazione
   * @param batch - A {@link WriteBatch} that can be used to atomically execute multiple
   * @param nonConformityReference - riferimento al documenti di Non Conformità
   * @param questions - tutte le domande provenienti da UI
   **/
  private async prepareQuestionsForDelete(
    batch: WriteBatch,
    nonConformityReference: DocumentReference<DocumentData>,
    questions: QuestionModel[]
  ) {
    const newQuestionsIds = questions.map(
      (question: QuestionModel) => question.id
    );
    const oldQuestionsSnapshot = await getDocs(
      collection(nonConformityReference, this.questionsCollectionName)
    );
    // TODO Ho provato a tipizzare oldQuestion con QueryDocumentSnapshot<DocumentData> ma non funziona
    oldQuestionsSnapshot.forEach((oldQuestion) => {
      if (newQuestionsIds.includes(oldQuestion.id)) return; // la domanda non è stata cancellata
      const collectionQuestionsReference = doc(
        nonConformityReference,
        this.questionsCollectionName,
        oldQuestion.id!
      );
      batch.delete(collectionQuestionsReference);
    });
  }

  private manageErrorCode<Err extends { code: string }>(
    error: Err
  ): AuthErrorType {
    return {
      isError: true,
      code: error.code,
      intlIdCode: this.decodeErrorCode(error),
    };
  }

  private decodeErrorCode = (e: any): string => {
    if (e?.code === 'auth/network-request-failed')
      return 'AUTH.GCP.NETWORK_REQUEST_FAILED';
    if (e?.code === 'auth/user-not-found') return 'AUTH.GCP.USER_NOT_FOUND';
    if (e?.code === 'auth/wrong-password') return 'AUTH.GCP.WRONG_PASSWORD';
    return 'AUTH.GENERAL.SOMETHING_WENT_WRONG';
  };
}
