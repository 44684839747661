import React, {FC} from 'react';
import Modal from '@mui/material/Modal';
import {useIntl} from "react-intl";
import Dialog from "@mui/material/Dialog";
import {DialogTitleWrapper} from "./question/types/partials/DialogTitleWrapper";
import DialogContent from "@mui/material/DialogContent";
import {DialogActions} from "@mui/material";
import {Button} from "../../../../_metronic/components/Button";


type Props = {
    open: boolean,
    handleClose: () => void
}
const TemplateModalErrors: FC<Props> = ({open, handleClose, children}) => {
    const intl = useIntl();


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

        >
            <div>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}
                        fullWidth={true}>
                    <DialogTitleWrapper id="choices-list-dialog-title" onClose={handleClose}>
                        {intl.formatMessage({id: "TEMPLATE.ERRORS_MODALS_TITLE" })}
                    </DialogTitleWrapper>
                    <DialogContent dividers className={'overflow-hidden'} sx={{px: 1}}>
                        {children}
                    </DialogContent>
                    <DialogActions className={'py-5'}>
                        <Button id='submit-new-template' classes="w-full sm:w-auto"
                                otherProps={{onClick: handleClose, type: 'submit'}}
                                innerText={intl.formatMessage({id: "TEMPLATE.ERRORS_MODALS_OK" })}/>
                    </DialogActions>
                </Dialog>
            </div>
        </Modal>
    )
}


export {TemplateModalErrors }
