import { Box } from '@mui/material';
import { FC, useContext } from 'react';
import { JobSiteDetailsForm } from './JobSiteDetailsForm';
import { JobSiteTabsContext } from '../context';

const JobSiteDetailsFormWrapper: FC = () => {
  const { data, mode, onSubmit } = useContext(JobSiteTabsContext);

  return (
    <Box sx={{ paddingTop: '20px' }}>
      <JobSiteDetailsForm mode={mode} values={data} submitEvent={onSubmit} />
    </Box>
  );
};

export { JobSiteDetailsFormWrapper };
