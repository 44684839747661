import { ErrorType } from '../contracts/JobSitesService';
import { NonConformityService } from '../contracts/NonConformityService';
import {
  getFirestore,
  Firestore,
  collection,
  getDocs,
  query,
  orderBy,
  CollectionReference,
  doc,
  getDoc,
  deleteDoc,
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentData,
} from 'firebase/firestore';
import { inject, singleton } from 'tsyringe';
import { NonConformity } from '../../app/modules/non_conformities/models/NonConformityModel';
import { GCPUtilities } from '../GCPUtilities';

@singleton()
export class GCPNonConformityService extends NonConformityService {
  private readonly firestore: Firestore;
  private readonly nonConformitiesCollectionRef: CollectionReference;
  private readonly verbalRef: CollectionReference;
  private readonly tenantRef: DocumentReference;

  constructor(@inject('Tenant') tenant: string) {
    super();
    this.firestore = getFirestore();
    this.tenantRef = doc(
      this.firestore,
      GCPUtilities.tenantsCollectionName,
      tenant
    );
    this.nonConformitiesCollectionRef = collection(
      this.tenantRef,
      GCPUtilities.nonConformitiesCollectionName
    );
    this.verbalRef = collection(
      this.tenantRef,
      GCPUtilities.verbalsCollectionName
    );
  }

  public get = (id: string): Promise<NonConformity | ErrorType> => {
    return Promise.resolve({
      isError: true,
      code: 'not-implemented',
      intlIdCode: 'not-implemented',
    });
  };

  public getAll = async (): Promise<Partial<NonConformity>[] | false> => {
    try {
      let nonConformities: Partial<NonConformity>[] = [];
      const nonConformitiesQuery = query(
        this.nonConformitiesCollectionRef,
        orderBy('updated_at', 'desc')
      );
      const nonConformitiesSnapshot = await getDocs(nonConformitiesQuery);
      for (const nc of nonConformitiesSnapshot.docs) {
        nonConformities.push(await this.getNonConformityData(nc));
      }
      return nonConformities;
    } catch (err: unknown) {
      console.log('Fetch delle non conformità fallito', err);
      return false;
    }
  };

  private getNonConformityData = async (
    nonConformity: QueryDocumentSnapshot<DocumentData>
  ) => {
    const ncData = nonConformity.data();
    let verbalName = await this.getVerbalName(ncData.linkedVerbal);
    const pdfUrl = await this.getPdfUrl(ncData.id);
    const linkedVerbalUrl = await this.getLinkedVerbalUrl(ncData.linkedVerbal);
    return {
      ...ncData,
      id: nonConformity.id,
      linkedVerbal: verbalName,
      linkedVerbal_id: ncData.linkedVerbal,
      updatedAt: ncData.updated_at,
      pdfUrl: pdfUrl,
      linkedVerbalUrl,
    };
  };

  private getPdfUrl = async (nonConformityId: string): Promise<string> => {
    if (!nonConformityId) {
      return '';
    }
    return await GCPUtilities.getLastPdfFromCollection(
      this.firestore,
      doc(
        this.tenantRef,
        GCPUtilities.nonConformitiesCollectionName,
        nonConformityId
      )
    );
  };

  private getLinkedVerbalUrl = async (verbalId: string): Promise<string> => {
    if (!verbalId) {
      return '';
    }
    return await GCPUtilities.getLastPdfFromCollection(
      this.firestore,
      doc(this.tenantRef, GCPUtilities.verbalsCollectionName, verbalId)
    );
  };

  private getVerbalName = async (verbalRef: any): Promise<string> => {
    if (!verbalRef || typeof verbalRef !== 'string' || verbalRef === 'null') {
      return '';
    }

    const verbalReference = doc(this.verbalRef, verbalRef);
    const verbalDoc = (await getDoc(verbalReference)).data();

    if (!verbalDoc) {
      return '';
    }

    return `${verbalDoc?.name}`;
  };

  public delete = async (nc: NonConformity): Promise<boolean> => {
    try {
      const ncRef = doc(this.nonConformitiesCollectionRef, nc.id!);
      await deleteDoc(ncRef);
      return Promise.resolve(true);
    } catch (err: unknown) {
      console.error(`Errore in fase di cancellazione verbale`, err);
      return Promise.resolve(false);
    }
  };
}
