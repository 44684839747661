import {FC} from "react";
import {useIntl} from "react-intl";
import {DialogTitleWrapper} from "./DialogTitleWrapper";
import {Dialog, DialogContent} from "@mui/material";
import {TextAndColorItem} from "../../../../models/textAndColorItem.model";
import {ListForm} from "./ListForm";
import {formMode} from "../../../../../../models/creationMode.model";


const newChoice = (): TextAndColorItem => {
    return {
        text: '',
        color: '#01E801'
    }
}

type Props = {
    open: boolean,
    handleClose: () => void,
    choiceFamily: TextAndColorItem[],
    onSave: (items: TextAndColorItem[]) => void,
    formChoicesMode: formMode, // la modalità di inserimento (creazione o modifica) è indipendente da quella generale
    maxItems?: number
}
const ListFormDialogWrapper: FC<Props> = ({formChoicesMode, open, handleClose, choiceFamily, onSave, maxItems}) => {
    choiceFamily = choiceFamily.length > 0 ? choiceFamily : [newChoice()]
    const intl = useIntl()

    const handleRenderTitle = (): string => {
        if(formChoicesMode === formMode.create) return intl.formatMessage({id: "QUESTION.CHOICE_DIALOG_CREATE" })
        return intl.formatMessage({id: "QUESTION.CHOICE_DIALOG_EDIT" })
    }


    return (
        <div>
            <Dialog onClose={handleClose}
                             aria-labelledby="customized-dialog-title" open={open} fullWidth={true}>
                <DialogTitleWrapper id="choices-list-dialog-title" onClose={handleClose}>
                    {handleRenderTitle()}
                </DialogTitleWrapper>
                <DialogContent dividers className={'overflow-hidden'}>
                    <ListForm
                      choiceFamily={choiceFamily}
                      closePopover={handleClose}
                      onSave={onSave}
                      maxItems={maxItems}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}
export {ListFormDialogWrapper}
