import { TextAndColorItem } from '../models/textAndColorItem.model';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

const chipsLimit = 3;

export function handleRenderRemainingChips(
  choices: TextAndColorItem[]
): ReactJSXElement {
  const remaining = choices.length - chipsLimit;
  if (remaining > 0 && choices.length > chipsLimit) return <>+ {remaining}</>;
  return <></>;
}

export function handleRenderChips(
  choiceFamily: TextAndColorItem[]
): ReactJSXElement[] {
  const chips: ReactJSXElement[] = [];
  choiceFamily
    .slice(0, chipsLimit)
    .map((choice: TextAndColorItem, index: number) => {
      return chips.push(
        <span
          style={{
            backgroundColor: choice.color,
            color: isColorDark(choice.color) ? 'white' : 'black',
          }}
          key={index}
          className={`items-center px-5 py-3 rounded-full text-sm font-medium`}
        >
          {choice.text}
        </span>
      );
    });
  return chips;
}

export function isColorDark(color: string): boolean {
  let r, g, b;
  // Check the format of the color, HEX or RGB?
  // if (color.match(/^rgb/)) {
  //     // If HEX --> store the red, green, blue values in separate variables
  //     let colorRegMatch = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
  //     r = colorRegMatch[1];
  //     g = colorRegMatch[2];
  //     b = colorRegMatch[3];
  // }
  // If RGB --> Convert it to HEX: http://gist.github.com/983661
  const stringToReplace = color.length < 5 ? /./g : '';
  let colorRegMatch = +('0x' + color.slice(1).replace(stringToReplace, '$&$&'));
  r = colorRegMatch >> 16;
  g = (colorRegMatch >> 8) & 255;
  b = colorRegMatch & 255;

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) return false;
  return true;
}

export function isValidIndex(number: number | undefined): boolean {
  if (typeof number !== 'number') {
    return false;
  }
  if (isNaN(number)) return false;
  return number >= 0;
}
