import algoliasearch, { SearchClient } from 'algoliasearch/lite';

export const getAlgoliaParameters = (): {
  index: string;
  searchClient: SearchClient;
} => {
  return {
    index: process.env.REACT_APP_ALGOLIA_INDEX!,
    searchClient: algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID as string,
      process.env.REACT_APP_ALGOLIA_API_KEY as string
    ),
  };
};
