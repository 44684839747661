import {initializeApp} from "firebase/app";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import {FirebaseApp} from "@firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";


export const initializeFirebase = (): void => {
    const firebase = initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_CONFIG_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_CONFIG_APPID
    });
    // Avvio dell'emulatore se previsto
    if (process.env.REACT_APP_FIREBASE_USE_EMULATORS!.toLowerCase() === 'true') emulateFirebase(firebase)
}

const emulateFirebase = (firebase: FirebaseApp): void => {
    emulateAuthentication()
    emulateFirestore(firebase)
    emulateStorage()
}

const emulateAuthentication = () => {
    const auth = getAuth()
    // GCP richieste sempre localhost senza protocollo ma a sto giro è richiesto
    const authEmulatorHost = `http://${process.env.REACT_APP_FIREBASE_EMULATOR_HOST}:${process.env.REACT_APP_FIREBASE_EMULATOR_AUTHENTICATION_PORT}`
    connectAuthEmulator(auth, authEmulatorHost)
}

const emulateFirestore = (firebase: FirebaseApp): void => {
    const db = getFirestore(firebase);
    connectFirestoreEmulator(
        db,
        process.env.REACT_APP_FIREBASE_EMULATOR_HOST!,
        Number(process.env.REACT_APP_FIREBASE_EMULATOR_FIRESTORE_PORT),
    );
}

const emulateStorage = (): void => {
    const storage = getStorage();
    connectStorageEmulator(
        storage,
        process.env.REACT_APP_FIREBASE_EMULATOR_HOST!,
        Number(process.env.REACT_APP_FIREBASE_EMULATOR_STORAGE_PORT));
}
