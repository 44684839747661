import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { useParams } from 'react-router-dom';
import { formMode } from '../../../../models/creationMode.model';
import { UserForm } from './UserForm';
import { emptyUser } from '../../../../models/UserModel';
import { UsersService } from '../../../../../service/contracts/UsersService';
import { container } from 'tsyringe';
import { EntityForm } from '../../../../components/EntityForm';

type Props = {
  mode?: formMode;
};

const UserPage: FC<Props> = ({ mode }) => {
  const intl = useIntl();
  const modality = mode ?? formMode.create;
  // @ts-ignore
  const { id } = useParams();
  const usersService = container.resolve<UsersService>('UsersService');
  const config = {
    id,
    mode: modality,
    onSubmitUrl: '/user',
    initialValues: emptyUser,
  };

  return (
    <>
      <EntityForm
        config={config}
        entitiesService={usersService}
        /* @ts-ignore */
        children={<UserForm />}
      />
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({
          id: modality === formMode.create ? 'USER.FORM.NEW' : 'USER.FORM.EDIT',
        })}
      </PageTitle>
    </>
  );
};

export { UserPage };
