import { Button, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

type Props = {
  param: GridRenderCellParams<string>;
  editPath?: string;
  onEdit?: CallableFunction;
  onDelete?: CallableFunction;
  downloadPath?: CallableFunction;
};

export const DataGridActionsColumn: FC<Props> = ({
  param,
  downloadPath,
  editPath,
  onEdit,
  onDelete,
  children,
}) => {
  const handleRenderDeleteButton = (): ReactJSXElement => {
    if (!onDelete) return <></>;
    return (
      <IconButton onClick={() => onDelete(param.row)}>
        <DeleteIcon />
      </IconButton>
    );
  };

  const handleRenderEditButton = (): ReactJSXElement => {
    if (onEdit) {
      return (
        <IconButton onClick={() => onEdit(param.row)}>
          <EditIcon />
        </IconButton>
      );
    }
    if (!editPath) return <></>;
    return (
      <IconButton component={Link} to={editPath}>
        <EditIcon />
      </IconButton>
    );
  };

  const handleRenderDownloadButton = (): ReactJSXElement => {
    if (!downloadPath) {
      return <></>;
    }
    const url = downloadPath(param.row);
    if (!url) {
      return <></>;
    }
    return (
      <Button href={url} target='_blank' download>
        <IconButton>
          <DownloadIcon />
        </IconButton>
      </Button>
    );
  };

  return (
    <>
      {handleRenderEditButton()}
      {handleRenderDeleteButton()}
      {handleRenderDownloadButton()}
      {children}
    </>
  );
};
