import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { UserModel } from '../../../../../models/UserModel';
import { UsersService } from '../../../../../../service/contracts/UsersService';
import { container } from 'tsyringe';
import { UsersContext } from '../context';
import { UsersTable } from '../../../../../components/UsersTable';
import { useIntl } from 'react-intl';
import { usersDGColumnsDefinition } from '../../../../../components/datagrid/DataGrid';
import { useRecoilValue } from 'recoil';
import { userState } from '../../../../sikuro_auth/state/atoms/user';
import { useHistory } from 'react-router-dom';

type Props = { trigger: boolean };

const UsersTableWrapper: FC<Props> = ({ trigger }): ReactElement => {
  const intl = useIntl();
  const usersService = container.resolve<UsersService>('UsersService');
  const [users, setUsers] = useState<UserModel[]>([]);
  const { setUsersSelected, usersToBind } = useContext(UsersContext);
  const [loading, setLoading] = useState<boolean>(false);
  const userData = useRecoilValue(userState);
  let history = useHistory();

  const onRowSelection = (usersIds: string[]) => {
    setUsersSelected({
      usersToBind,
      usersToUnbind: usersIds,
    });
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const userFetched = await usersService.getManyInThisTenant();
        setUsers(userFetched);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        // const permissionsAreInvalid = GCPUtilities.arePermissionsInvalid(e);
        // if (permissionsAreInvalid) {
        history.push('/logout');
        // }
      }
    };
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, userData]);

  return (
    <div className='h-full'>
      <UsersTable
        loading={loading}
        users={users}
        onRowSelection={onRowSelection}
        columnsSchema={usersDGColumnsDefinition(intl)}
      />
    </div>
  );
};

export { UsersTableWrapper };
