import { GridColDef } from '@mui/x-data-grid';
import { IntlShape } from 'react-intl';

export const dgColumnsDefinition = (intl: IntlShape): GridColDef[] => [
  {
    field: 'name',
    headerName: intl.formatMessage({ id: 'COMPANY.MODEL.LABELS.NAME' }),
    sortable: true,
    flex: 1,
  },
  {
    field: 'vat_code',
    headerName: intl.formatMessage({ id: 'COMPANY.MODEL.LABELS.VAT_CODE' }),
    sortable: true,
    flex: 1,
  },
];
