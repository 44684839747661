import { ReactElement, useContext } from 'react';
import { useIntl } from 'react-intl';
import { DataGridCustomized } from '../../../../../../../../../../theme/DataGrid';
import { UserModel } from '../../../../../../../../../models/UserModel';
import { usersDGColumnsDefinition } from '../../../../../../../../../components/datagrid/DataGrid';
import { JobSiteTabsContext } from '../../../../context';

const BindUsersTable = ({
  users,
}: {
  users: Partial<UserModel>[];
}): ReactElement => {
  const intl = useIntl();
  const { setUsersToBind } = useContext(JobSiteTabsContext);

  const onRowSelection = (usersIds: string[]) => {
    setUsersToBind(usersIds);
  };

  return (
    <div className='h-full'>
      <DataGridCustomized
        columns={usersDGColumnsDefinition(intl)}
        rows={users}
        props={{
          checkboxSelection: true,
          density: 'compact',
          onSelectionModelChange: (newSelectionModel: string[]) =>
            onRowSelection(newSelectionModel),
        }}
      />
    </div>
  );
};

export { BindUsersTable };
