import {TextAndColorItem} from "../models/textAndColorItem.model";

export const defaultMultichoices: TextAndColorItem[][] = [
    [
        {text: 'Alto', color: '#01E801'},
        {text: 'Medio', color: '#E8E602'},
        {text: 'Basso', color: '#E80000'},
        {text: 'N/D', color: '#AEAEAE'},
    ],
    [
        {text: 'Sicuro', color: '#01E801'},
        {text: 'Rischioso', color: '#E80000'},
        {text: 'N/D', color: '#AEAEAE'},
    ],
    [
        {text: 'Passato', color: '#01E801'},
        {text: 'Fallito', color: '#E80000'},
        {text: 'N/D', color: '#AEAEAE'},
    ],
    [
        {text: 'Si', color: '#01E801'},
        {text: 'No', color: '#E80000'},
        {text: 'N/D', color: '#AEAEAE'}
    ]
]

export const defaultRadioChoices: TextAndColorItem[][] = [
    [
        {text: 'Si', color: '#01E801'},
        {text: 'No', color: '#E80000'}
    ],
    [
        {text: 'Vero', color: '#01E801'},
        {text: 'Falso', color: '#E80000'}
    ],
];