import { FC } from 'react';
import { QuestionModel } from '../../../models/question.model';
import { getLastEntitiesOrder, newQuestion } from '../../../state/template';
import { formMode } from '../../../../../models/creationMode.model';
import { getQuestionCleaned } from '../QuestionWrapper';
import { VerbalTableType } from './VerbalTableType';
import { NcVerbalTableType } from './NcVerbalTableType';

/**
 * Add a new Column to table type columns
 */
export const _addColumn = (columns: QuestionModel[]): QuestionModel[] => {
  const columnsUpdated = [
    ...columns,
    newQuestion(getLastEntitiesOrder(columns)),
  ];
  return columnsUpdated;
};

/**
 * Save table type questions after a drag and drop action
 * @param questionsReordered: the questions reordered
 */
export const _saveEntitiesAfterDrag = (
  questionsReordered: QuestionModel[]
): QuestionModel[] => {
  const columns = questionsReordered.map((q, index) => {
    return { ...q, order: index };
  });
  return columns;
};

export const _updateTableTypeQuestions = (
  eventOrSpecialCase: any,
  columns: QuestionModel[],
  editingMode: formMode,
  key: keyof QuestionModel,
  value: any,
  order: number
): QuestionModel[] => {
  const questionTableIndex = columns.findIndex((r) => r.order === order);
  const newQuestionCleaned = getQuestionCleaned(
    columns[questionTableIndex],
    editingMode,
    key,
    eventOrSpecialCase.target?.type === 'number' ? Number(value) : value
  );
  const _questions = [...columns];
  _questions[questionTableIndex] = newQuestionCleaned;
  return _questions;
};

export const _deleteTableTypeQuestion = (
  columns: QuestionModel[],
  order: number
): QuestionModel[] => {
  const questionTableIndex = columns.findIndex((r) => r.order === order);
  const _oldQuestions = [...columns];
  _oldQuestions.splice(questionTableIndex, 1);
  return _oldQuestions;
};

type Props = {
  question: QuestionModel;
  sectionIndex: number;
  isNc: boolean;
};
const TableTypeWrapper: FC<Props> = ({ question, sectionIndex, isNc }) => {
  const editingMode =
    question.columns.length > 0 ? formMode.edit : formMode.create;
  const columns =
    question.columns.length > 0 ? question.columns : [newQuestion(0)];

  if (isNc) {
    return (
      <NcVerbalTableType
        question={question}
        editingMode={editingMode}
        columns={columns}
      ></NcVerbalTableType>
    );
  }
  return (
    <VerbalTableType
      question={question}
      sectionIndex={sectionIndex}
      editingMode={editingMode}
      columns={columns}
    ></VerbalTableType>
  );
};
export { TableTypeWrapper };
