import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoutes } from '../../routing/PrivateRoutes';
import { formMode } from '../../models/creationMode.model';
import { CompanyWrapper } from './components/CompanyWrapper';

const CompaniesRoutes: FC = () => {
  let match = useRouteMatch();

  return (
    <>
      <PrivateRoutes>
        <Switch>
          <Route exact path={`${match.path}/edit/:id`}>
            <CompanyWrapper mode={formMode.edit} />
          </Route>
          <Route exact path={match.path}>
            <CompanyWrapper mode={formMode.create} />
          </Route>
        </Switch>
      </PrivateRoutes>
    </>
  );
};

export { CompaniesRoutes };
