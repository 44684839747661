import React, {Suspense} from 'react'
import {Redirect, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'

export function PrivateRoutes(props: any) {

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {props.children}
        <Redirect from='/auth' to='/users-list' />
        <Redirect from='/' to='/users-list' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
