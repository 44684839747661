import { FC, ReactElement } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  setDialogState: (state: boolean) => void;
};
const CloseButton: FC<Props> = ({ setDialogState }): ReactElement => {
  return (
    <IconButton
      aria-label='close'
      onClick={() => setDialogState(false)}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export { CloseButton };
