import { VerbalTemplate } from '../app/pages/templates/models/template.model';
import { SectionModel } from '../app/pages/templates/models/section.model';
import ValidationService from './ValidationService';
import { Error } from './ValidationService';

export class VerbalValidationService extends ValidationService {
  public evaluateTemplate(template: VerbalTemplate): Error[] {
    let errors: Error[] = [];
    if (!this.areThereSections(template.sections)) {
      errors.push(this.getAtLeastAQuestionErrorObject());
    }
    if (this.areThereSectionsWithoutTitle(template)) {
      template.sections.map((s, i) => {
        if (!s.title) {
          errors.push(this.getSectionsWithoutTitleErrorObject(i));
        }
        return true;
      });
    }
    if (this.areThereQuestionsBoxWithoutAQuestion(template.sections)) {
      errors.push(this.getAtLeastAQuestionErrorObject());
    }
    errors = [
      ...errors,
      ...this.evaluateCommonProperties(template),
      ...this.evaluateQuestionsBoxes(template.sections),
    ];
    return errors;
  }
  private areThereSections(sections: SectionModel[]): boolean {
    if (!sections) {
      return false;
    }
    return sections.length > 0;
  }

  private areThereSectionsWithoutTitle(template: VerbalTemplate): boolean {
    return template.sections.some((section: SectionModel) => !section.title);
  }

  protected getAtLeastAQuestionErrorObject() {
    return {
      field: 'sections',
      message: this.intl.formatMessage({
        id: 'TEMPLATE.ERRORS_AL_LEAST_ONE_SECTION',
      }),
    };
  }
}

export default VerbalValidationService;
