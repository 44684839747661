/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { container } from 'tsyringe';
import { AuthService } from '../../../../service/contracts/AuthService';
import { ButtonWrapper } from '../../../../_metronic/components/ButtonWrapper';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { ButtonText } from '../../../../_metronic/components/ButtonText';
import * as formUtils from '../utils/formUtils';
import { Input } from '../../../../_metronic/layout/components/form/Input';

export function ForgotPasswordSubmit() {
  const history = useHistory();
  const intl = useIntl();
  const authService = container.resolve<AuthService>('AuthService');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const initialValues = {
    code: '',
    email: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const registrationSchema = Yup.object().shape({
    code: Yup.string().required(
      intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' })
    ),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'AUTH.GENERAL.FORMAT_NOT_VALID' }))
      .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.FIELD_MIN_3_CHARACTERS' }))
      .max(
        50,
        intl.formatMessage({ id: 'AUTH.GENERAL.FIELD_MAX_50_CHARACTERS' })
      )
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' })),
    newPassword: Yup.string().required(
      intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' })
    ),
    confirmNewPassword: Yup.string()
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' }))
      .when('newPassword', {
        is: (val: string) => !!(val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref('newPassword')],
          intl.formatMessage({ id: 'AUTH.GENERAL.PASSWORD_MUST_BE_EQUALS' })
        ),
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      authService
        .sendNewPassword(values.email, values.code, values.newPassword)
        .then(() => manageSuccess(setStatus, setSubmitting))
        .catch((error: any) => manageError(error, setStatus, setSubmitting));
    },
  });

  const manageError = (
    error: any,
    setStatus: (status?: any) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setLoading(false);
    setSubmitting(false);
    setErrors(true); // per disabilitare pulsanti "Registrati"
    setStatus(intl.formatMessage({ id: error.intlIdCode }));
  };

  const manageSuccess = (
    setStatus: (status?: any) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setLoading(false);
    setSubmitting(true);
    setErrors(false);
    setStatus(
      intl.formatMessage({ id: 'AUTH.FORGOT_PASSWORD_SUBMIT.FORM_SUCCESS_MSG' })
    );
    setTimeout(() => {
      history.push('/');
    }, 4000);
  };

  const formStatus = (): JSX.Element => {
    return (
      formik.status && (
        <div className='mb-lg-15'>
          <div
            className={clsx(
              'font-weight-bold',
              { 'alert alert-danger': errors },
              { 'alert alert-success': !errors }
            )}
          >
            {formik.status}
          </div>
        </div>
      )
    );
  };

  const handleRenderSubmitContent = (): ReactJSXElement => {
    if (!loading)
      return (
        <ButtonText
          title={intl.formatMessage({
            id: 'AUTH.FORGOT_PASSWORD_SUBMIT.FORM_SUBMIT',
          })}
        />
      );
    return (
      <ButtonText classes='indicator-progress block'>
        <span className='spinner-border spinner-border-sm align-middle ms-2' />
      </ButtonText>
    );
  };

  return (
    <form
      className='form w-100'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Title */}
      <div className='mb-10 text-center'>
        <h1 className='text-dark mb-3 text-2xl font-bold'>
          {intl.formatMessage({ id: 'AUTH.FORGOT_PASSWORD_SUBMIT.FORM_TITLE' })}
        </h1>
      </div>

      {formStatus()}

      {/* Form Body */}
      <div className='fv-row mb-10'>
        <Input
          label={intl.formatMessage({ id: 'AUTH.FORGOTPWD.FORM_EMAIL' })}
          id='email'
          type='email'
          formikProps={{ ...formik.getFieldProps('email') }}
          errorMsg={formUtils.getErrorMsg('email', formik)}
          isTouched={formUtils.isTouched('email', formik)}
          thereIsError={formUtils.thereIsError('email', formik)}
        />
      </div>

      {/*  Code */}
      <div className='fv-row mb-10'>
        <Input
          label={intl.formatMessage({ id: 'AUTH.FORGOT_PASSWORD_SUBMIT.CODE' })}
          id='code'
          formikProps={{ ...formik.getFieldProps('code') }}
          errorMsg={formUtils.getErrorMsg('code', formik)}
          isTouched={formUtils.isTouched('code', formik)}
          thereIsError={formUtils.thereIsError('code', formik)}
        />
      </div>

      {/* New Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <Input
          label={intl.formatMessage({
            id: 'AUTH.FORGOT_PASSWORD_SUBMIT.FORM_NEW_PASSWORD',
          })}
          id='newPassword'
          type={'password'}
          formikProps={{ ...formik.getFieldProps('newPassword') }}
          errorMsg={formUtils.getErrorMsg('newPassword', formik)}
          isTouched={formUtils.isTouched('newPassword', formik)}
          thereIsError={formUtils.thereIsError('newPassword', formik)}
        />
      </div>
      {/* Confirm New Password */}
      <div className='fv-row mb-5'>
        <Input
          label={intl.formatMessage({
            id: 'AUTH.FORGOT_PASSWORD_SUBMIT.FORM_PASSWORD_PLACEHOLDER',
          })}
          id='confirmNewPassword'
          type={'password'}
          formikProps={{ ...formik.getFieldProps('confirmNewPassword') }}
          errorMsg={formUtils.getErrorMsg('confirmNewPassword', formik)}
          isTouched={formUtils.isTouched('confirmNewPassword', formik)}
          thereIsError={formUtils.thereIsError('confirmNewPassword', formik)}
        />
      </div>

      {/* Submit */}
      <div className='text-center'>
        <ButtonWrapper
          id='kt_sign_up_submit'
          classes='w-full'
          otherProps={{
            disabled: formik.isSubmitting || !formik.isValid,
            type: 'submit',
          }}
        >
          {handleRenderSubmitContent()}
        </ButtonWrapper>
      </div>
    </form>
  );
}
