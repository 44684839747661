import React from 'react';
import {
  userState,
  sessionState,
  settingsState,
} from '../../state/atoms/index';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { container } from 'tsyringe';
import {
  AuthService,
  loginOutputType,
} from '../../../../../service/contracts/AuthService';
import { updateTokenData } from '../../../../../_metronic/hooks/useUpdateTokenData';
import { UsersService } from '../../../../../service/contracts/UsersService';
import { Login } from './Login';
import { updateSettingsState } from '../../../../../utils/updateSettingsState';
import { updateUserState } from '../../../../../utils/updateUserState';
import { UserModel } from '../../../../models/UserModel';
import { RolesService } from '../../../../../service/contracts/RolesService';
import { ActorsService } from '../../../../../service/contracts/ActorsService';

const LoginWrapper: React.FC = () => {
  const authService = container.resolve<AuthService>('AuthService');
  const usersService = container.resolve<UsersService>('UsersService');
  const sessionToken = useSetRecoilState(sessionState);
  const setUserState = useSetRecoilState(userState);
  const [settings, setSettings] = useRecoilState(settingsState);

  /**
   * Method called when the login is successful
   */
  const onSuccess = async (response: loginOutputType) => {
    updateTokenData(response, sessionToken);
    const tenant = await getTenant(response.user.uid);
    container.register('Tenant', { useValue: tenant });
    const userData = { ...response.user, tenant };
    const permissions = await getPermissions(response.user.uid);
    if (!permissions.includes('BACKOFFICE_ACCESS')) {
      throw new Error('AUTH.GENERAL.PERMISSION_BACKOFFICE_DENIED');
    }
    updateUserState({ ...userData, permissions }, setUserState);
  };

  const getPermissions = async (userId: string): Promise<string[]> => {
    try {
      const rolesService = container.resolve<RolesService>('RolesService');
      const actorsService = container.resolve<ActorsService>('ActorsService');
      const actor = await actorsService.get(userId);
      const role = await rolesService.get(actor.roleId);
      return role.permissions.map((p) => p.permissionValue);
    } catch (e) {
      throw new Error('AUTH.GENERAL.ROLE_OR_PERMISSIONS_NOT_FOUND');
    }
  };

  /**
   * Get the tenant of the user
   * (1) Get all current users'tenants
   * (2) If there is a default tenant and it is in the user's tenants, return it
   * (3) Otherwise return the first
   */
  const getTenant = async (userId: string): Promise<string> => {
    // (1)
    let response = (await usersService.get(userId)) as UserModel;
    const tenants = response.tenants;
    if (!tenants || tenants.length === 0) {
      throw new Error('TENANT_NOT_FOUND');
    }
    // (2)
    const userTenants = tenants.map((tenant) => tenant.id);
    const defaultTenant: string = settings?.defaultTenant;
    const currentTenantIsInUserTenants = userTenants.includes(defaultTenant);
    if (currentTenantIsInUserTenants) {
      return defaultTenant;
    }
    // (3)
    updateSettingsState({ defaultTenant: tenants[0].id! }, setSettings);
    return tenants[0].id!;
  };

  return <Login loginAction={authService.login} onSuccess={onSuccess} />;
};

export { LoginWrapper };
