import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Link} from "react-router-dom";

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config

  return (
    <div className='menu-item menu-accordion show' data-kt-menu-trigger='click'>
      <Link to={to}>
        <span className='menu-link'>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && aside.menuIcon === 'png' && (
            <img
              className='pe-3'
              style={{width: '35px'}}
              alt='metronic'
              src={toAbsoluteUrl(icon)}
            />
          )}
          {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
          <span className='menu-title tertiary-text'>{title}</span>
        </span>
      </Link>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
        {children}
      </div>
    </div>
  )
}

export {AsideMenuItemWithSub}
