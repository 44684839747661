import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { IntlShape } from 'react-intl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGridActionsColumn } from '../../../../_metronic/components/datagrid/DataGridActionsColumn';
import { formMode } from '../../../models/creationMode.model';
import {
  handleRenderCategoryChip,
  showDateInItFormat,
} from '../../../../_metronic/utilities/utilities';
import Copy from '@mui/icons-material/FileCopy';
import { IconButton } from '@mui/material';
import { Category } from '../../../modules/categories/models/Category.model';

export const handleRenderCategoriesChips = (
  categories: Category[],
  characterLimit = 10,
  chipNumberLimit = 2
): ReactJSXElement => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      {categories
        .slice(0, chipNumberLimit)
        .map((category: Partial<Category>, index: number) => (
          <Box sx={{ pl: 1 }} key={index}>
            {handleRenderCategoryChip(category.tag!, characterLimit)}
          </Box>
        ))}
      {categories.length > chipNumberLimit && (
        <Box sx={{ pl: 1 }}> + {categories.length - chipNumberLimit}</Box>
      )}
    </Box>
  );
};

export const templateDGColumnsDefinition = (
  intl: IntlShape,
  onDelete: CallableFunction,
  handleOpenTemplateCopyModal: CallableFunction
): GridColDef[] => [
  {
    field: 'name',
    headerName: intl.formatMessage({ id: 'TEMPLATES.TABLE.NAME' }),
    sortable: true,
    width: 500,
    renderCell: (params: GridValueGetterParams): GridCellValue => (
      <Box>
        <Typography>{params.row.name}</Typography>
        <Typography className={'w-full block'}>
          {params.row.description}
        </Typography>
      </Box>
    ),
  },
  {
    field: 'categories',
    headerName: intl.formatMessage({ id: 'TEMPLATES.TABLE.CATEGORIESS' }),
    sortable: true,
    flex: 1,
    renderCell: (params: GridValueGetterParams): ReactJSXElement =>
      handleRenderCategoriesChips(params.row.categories),
  },
  {
    field: 'created_at',
    headerName: intl.formatMessage({ id: 'GENERAL.CREATED_AT' }),
    sortable: true,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => {
      // @ts-ignore
      return showDateInItFormat(params.value);
    },
  },
  {
    field: 'updated_at',
    headerName: intl.formatMessage({ id: 'GENERAL.UPDATED_AT' }),
    sortable: true,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => {
      // @ts-ignore
      return showDateInItFormat(params.value);
    },
  },
  {
    field: 'sub',
    headerName: intl.formatMessage({ id: 'GENERAL.TABLE.ACTIONS_LABEL' }),
    renderCell: (param: GridRenderCellParams<string>): ReactJSXElement => (
      <DataGridActionsColumn
        param={param}
        editPath={`/template/${formMode.edit}/${param.row.id}`}
        onDelete={onDelete}
      >
        <IconButton
          onClick={() =>
            handleOpenTemplateCopyModal({
              id: param.id,
              name: param.row.name,
            })
          }
        >
          <Copy />
        </IconButton>
      </DataGridActionsColumn>
    ),
    sortable: false,
    filterable: false,
    hide: false,
    disableColumnMenu: true,
    width: 120,
  },
];
