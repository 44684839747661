import {
  getFirestore,
  Firestore,
  collection,
  getDocs,
  getDoc,
  query,
  orderBy,
  doc,
  runTransaction,
  DocumentReference,
  CollectionReference,
} from 'firebase/firestore';
import { CategoriesService, upsertData } from '../contracts/CategoriesService';
import { Category } from '../../app/modules/categories/models/Category.model';
import { inject, singleton } from 'tsyringe';
import { categoriesCollectionName } from '../GCPUtilities';

@singleton()
export class GCPCategoriesService extends CategoriesService {
  private readonly firestore: Firestore;
  categoriesCollectionName = categoriesCollectionName;
  private readonly categoriesRef: CollectionReference;
  protected readonly tenant: string;

  constructor(@inject('Tenant') tenant: string) {
    super();
    this.tenant = tenant;
    this.firestore = getFirestore();
    this.categoriesRef = collection(
      doc(this.firestore, 'tenants', tenant),
      this.categoriesCollectionName
    );
  }

  /**
   * Get a category starting from its id
   * @param id: the category id
   */
  public get = async (id: string): Promise<Category | false> => {
    try {
      const templateReference = doc(this.categoriesRef, id);
      const categorySnapshot = await getDoc(templateReference);
      // @ts-ignore
      return { ...categorySnapshot.data(), id: categorySnapshot.id };
    } catch (err: unknown) {
      console.error(`Fetch Template fallito`, err);
      return false;
    }
  };

  public getAll = async (): Promise<Category[] | false> => {
    try {
      let categories: Category[] = [];
      // Get and sort by 'Tag'
      const categoriesQuery = query(this.categoriesRef, orderBy('tag', 'desc'));
      const categoriesSnapshot = await getDocs(categoriesQuery);
      categoriesSnapshot.forEach((t: any) =>
        categories.push({ ...t.data(), id: t.id })
      );
      return categories;
    } catch (err: unknown) {
      console.log('Fetch delle categorie fallito', err);
      return false;
    }
  };

  public delete = async (id: string): Promise<boolean> => {
    try {
      await runTransaction(this.firestore, async (transaction) => {
        const templateReference = doc(this.categoriesRef, id);
        transaction.delete(templateReference);
      });
      return Promise.resolve(true);
    } catch (err: unknown) {
      console.error(`Errore in fase di cancellazione della categoria`, err);
      return Promise.resolve(false);
    }
  };

  /**
   * Create a new category via transaction operation
   * @param categoryData: the category to create
   * @returns the created category or false if errors
   **/
  public create = async ({
    categoryData,
    userId,
  }: upsertData): Promise<string> => {
    try {
      const categoryReference = doc(this.categoriesRef);
      if (await this.isThereAlreadyThisCategory(categoryData.inputValue!)) {
        return Promise.reject();
      }
      const userReference = this.getUserReference(userId);
      await runTransaction(this.firestore, async (transaction) => {
        const { inputValue } = categoryData;
        transaction.set(categoryReference, {
          tag: this.cleanAndGetCategoryTag(inputValue),
          owner: userReference,
        });
      });
      return Promise.resolve(categoryReference.id);
    } catch (err: unknown) {
      console.error('Errore in fase di creazione nuova Creazione', err);
      return Promise.reject(false);
    }
  };

  private cleanAndGetCategoryTag = (categoryName: string): string => {
    if (!categoryName) return '';
    return categoryName.trim().toLowerCase();
  };

  public update = async ({
    categoryData,
    userId,
  }: upsertData): Promise<boolean> => {
    try {
      if (await this.isThereAlreadyThisCategory(categoryData.tag!)) {
        return Promise.resolve(false);
      }
      const userReference = this.getUserReference(userId);
      await runTransaction(this.firestore, async (transaction) => {
        const categoryReference = doc(this.categoriesRef, categoryData.id!);
        const values = {
          tag: this.cleanAndGetCategoryTag(categoryData.inputValue),
          owner: userReference,
        };
        transaction.update(categoryReference, values);
      });
      return Promise.resolve(true);
    } catch (err: unknown) {
      console.error('Errore in fase di aggiornamento categoria', err);
      return Promise.resolve(false);
    }
  };

  /**
   * Check if there is already a category with the same tag IN THE CURRENT TENANT
   */
  private isThereAlreadyThisCategory = async (
    tag: string
  ): Promise<boolean> => {
    const categories = await this.getAll();
    if (!categories) {
      return Promise.reject('Get all categories failed!');
    }
    return !!categories.find((e) => e.tag === tag);
  };

  private getUserReference = (userId: string): DocumentReference<any> => {
    return doc(this.firestore, 'users', userId);
  };
}
