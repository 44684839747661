import React, {FC, useState} from 'react';
import {useIntl} from "react-intl";

type Props = {
    removeAction?: () => void
}
const DropdownCrud: FC<Props> = ({removeAction}) => {
    const intl = useIntl()
    const notVisibilityClasses = 'transform opacity-0 scale-95 hidden'
    const visibilityClasses = 'transform opacity-100 scale-100'
    const [visible, setVisible] = useState(false);
    const [menuVisibilityClasses, setmenuVisibilityClasses] = useState(notVisibilityClasses);
    const toggleMenu = () => {
        setVisible(!visible)
        setmenuVisibilityClasses(visible ? notVisibilityClasses : visibilityClasses)
    }


    return (
        <div className="relative inline-block text-left">
            <div className={'border-none'}>
                <button type="button" className="flex items-center" id="menu-button" aria-expanded="true"
                        aria-haspopup="true" onClick={toggleMenu}
                >
                    <span className="sr-only">Open options</span>
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                         aria-hidden="true">
                        <path
                            d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"/>
                    </svg>
                </button>
            </div>
            <div className={`origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black
            ring-opacity-5 divide-y divide-gray-100 focus:outline-none ${menuVisibilityClasses}`} role="menu" aria-orientation="vertical"
                 aria-labelledby="menu-button" tabIndex={-1}>
                <div className="py-1 cursor-pointer" role="none" onClick={toggleMenu}>
                    <div className="text-gray-700 group flex items-center px-4 py-2 text-sm" role="menuitem"
                       tabIndex={-1} id="menu-item-6" onClick={removeAction}>
                        <svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                             aria-hidden="true">
                            <path fillRule="evenodd"
                                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1
                                   1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1
                                    1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                  clipRule="evenodd"/>
                        </svg>
                        {intl.formatMessage({ id: 'GENERAL.FORM.DELETE' })}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default DropdownCrud;
