import React, { ChangeEvent, FC } from 'react';
import { Input } from '../../../../../../_metronic/layout/components/form/Input';
import { useIntl } from 'react-intl';
import { QuestionModel } from '../../../models/question.model';

type Props = {
  min: number;
  max: number;
  step: number;
  onChangeInput: (
    event: ChangeEvent<HTMLInputElement>,
    key: keyof QuestionModel
  ) => void;
  errorMsg?: string | undefined;
  thereIsError?: boolean;
};
const SliderType: FC<Props> = ({ min, max, step, onChangeInput, thereIsError, errorMsg }) => {
  const intl = useIntl();
  const isError = max <= min || step >= max - min
  return (
    <div className={'pb-5'}>
      <div className={'w-full sm:w-1/2 md:w-1/3 grid grid-cols-3 gap-2'}>
        <div>
          <Input
            type='number'
            label={intl.formatMessage({ id: 'QUESTION.SLIDER_MIN_LABEL' })}
            id={'min'}
            onChangeInput={onChangeInput}
            value={min}
            thereIsError={isError && thereIsError}
          />
        </div>
        <div>
          <Input
            type='number'
            label={intl.formatMessage({ id: 'QUESTION.SLIDER_MAX_LABEL' })}
            id={'max'}
            onChangeInput={onChangeInput}
            value={max}
            thereIsError={isError && thereIsError}
          />
        </div>
        <div>
          <Input
            type='number'
            label={intl.formatMessage({ id: 'QUESTION.SLIDER_STEP_LABEL' })}
            id={'step'}
            onChangeInput={onChangeInput}
            value={step}
            thereIsError={isError && thereIsError}
          />
        </div>
      </div>
      {isError && errorMsg && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errorMsg}</span>
            </div>
          </div>
      )}
    </div>
  );
};
export { SliderType };
