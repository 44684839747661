import { Permission } from '../../app/modules/roles/models/Permission.model';
import { Role } from '../../app/modules/roles/models/Role.model';

export abstract class RolesService {
  abstract getMany(): Promise<Role[]>;
  abstract get(id: string): Promise<Role>;
  abstract create(user: Role): Promise<string>;
  abstract update(user: Role): Promise<void>;
  abstract delete(roles: Partial<Role>[]): Promise<boolean>;
  abstract bindPermissions(
    roleId: string,
    permissions: Permission[]
  ): Promise<void>;
  abstract unbindPermission(
    roleId: string,
    permissionId: string
  ): Promise<void>;

  abstract getManyNotInThisIds(ids: string[]): Promise<Role[]>;
}
