import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  defaultTemplate,
  getLastEntitiesOrder,
  newSection,
  templateState,
} from '../state/template';
import { useRecoilState } from 'recoil';
import { Input } from '../../../../_metronic/layout/components/form/Input';
import { Textarea } from '../../../../_metronic/layout/components/form/Textarea';
import { Select } from '../../../../_metronic/layout/components/form/Select';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { TemplateModalErrors } from './TemplateModalErrors';
import { container } from 'tsyringe';
import { TemplateService } from '../../../../service/contracts/TemplateService';
import { useHistory, useParams } from 'react-router-dom';
import { formMode } from '../../../models/creationMode.model';
import { AlertErrors } from '../../../../_metronic/components/alerts/Errors';
import {
  ErrorType,
  JobSitesService,
} from '../../../../service/contracts/JobSitesService';
import { QuestionModel } from '../models/question.model';
import DragAndDropSections from './DragAndDropSections';
import { CategoriesService } from '../../../../service/contracts/CategoriesService';
import { Category } from '../../../modules/categories/models/Category.model';
import FabActions from './FabActions';
import { JobSiteModel } from '../../../models/JobSite';
import VerbalValidationService from '../../../../service/VerbalValidationService';
import { CategoriesAutocompleteWrapper } from './CategoriesAutocompleteWrapper';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  mode: formMode;
};
const Template: FC<Props> = ({ mode }) => {
  const intl = useIntl();
  let history = useHistory();
  const { id }: { id: string } = useParams();
  const templateService = container.resolve<TemplateService>('TemplateService');
  const jobSitesService = container.resolve<JobSitesService>('JobSitesService');
  const templateValidator = new VerbalValidationService(intl);
  const categoriesService =
    container.resolve<CategoriesService>('CategoriesService');
  const [categories, setCategories] = useState<Partial<Category>[]>([]);
  const [template, updateTemplate] = useRecoilState(templateState);
  const [templateErrors, setTemplateErrors] = useState<
    { field: string; message: string }[]
  >([]);
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(false);
  const [jobSites, setJobSites] = useState<JobSiteModel[]>([]);
  const getJobsites = async () => {
    const jobSites: JobSiteModel[] | ErrorType = await jobSitesService.getAll();
    if (!('isError' in jobSites)) return setJobSites(jobSites);
  };

  const getCategories = async () => {
    let response: Partial<Category>[] | false =
      await categoriesService.getAll();
    if (typeof response === 'boolean') {
      return;
    }
    setCategories(
      response
        .map((category) => ({ id: category.id, tag: category.tag }))
        .filter((e) => !template.categories.map((f) => f.tag).includes(e.tag))
    );
  };

  const updateTemplateState = (
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    key: keyof QuestionModel
  ): void => {
    updateTemplate({ ...template, [key]: event.target.value });
  };

  const handleRenderTitle = (): string => {
    if (mode === formMode.create) {
      return intl.formatMessage({ id: 'TEMPLATE.FORM_CREATION_TITLE_LABEL' });
    }
    return intl.formatMessage({ id: 'TEMPLATE.FORM_EDIT_TITLE_LABEL' });
  };

  /**
   * Add a new section to the template giving it the last order
   */
  const addSection = (): void => {
    const newSectionOrder = getLastEntitiesOrder(template.sections);
    updateTemplate({
      ...template,
      sections: [
        ...template.sections,
        { ...newSection(newSectionOrder), expanded: true },
      ],
    });
  };

  /**
   * Handle template creation/editing
   */
  const saveTemplate = async (): Promise<void> => {
    // Check errors
    const errors = getErrors();
    if (errors) {
      return;
    }
    // Call template UPSERT service
    const result = await (mode === formMode.create
      ? templateService.create(template)
      : templateService.update(template));
    // Redirect to template list
    if (result) {
      history.push('/template-list');
    }
    // TODO : Handle errors
  };

  const getErrors = (): boolean => {
    const response = templateValidator.evaluateTemplate(template);
    if (response.length === 0) {
      return false;
    }
    setTemplateErrors(response);
    setShowErrorsModal(true);
    return true;
  };

  useEffect(() => {
    const fetchAll = async () => {
      await getJobsites();
      await getCategories();
      if (mode === formMode.create) {
        return updateTemplate(defaultTemplate);
      }
      if (!id) {
        return;
      }
      const getTemplate = async (id: string) => {
        const templateFetched = await templateService.getTemplate(id);
        if (templateFetched) {
          updateTemplate(templateFetched);
        }
      };
      await getTemplate(id);
    };
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, mode]);

  const handleRenderJobsitesOptions = (): ReactJSXElement[] => {
    const options: ReactJSXElement[] = [];
    jobSites.forEach((js: JobSiteModel, index: number) => {
      options.push(
        <option key={index} value={js.id}>
          {js.name}
        </option>
      );
    });
    return options;
  };

  const onCategoriesChange = async (
    event: any,
    categories: Partial<Category>[]
  ) => {
    try {
      updateTemplate({ ...template, categories });
    } catch (e) {
      console.error(e);
    }
  };

  const getError = (field: string): string | undefined =>
    templateErrors.find((e) => e.field === field)?.message;

  return (
    <>
      <div className={'container mw-100 primary-bg rounded-xl p-10 h-auto'}>
        {/* Form: Title + Description + Category + JobSite */}
        <form>
          <div>
            <div>
              <div className='text-2xl mb-10'>
                <h3 className='card-title font-semibold capitalize primary-text'>
                  {handleRenderTitle()}
                </h3>
              </div>
              <div className='mb-3'>
                <Input
                  onChangeInput={updateTemplateState}
                  label={intl.formatMessage({
                    id: 'TEMPLATE.FORM_TITLE_LABEL',
                  })}
                  id='name'
                  value={template.name}
                  errorMsg={getError('name')}
                  thereIsError={!!getError('name')}
                />
              </div>
              <div className='mb-3'>
                <Textarea
                  id='description'
                  onChangeInput={updateTemplateState}
                  label={intl.formatMessage({
                    id: 'TEMPLATE.FORM_DESCRIPTION_LABEL',
                  })}
                  value={template.description}
                  errorMsg={getError('description')}
                  thereIsError={!!getError('description')}
                />
              </div>

              <div className='mb-3'>
                <Select
                  id='jobSite'
                  label={intl.formatMessage({
                    id: 'TEMPLATE.FORM_JOBSITE_LABEL',
                  })}
                  onChangeInput={updateTemplateState}
                  value={template.jobSite}
                  errorMsg={getError('jobSite')}
                  thereIsError={!!getError('jobSite')}
                >
                  {handleRenderJobsitesOptions()}
                </Select>
              </div>
              <div className='mb-3'>
                <div>
                  <label htmlFor={id} className='text-lg'>
                    {intl.formatMessage({
                      id: 'TEMPLATE.FORM_CATEGORIES_LABEL',
                    })}
                  </label>
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'CATEGORIES-ATOCOMPLETE.HELP_INFO',
                    })}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <CategoriesAutocompleteWrapper
                  categories={categories}
                  onCategoriesChange={onCategoriesChange}
                  value={template.categories}
                  errorMsg={getError('categories')}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* Drag and Drop Sections */}
      <div className='container  mw-100 mt-5 mb-20 px-0'>
        <DragAndDropSections
          sections={template.sections}
          mode={mode}
          saveTemplate={saveTemplate}
          templateErrors={templateErrors}
        />
      </div>
      {/* Template Errors Modal */}
      <TemplateModalErrors
        open={showErrorsModal}
        handleClose={() => setShowErrorsModal(false)}
      >
        <AlertErrors
          errors={[
            ...Array.from(new Set(templateErrors.map((e) => e.message))),
          ]}
        />
      </TemplateModalErrors>
      <FabActions add={addSection} save={saveTemplate} />
    </>
  );
};

export { Template };
