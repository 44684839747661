import { Role } from '../modules/roles/models/Role.model';
import { JobSiteModel } from './JobSite';
import { Tenant } from './Tenant.model';

export type UserCore = {
  email: string;
  display_name: string;
};

export type UserModel = UserCore & {
  id: string;
  job_sites: JobSiteModel[];
  tenants: Tenant[];
  role: Role;
};

export const emptyUser: Partial<UserModel> = {
  email: '',
  display_name: '',
  job_sites: [],
  tenants: [],
};
