export enum QuestionType {
  picture = 'PICTURE',
  boolean = 'BOOLEAN',
  correlation = 'CORRELATION',
  datetime = 'DATETIME',
  instruction = 'INSTRUCTION',
  // checkbox = 'CHECKBOX',
  numeric = 'NUMERIC',
  position = 'POSITION',
  signature = 'SIGNATURE',
  slider = 'SLIDER',
  table = 'TABLE',
  text = 'TEXT',
  // order = 'ORDER',
  // verbal_no = "VERBAL_NO",
  // non_conformity = 'NON_CONFORMITY',
  // non_conformity_t = 'NON_CONFORMITY_T',
  multichoice = 'MULTICHOICE',
  radio = 'RADIO',
}
