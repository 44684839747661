import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { IntlShape } from 'react-intl';
import { DataGridActionsColumn } from '../../../../_metronic/components/datagrid/DataGridActionsColumn';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { showDateInItFormat } from '../../../../_metronic/utilities/utilities';

export const usersDGColumnsDefinition = (
  intl: IntlShape,
  onDelete: CallableFunction,
  handleDownloadUrl: CallableFunction
): GridColDef[] => [
  {
    field: 'name',
    headerName: intl.formatMessage({ id: 'VERBAL.GENERAL_DESCRIPTION_LABEL' }),
    sortable: true,
    flex: 1,
    renderCell: (params: GridValueGetterParams): GridCellValue => (
      <Box>
        <Typography sx={{ fontWeight: 600 }}>{params.row.name}</Typography>
        {params.row.signedBy && (
          <>
            <Typography className={'w-full block'}>
              <i>{intl.formatMessage({ id: 'VERBAL.DATAGRID.SIGNEDBY' })}</i>{' '}
              {params.row.signedBy}
            </Typography>
            <Typography className={'w-full block'}>
              <i>{intl.formatMessage({ id: 'VERBAL.DATAGRID.UPDATEDAT' })}</i>{' '}
              {showDateInItFormat(params.row.signedAt)}
            </Typography>
          </>
        )}
      </Box>
    ),
  },
  {
    field: 'updatedAt',
    headerName: intl.formatMessage({ id: 'GENERAL.UPDATED_AT' }),
    sortable: true,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => {
      // @ts-ignore
      return showDateInItFormat(params.value);
    },
  },
  {
    field: 'sub',
    headerName: intl.formatMessage({ id: 'GENERAL.TABLE.ACTIONS_LABEL' }),
    renderCell: (param: GridRenderCellParams<string>): ReactJSXElement => (
      <DataGridActionsColumn
        param={param}
        onDelete={onDelete}
        downloadPath={handleDownloadUrl}
      />
    ),
    sortable: false,
    filterable: false,
    hide: false,
    disableColumnMenu: true,
  },
];
