import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as formUtils from '../utils/formUtils';
import { IntermediateMessage } from './IntermediateMessage';
import { ButtonText } from '../../../../_metronic/components/ButtonText';
import { ButtonWrapper } from '../../../../_metronic/components/ButtonWrapper';
import { Input } from '../../../../_metronic/layout/components/form/Input';
import { container } from 'tsyringe';
import {
  AuthErrorType,
  AuthService,
} from '../../../../service/contracts/AuthService';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

export function ForgotPassword() {
  const intl = useIntl();
  const authService = container.resolve<AuthService>('AuthService');
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const initialValues = {
    email: '',
  };
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'AUTH.GENERAL.FORMAT_NOT_VALID' }))
      .min(3, intl.formatMessage({ id: 'AUTH.GENERAL.FIELD_MIN_3_CHARACTERS' }))
      .max(
        50,
        intl.formatMessage({ id: 'AUTH.GENERAL.FIELD_MAX_50_CHARACTERS' })
      )
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' })),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      authService
        .requestResetPassword(values.email)
        .then(() => manageSuccess(setHasErrors, setLoading))
        .catch((error: AuthErrorType) =>
          manageError(error, setStatus, setSubmitting)
        );
    },
  });

  const manageSuccess = (
    setHasErrors: (status?: any) => void,
    setLoading: (isSubmitting: boolean) => void
  ) => {
    setLoading(true);
    setHasErrors(false);
  };

  const manageError = (
    error: AuthErrorType,
    setStatus: Function,
    setSubmitting: Function
  ) => {
    setHasErrors(true);
    setLoading(false);
    setSubmitting(false);
    setStatus(intl.formatMessage({ id: error.intlIdCode }));
  };

  const formStatus = (): JSX.Element => {
    if (!hasErrors) return <></>;
    return (
      <div className='mb-lg-15 alert alert-danger'>
        <div className='alert-text font-weight-bold'>{formik.status}</div>
      </div>
    );
  };

  const handleRenderSubmitContent = (): ReactJSXElement => {
    if (!loading)
      return (
        <ButtonText title={intl.formatMessage({ id: 'GENERAL.FORM.SEND' })} />
      );
    return (
      <ButtonText
        classes='indicator-progress block'
        title={intl.formatMessage({ id: 'AUTH.LOGIN.WAIT_MSG' })}
      >
        <span className='spinner-border spinner-border-sm align-middle ms-2' />
      </ButtonText>
    );
  };

  /**
   * If was given a reset confirmation then the state of the component change => an intermediate page where the user
   * can proceed or cancel the request is shown
   */
  if (hasErrors === false) {
    return (
      <IntermediateMessage
        message={intl.formatMessage({
          id: 'AUTH.FORGOTPWD.SUCCESS_REQUEST_MSG',
        })}
        type='success'
        nextPathRoute='/forgot-password-submit'
      />
    );
  }

  return (
    <>
      <form
        className='form w-100'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        {/* Form Title */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3 text-2xl font-bold'>
            {intl.formatMessage({ id: 'AUTH.FORGOTPWD.FORM_MSG' })}
          </h1>
          <div className='secondary-text text-xl'>
            {intl.formatMessage({ id: 'AUTH.FORGOTPWD.FORM_SUB_MSG' })}
          </div>
        </div>
        {/* Form Errors */}
        {formStatus()}
        {/* Form Body */}
        <div className='mb-5'>
          <Input
            label={intl.formatMessage({ id: 'AUTH.FORGOTPWD.FORM_EMAIL' })}
            id='email'
            type='email'
            formikProps={{ ...formik.getFieldProps('email') }}
            errorMsg={formUtils.getErrorMsg('email', formik)}
            isTouched={formUtils.isTouched('email', formik)}
            thereIsError={formUtils.thereIsError('email', formik)}
          />
        </div>
        {/* Form Submit */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <ButtonWrapper
            id='kt_password_reset_submit'
            classes='w-full'
            otherProps={{ type: 'submit' }}
          >
            {handleRenderSubmitContent()}
          </ButtonWrapper>
        </div>
      </form>
    </>
  );
}
