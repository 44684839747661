import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { container } from 'tsyringe';
import { Category } from './models/Category.model';
import { DataGridCustomized } from '../../../theme/DataGrid';
import { CategoriesService } from '../../../service/contracts/CategoriesService';
import { AlertErrors } from '../../../_metronic/components/alerts/Errors';
import { DeletionModal } from '../../../_metronic/components/modals/DeletionModal';
import { columnsDefinition } from './settings/DataGrid';
import SimpleModalWithText from '../../pages/templates/components/SimpleModalWithText';
import { useRecoilValue } from 'recoil';
import { userState } from '../sikuro_auth/state/atoms/user';

const CategoriesListPage: FC = () => {
  const intl = useIntl();
  const user = useRecoilValue(userState); //expiresAt
  const categoriesService =
    container.resolve<CategoriesService>('CategoriesService');
  const [categories, setCategories] = useState<Category[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [categoryToDelete, setCategoryToDelete] = useState<Category>();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [upsertModalOpened, setUpsertModalOpened] = useState<boolean>(false);
  const userData = useRecoilValue(userState);
  const [upsertOperationDone, setUpsertOperationDone] =
    useState<boolean>(false);
  const [categoryToUpsert, setCategoryToUpsert] = useState<{
    id: string;
    inputValue: string;
  }>({
    id: '',
    inputValue: '',
  });

  /**
   * Handle categories deletion, when a category is deleted refresh the table
   */
  useEffect(() => {
    async function getCategories() {
      let response: Category[] | false = await categoriesService.getAll();
      setLoading(false);
      if (typeof response === 'boolean') {
        return setErrors(['Errori']);
      }
      return setCategories(response);
    }
    setLoading(true);
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertOperationDone, userData]);

  /**
   * When the user clicks on delete button => open the deletion modal
   * @param category: the category to be deleted
   */
  const openDeletionModal = (category: Category) => {
    setOpenDeleteModal(true);
    setCategoryToDelete(category);
  };

  /**
   * When the user confirm the category to be deleted
   */
  const onConfirmCategoryDeletion = async () => {
    try {
      if (!categoryToDelete) {
        return;
      }
      await categoriesService.delete(categoryToDelete.id);
      setCategoryToDelete(undefined);
      setUpsertOperationDone(!upsertOperationDone);
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDeleteModal(false);
    }
  };

  /**
   * Handle creation or update of a category. If id is empty => create a new category
   * @param data: id and the name coming from upsert modal
   */
  const handleCategoryUpsert = async (data: {
    id: string;
    inputValue: string;
  }) => {
    try {
      if (data.id) {
        // update category
        await categoriesService.update({
          categoryData: data,
          userId: user.uid,
        });
      } else {
        // create new category
        await categoriesService.create({
          categoryData: data,
          userId: user.uid,
        });
      }
      setCategoryToUpsert({ id: '', inputValue: '' });
      setUpsertOperationDone(!upsertOperationDone);
    } catch (err: unknown) {
      console.error('Duplicazione del verbale Fallita', err);
    } finally {
      setUpsertModalOpened(false);
    }
  };

  const setOpenUpsertModal = (category?: Category) => {
    setCategoryToUpsert({
      id: category?.id || '',
      inputValue: category?.tag || '',
    });
    setUpsertModalOpened(true);
  };

  const columns = columnsDefinition(
    intl,
    openDeletionModal,
    setOpenUpsertModal
  );

  const handleNewCategory = () => {
    setCategoryToUpsert({
      id: '',
      inputValue: '',
    });
    setUpsertModalOpened(true);
  };

  return (
    <div className='container mw-100 px-0'>
      <div className={'mb-2'}>
        <label
          role='button'
          onClick={() => handleNewCategory()}
          className='items-center px-10 py-3 border rounded-md secondary-bg-lighter font-bold '
        >
          <span className={`secondary-text`}>
            {intl.formatMessage({
              id: 'CATEGORIES.LIST.NEW_CATEGORY',
            })}
          </span>
        </label>
        {/* <Button
          classes='mr-2'
          id='new-job-site'
          innerText={intl.formatMessage({
            id: 'CATEGORIES.LIST.NEW_CATEGORY',
          })}
          otherProps={{
            onClick: handleNewCategory,
          }}
        /> */}
      </div>
      <div>
        <AlertErrors errors={errors} />
      </div>
      <div className='h-full'>
        <DataGridCustomized
          columns={columns}
          rows={categories}
          props={{ loading }}
        />
      </div>
      <DeletionModal
        open={openDeleteModal}
        setModalState={setOpenDeleteModal}
        onConfirm={onConfirmCategoryDeletion}
        entityString={categoryToDelete?.tag || ''}
      />
      <SimpleModalWithText
        entityProps={categoryToUpsert}
        open={upsertModalOpened}
        setOpen={setUpsertModalOpened}
        handleSubmit={handleCategoryUpsert}
        labels={{
          inputLabel: intl.formatMessage({
            id: !!categoryToUpsert.id
              ? 'CATEGORIES_LIST_PAGE.UPDATE_CATEGORY.HELP_TEXT'
              : 'CATEGORIES_LIST_PAGE.INSERT_CATEGORY.HELP_TEXT',
          }),
          helpText: intl.formatMessage({
            id: 'SIMPLE_MODAL_TEXT.HELP_TEXT',
          }),
        }}
      />
    </div>
  );
};

export { CategoriesListPage };
