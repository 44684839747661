import { FC } from 'react';
import { Input } from '../../../../../_metronic/layout/components/form/Input';
import { CheckboxItem } from '../CheckboxItem';
import { Select } from '../../../../../_metronic/layout/components/form/Select';
import { useIntl } from 'react-intl';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import DropdownCrud from '../../../../../_metronic/components/DropdownCrud';
import { QuestionModel } from '../../models/question.model';
import { QuestionType } from '../../models/questionType.enum';
import { CorrelationType } from './types/CorrelationType';
import { useRenderEnumInSelect } from '../../../../../_metronic/hooks/useRenderEnumInSelect';
import { DateTimeType } from './types/DateTimeType';
import { InstructionType } from './types/InstructionType';
import { MultiChoiceType } from './types/MultiChoiceType';
import { SliderType } from './types/SliderType';
import { updateQuestionType } from '../../models/updateQuestionType';
import { TableTypeWrapper } from './types/TableTypeWrapper';

/**
 * Return Question Component BASED ON question type
 * @param question: question model
 * @param updateQuestion: function to update/save question
 * @returns: The Question Component
 */
const handleRenderQuestionType = (
  question: QuestionModel,
  sectionIndex: number,
  updateQuestion: updateQuestionType,
  isNc: boolean,
  templateErrors?: { field: string; message: string }[]
): ReactJSXElement => {
  if (question.type === QuestionType.correlation) {
    return (
      <CorrelationType
        correlationType={question.correlationType}
        onChangeInput={updateQuestion}
      />
    );
  }
  if (question.type === QuestionType.datetime) {
    return (
      <DateTimeType onChangeInput={updateQuestion} value={question.date_time} />
    );
  }
  if (question.type === QuestionType.instruction) {
    return (
      <InstructionType
        onChangeInput={updateQuestion}
        images={question.images}
        instructionDescription={question.instruction_description}
      />
    );
  }
  if (question.type === QuestionType.table) {
    return (
      <TableTypeWrapper
        question={question}
        isNc={isNc}
        sectionIndex={sectionIndex}
      />
    );
  }
  if (question.type === QuestionType.slider) {
    const errorMsg = templateErrors?.find(
      (e) => e.field === `sections.${sectionIndex}.questions.slider`
    )?.message;
    return (
      <SliderType
        onChangeInput={updateQuestion}
        min={question.min}
        max={question.max}
        step={question.step}
        thereIsError={!!errorMsg}
        errorMsg={errorMsg}
      />
    );
  }
  if (question.type === QuestionType.multichoice) {
    return (
      <MultiChoiceType
        options={question.options}
        onChangeInput={updateQuestion}
      />
    );
  }
  if (question.type === QuestionType.radio) {
    return (
      <MultiChoiceType
        options={question.options}
        onChangeInput={updateQuestion}
        radioMode={true}
      />
    );
  }
  return <></>;
};

type Props = {
  updateQuestion: updateQuestionType;
  deleteQuestion: () => void;
  question: QuestionModel;
  sectionIndex: number;
  otherProps?: Object;
  typesToExcludes?: string[];
  excludeCheckbox?: boolean;
  questionLabels?: {
    title: string;
    placeholder: string;
  };
  isNc?: boolean;
  templateErrors?: { field: string; message: string }[];
  errorMsg?: string | undefined;
};
/**
 * Question component: IT ONLY DISPLAY JSX, all business logic is delegated to its QuestionWrapper
 * @param props.question The question to be Rendered
 * @param props.styleProps All style properties to apply to the component
 * @param props.updateQuestion Function to call when update question
 * @param props.deleteQuestion Function to call when delete question
 * @param props.typesToExcludes All question types to exclude in select choices
 * @param props.excludeCheckbox True if common CheckboxItem must be excluded
 * */
const Question: FC<Props> = ({
  question,
  sectionIndex,
  otherProps,
  updateQuestion,
  deleteQuestion,
  typesToExcludes,
  excludeCheckbox = false,
  questionLabels,
  isNc = false,
  templateErrors,
  errorMsg,
}) => {
  const intl = useIntl();
  const widthPercError = intl.formatMessage({
    id: 'TEMPLATE.ERRORS_WIDTH_PERC_NOT_VALID',
  });
  const contentErrorMsg = intl.formatMessage({
    id: 'TEMPLATE.ERRORS_QUESTION_TITLE_MANDATORY',
  });
  questionLabels = questionLabels ?? {
    title: intl.formatMessage({ id: 'QUESTION.QUESTION_TITLE' }),
    placeholder: intl.formatMessage({ id: 'QUESTION.QUESTION_TITLE' }),
  };

  const getError = (field: string): string | undefined => {
    return templateErrors?.find(
      (e) => e.field === `sections.${sectionIndex}.questions.${field}`
    )?.message;
  };
  return (
    <div className='mt-5' {...otherProps}>
      <div className='space-y-6 lg:col-start-1 lg:col-span-2'>
        <section>
          <div className='border-solid border-1 mt-5 sm:rounded-lg'>
            <div className='flex px-4 py-5 sm:px-6 flex gap-2 items-center text-lg w-full'>
              <div className={'relative grid sm:flex w-full gap-5'}>
                <div className='sm:w-8/12'>
                  <Input
                    label={questionLabels.title}
                    id='content'
                    value={question.content}
                    onChangeInput={updateQuestion}
                    otherProps={{ placeholder: questionLabels.placeholder }}
                    errorMsg={
                      question.content
                        ? undefined
                        : getError('content') || contentErrorMsg
                    }
                    thereIsError={
                      !question.content &&
                      !!(getError('content') || contentErrorMsg)
                    }
                  />
                </div>
                <div className='sm:w-3/12'>
                  <Select
                    id='type'
                    value={question.type}
                    onChangeInput={updateQuestion}
                    label={intl.formatMessage({
                      id: 'QUESTION.ANSWER_TYPE_LABEL',
                    })}
                  >
                    {useRenderEnumInSelect(
                      QuestionType,
                      'QUESTION.TYPE_',
                      typesToExcludes
                    )}
                  </Select>
                </div>
                <div className='sm:flex sm:justify-end absolute right-0'>
                  <div>
                    <DropdownCrud removeAction={deleteQuestion} />
                  </div>
                </div>
              </div>
            </div>
            <div className={'px-4 mb-5'}>
              {excludeCheckbox && (
                <>
                  <Input
                    type='number'
                    otherProps={{ min: 0, max: 100 }}
                    label={intl.formatMessage({
                      id: 'QUESTION.WIDTH_PERC',
                    })}
                    id='width_perc'
                    value={question.width_perc || 0}
                    className='sm:w-1/4 w-full'
                    onChangeInput={updateQuestion}
                    errorMsg={
                      !question.width_perc ||
                      (typeof question.width_perc === 'number' &&
                        question.width_perc >= 0 &&
                        question.width_perc <= 100)
                        ? undefined
                        : getError('width_perc') || widthPercError
                    }
                    thereIsError={
                      !(
                        !question.width_perc ||
                        (typeof question.width_perc === 'number' &&
                          question.width_perc >= 0 &&
                          question.width_perc <= 100)
                      ) && !!(getError('width_perc') || widthPercError)
                    }
                  />
                  <p>
                    <i>
                      {intl.formatMessage({
                        id: 'QUESTION.WIDTH_PERC_HELP_TEXT',
                      })}
                    </i>
                  </p>
                </>
              )}
            </div>
            {!excludeCheckbox && (
              <div className='border-t px-4 py-5'>
                <div className={'grid grid-cols-3'}>
                  <CheckboxItem
                    id='required'
                    onChangeInput={updateQuestion}
                    otherProps={{ checked: question.required }}
                    label={intl.formatMessage({
                      id: 'QUESTION.CBOX_MANDATORY',
                    })}
                  />
                  <CheckboxItem
                    id='is_visible_in_report'
                    onChangeInput={updateQuestion}
                    otherProps={{ checked: question.is_visible_in_report }}
                    label={intl.formatMessage({
                      id: 'QUESTION.CBOX_VIEW_MANDATORY',
                    })}
                  />
                  <CheckboxItem
                    id='is_duplicable'
                    onChangeInput={updateQuestion}
                    otherProps={{ checked: question.is_duplicable }}
                    label={intl.formatMessage({
                      id: 'QUESTION.CBOX_IS_DUPLICABLE',
                    })}
                  />
                </div>
              </div>
            )}
            <div className={'px-4'}>
              {handleRenderQuestionType(
                question,
                sectionIndex,
                updateQuestion,
                isNc,
                templateErrors
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export { Question };
