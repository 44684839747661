import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { IntlShape } from 'react-intl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReactElement } from 'react';
import { DataGridActionsColumn } from '../../../../../../../../../../_metronic/components/datagrid/DataGridActionsColumn';
import { UserModel } from '../../../../../../../../../models/UserModel';

export const usersDGColumnsDefinition = (
  intl: IntlShape,
  unbindUser: (user: UserModel) => void
): GridColDef[] => [
  {
    field: 'field_name',
    headerName: intl.formatMessage({ id: 'USERS_LIST.TABLE.NAME_AND_SURNAME' }),
    sortable: true,
    flex: 1,
    renderCell: (params: GridValueGetterParams): GridCellValue => (
      <Box>
        <Typography>{params.row.email}</Typography>
        {params.row.displayName && (
          <Typography className={'w-full block'}>
            {params.row.displayName}
          </Typography>
        )}
      </Box>
    ),
  },
  {
    field: 'sub',
    headerName: intl.formatMessage({ id: 'GENERAL.TABLE.ACTIONS_LABEL' }),
    renderCell: (param: GridRenderCellParams<string>): ReactElement => (
      <DataGridActionsColumn param={param} onDelete={unbindUser} />
    ),
    sortable: false,
    filterable: false,
    hide: false,
    disableColumnMenu: true,
  },
];
