import { atom } from 'recoil'
import { intlConfig } from '../types/intlConfig'
const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'


export const initialIntlConfigState: intlConfig = {
  selectedLang: 'it',
}

const intlConfigState = atom({
    key: 'intlConfig',
    default: getConfig()
})

export function getConfig(): intlConfig {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as intlConfig
    } catch (er) {
      console.error(er)
    }
  }
  return initialIntlConfigState
}

export function setIntlConfigStorage(newConfig: intlConfig){
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify(newConfig))
}


export { intlConfigState };