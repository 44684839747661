import { IntlShape } from 'react-intl';
import * as Yup from 'yup';


export const loginSchema = (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'AUTH.GENERAL.FORMAT_NOT_VALID' }))
      .required(intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' })),
    password: Yup.string().required(
      intl.formatMessage({ id: 'AUTH.GENERAL.REQUIRED_FIELD_MSG' })
    ),
  });
