import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { Routes } from './routing/Routes';
import { ThemeProvider } from '@mui/material';
import { muiThemeOverrides } from './muiStyles';
import ThemeColorPresets from '../theme/ThemeSettings';

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  return (
    <>
      <ThemeProvider theme={muiThemeOverrides}>
        <ThemeColorPresets>
          <Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter basename={basename}>
              <I18nProvider>
                <LayoutProvider>
                  <Routes />
                </LayoutProvider>
              </I18nProvider>
            </BrowserRouter>
          </Suspense>
        </ThemeColorPresets>
      </ThemeProvider>
    </>
  );
};

export { App };
