import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DataGridCustomized } from '../../theme/DataGrid';
import { DeletionModal } from '../../_metronic/components/modals/DeletionModal';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { DataGridActionsColumn } from '../../_metronic/components/datagrid/DataGridActionsColumn';
import { formMode } from '../models/creationMode.model';
import { AlertErrors } from '../../_metronic/components/alerts/Errors';
import { useRecoilValue } from 'recoil';
import { userState } from '../modules/sikuro_auth/state/atoms/user';

type Props<T> = {
  entityName: string;
  entitiesService: any;
  columns: GridColDef[];
  filters?: any;
  toEntityString?: ((row: T) => string) | null;
};

/**
 * General Table component
 * @param props.entitiesService The entity service to call in order to execute CRUD operations on the entities
 * @param props.columns Table columns definition
 * @param props.filters Filters to apply to the table, if there are no filters the table will show all the entities
 * */
export function GeneralTable<T>({
  entityName,
  entitiesService,
  columns,
  filters,
  toEntityString,
}: Props<T>) {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [entities, setEntities] = useState<T[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [fetchTrigger, setFetchTrigger] = useState<boolean>(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
  const [entitiesToDelete, setEntitiesToDelete] = useState<T[]>([]);
  const userData = useRecoilValue(userState);

  const handleRenderErrors = (): ReactJSXElement => {
    if (errors.length === 0) return <></>;
    return (
      <div className={'my-5'}>
        <AlertErrors errors={errors} />
      </div>
    );
  };

  /**
   * Handle entities fetching (GET ALL)
   */
  useEffect(() => {
    async function getEntities() {
      setLoading(true);
      let fetchResponse: T[] | false = await entitiesService.getMany(filters);
      setLoading(false);
      if (!fetchResponse) {
        return setErrors([
          intl.formatMessage({ id: 'GENERAL.ALERTS.THERE_WAS_AN_ERRORS' }),
        ]);
      }
      return setEntities(fetchResponse);
    }
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTrigger, userData]);

  /**
   * Handle entities DELETION, when an entity is deleted refresh the table
   */
  const openDeletionModal = (entity: T) => {
    setDeleteModalOpened(true);
    setEntitiesToDelete([entity]);
  };

  const deleteEntity = async () => {
    try {
      if (entitiesToDelete.length === 0) {
        return;
      }
      await entitiesService.delete(entitiesToDelete);
      setDeleteModalOpened(false);
      setFetchTrigger(!fetchTrigger);
    } catch (e: unknown) {
      return setErrors([
        intl.formatMessage({ id: 'GENERAL.ALERTS.THERE_WAS_AN_ERRORS' }),
      ]);
    }
  };

  /**
   * Add EDIT/DELETION actions to the columns provided
   */
  const _columns: GridColDef[] = [
    ...columns,
    {
      field: 'sub',
      headerName: intl.formatMessage({ id: 'GENERAL.TABLE.ACTIONS_LABEL' }),
      renderCell: (param: GridRenderCellParams<string>): ReactJSXElement => (
        <DataGridActionsColumn
          param={param}
          editPath={`/${entityName}/${formMode.edit}/${param.row.id}`}
          onDelete={openDeletionModal}
        />
      ),
      sortable: false,
      filterable: false,
      hide: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <>
      {handleRenderErrors()}
      <div className='h-full'>
        <DataGridCustomized
          columns={_columns}
          rows={entities}
          props={{ loading }}
        />
      </div>
      <DeletionModal
        open={deleteModalOpened}
        setModalState={setDeleteModalOpened}
        onConfirm={deleteEntity}
        entityString={
          entitiesToDelete.length > 0 && !!toEntityString
            ? toEntityString(entitiesToDelete[0])
            : intl.formatMessage({
                id: 'GENERAL.MODAL.DELETE_CONFIRMATION_NO_ENTITY_SPECIFICATION',
              })
        }
      />
    </>
  );
}
