import React, { ChangeEvent, FC } from 'react';
import { Select } from '../../../../../../_metronic/layout/components/form/Select';
import { useIntl } from 'react-intl';
import { useRenderEnumInSelect } from '../../../../../../_metronic/hooks/useRenderEnumInSelect';
import { DateTimeEnum } from '../../../models/dateTime.enum';

type Props = {
  onChangeInput: (
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    key: any
  ) => void;
  value: DateTimeEnum;
};
const DateTimeType: FC<Props> = ({ onChangeInput, value }) => {
  const intl = useIntl();

  return (
    <div className={'pb-5'}>
      <div className={'w-full sm:w-1/3'}>
        <Select
          id='date_time'
          label={intl.formatMessage({ id: 'QUESTION.DATETIME_LABEL' })}
          onChangeInput={onChangeInput}
          value={value}
        >
          {useRenderEnumInSelect(DateTimeEnum, 'QUESTION.DATETIME_')}
        </Select>
      </div>
    </div>
  );
};
export { DateTimeType };
