import {FC} from 'react'
import {ButtonWrapper} from "./ButtonWrapper";
import {ButtonText} from "./ButtonText";

type Props = {
    id: string,
    classes?: string,
    otherProps?: any,
    innerText?: string
}

const Button: FC<Props> = ({id, classes, otherProps, innerText}) => {
    return <ButtonWrapper id={id} classes={classes} otherProps={otherProps}>
        <ButtonText title={innerText!} />
    </ButtonWrapper>
}

export {Button}
