import { Category } from '../../app/modules/categories/models/Category.model';
import { NonConformityTemplate } from '../../app/modules/non_conformity/models/nonConformity.model';

export type ErrorType = {
  isError: true;
  code: string;
  intlIdCode: string; // code da fornire a intl per una successiva traduzione
};
export abstract class NonConformityTemplateService {
  abstract getAll(): Promise<NonConformityTemplate[] | ErrorType>;
  abstract get(id: string): Promise<NonConformityTemplate | ErrorType>;
  abstract create(
    nonConformity: NonConformityTemplate,
    is_copying_images?: boolean
  ): Promise<true | ErrorType>;
  abstract delete(
    nonConformity: NonConformityTemplate
  ): Promise<true | ErrorType>;
  abstract update(
    nonConformity: NonConformityTemplate
  ): Promise<true | ErrorType>;
  abstract getFiltered(filters: {
    categories: Partial<Category>[];
  }): Promise<NonConformityTemplate[] | false>;
}
