import { FC, ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '../../../../../_metronic/components/Button';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { UserModel } from '../../../../models/UserModel';
import { formMode } from '../../../../models/creationMode.model';
import { container } from 'tsyringe';
import { RolesService } from '../../../../../service/contracts/RolesService';
import { ActorsService } from '../../../../../service/contracts/ActorsService';
import { Role } from '../../../roles/models/Role.model';
import { useHistory } from 'react-router-dom';

type Props = {
  mode?: formMode;
  values: Partial<UserModel>;
  submitEvent: (data: Partial<UserModel>) => void;
};
const UserForm: FC<Props> = ({ mode, values, submitEvent }): ReactElement => {
  const intl = useIntl();
  let history = useHistory();
  const rolesService = container.resolve<RolesService>('RolesService');
  const actorsService = container.resolve<ActorsService>('ActorsService');
  const [roles, setRoles] = useState<Role[]>([]);
  const [role, setRole] = useState<string>();

  const handleChange = (event: SelectChangeEvent) => {
    // @ts-ignore
    setRole(event.target.value);
  };

  useEffect(() => {
    const getActorsAndRoles = async () => {
      const roles = await rolesService.getMany();
      setRoles(roles);
      const actor = await actorsService.getByUser(values.id!);
      setRole(roles.find((r) => r.id === actor?.roleId)?.id);
    };
    getActorsAndRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    try {
      await actorsService.upsert(values.id!, role as string);
      history.push('/users-list');
    } catch (error: unknown) {}
  };

  return (
    <div className={'container primary-bg rounded-xl mw-100 p-10 h-auto'}>
      <div>
        <div>
          <div className='text-2xl mb-10'>
            <h3 className='card-title font-semibold capitalize primary-text'>
              {intl.formatMessage({ id: 'USER.FORM.EDIT' })}
            </h3>
          </div>
          <Stack direction='row' spacing={2}>
            <TextField
              fullWidth
              name='email'
              value={values.email}
              disabled={true}
              size='small'
              label={intl.formatMessage({
                id: 'USER.FORM_EMAIL_LABEL',
              })}
            />
            <TextField
              fullWidth
              name='display_name'
              value={values.display_name}
              disabled={true}
              size='small'
              label={intl.formatMessage({
                id: 'USER.FORM_DISPLAY_NAME_LABEL',
              })}
            />
          </Stack>
          <Stack direction='row' sx={{ py: 2 }} spacing={2}>
            <FormControl sx={{ flex: 0.5, marginTop: 1, marginRight: 2 }}>
              {/* <RolesAutocompleteWrapper user={values?.id!} /> */}
              {/* <RolesAutocomplete roles={roles} value={actor?.roleId} /> */}
              <InputLabel id='role-helper-label' shrink>
                Ruolo
              </InputLabel>
              <Select
                id='role'
                name='role'
                size='small'
                fullWidth
                labelId='role-helper-label'
                label='Ruolo'
                onChange={handleChange}
                value={role ?? '0'}
              >
                <MenuItem value='0'>
                  <em>Nessun valore selezionato</em>
                </MenuItem>
                {roles.map((r, index) => (
                  <MenuItem value={r.id} key={index}>
                    {r.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </div>
      </div>
      <div
        className={
          'flex flex-col sm:flex-row align-middle justify-center sm:justify-end mt-5'
        }
      >
        <div>
          <Button
            id='submit-user'
            classes='w-full sm:w-auto'
            innerText={intl.formatMessage({ id: 'GENERAL.FORM.SAVE' })}
            otherProps={{ type: 'submit', onClick: onSubmit }}
          />
        </div>
      </div>
    </div>
  );
};

export { UserForm };
