import {
  collection,
  Firestore,
  getDocs,
  getFirestore,
} from 'firebase/firestore';
import { Permission } from '../../app/modules/roles/models/Permission.model';
import { PermissionsService } from '../contracts/PermissionsService';
import { permissionsCollectionName } from '../GCPUtilities';

export class GCPPermissionsService implements PermissionsService {
  private readonly firestore: Firestore;
  private permissionsCollectionName = permissionsCollectionName;

  constructor() {
    this.firestore = getFirestore();
  }
  async getMany(): Promise<Permission[]> {
    try {
      let permissions: Permission[] = [];
      const permissionsQuerySnapshot = await getDocs(
        collection(this.firestore, this.permissionsCollectionName)
      );
      permissionsQuerySnapshot.forEach((t: any) => {
        permissions.push({ ...t.data(), id: t.id });
      });
      return permissions;
    } catch (error: unknown) {
      return Promise.reject(error);
    }
  }
  async getManyNotInThisIds(ids: string[]): Promise<Permission[]> {
    try {
      const allPermissions = await this.getMany();
      return allPermissions.filter((p) => !ids.includes(p.id));
    } catch (error: unknown) {
      return Promise.reject(error);
    }
  }

  async getManyInThisIds(ids: string[]): Promise<Permission[]> {
    try {
      const allPermissions = await this.getMany();
      return allPermissions.filter((p) => ids.includes(p.id));
    } catch (error: unknown) {
      return Promise.reject(error);
    }
  }
}
