import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../../../../_metronic/layout/core';
import { useParams } from 'react-router-dom';
import { formMode } from '../../../../../../models/creationMode.model';
import { container } from 'tsyringe';
import { RoleForm } from './RoleForm';
import { emptyRole } from '../../../../models/Role.model';
import { EntityForm } from '../../../../../../components/EntityForm';
import { RolesService } from '../../../../../../../service/contracts/RolesService';

type Props = {
  mode?: formMode;
};

const RolesWrapper: FC<Props> = ({ mode }) => {
  const intl = useIntl();
  const modality = mode ?? formMode.create;
  const rolesService = container.resolve<RolesService>('RolesService');
  // @ts-ignore
  const { id } = useParams();
  const config = {
    id,
    mode: modality,
    onSubmitUrl: '/roles-list',
    initialValues: emptyRole,
  };
  return (
    <>
      <EntityForm
        config={config}
        entitiesService={rolesService}
        /* @ts-ignore */
        children={<RoleForm />}
      />
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({
          id: modality === formMode.create ? 'ROLE.FORM.NEW' : 'ROLE.FORM.EDIT',
        })}
      </PageTitle>
    </>
  );
};

export { RolesWrapper };
