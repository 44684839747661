import React, {FC, createContext} from 'react'
import { useRecoilValue } from 'recoil'
import { initialIntlConfigState, intlConfigState } from './state/intlConfig'
import { intlConfig } from './types/intlConfig'


const I18nContext = createContext(initialIntlConfigState)


const MetronicI18nProvider: FC = ({children}) => {
  const intlConfig: intlConfig = useRecoilValue(intlConfigState)
  return <I18nContext.Provider value={intlConfig}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider}
