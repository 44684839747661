import {
  collection,
  DocumentReference,
  Firestore,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

export const pdfCollectionName = 'pdfExports';
export const categoriesCollectionName = 'categories';
export const verbalsCollectionName = 'verbals';
export const nonConformitiesCollectionName = 'nonConformities';
export const tenantsCollectionName = 'tenants';
export const permissionsCollectionName = 'permissions';
export const rolesCollectionName = 'roles';
export const actorsCollectionName = 'actors';
export const usersCollectionName = 'users';

export const getLastPdfFromCollection = async (
  firestore: Firestore,
  docRef: DocumentReference
): Promise<string> => {
  try {
    const pdfExportsRef = collection(docRef, pdfCollectionName);
    const pdfExportsQuery = query(pdfExportsRef, orderBy('created_at', 'desc'));
    const pdfExportsSnapshot = await getDocs(pdfExportsQuery);
    if (pdfExportsSnapshot.empty) {
      return '';
    }
    const pdfExport = pdfExportsSnapshot.docs[0];
    const uri = pdfExport ? pdfExport.data().uri : '';
    const url = await getPdfUrl(firestore, uri);
    return url;
  } catch (e) {
    console.log("Errore in fase di recupero dell' url dell'ultimo pdf", e);
    return '';
  }
};

export const getPdfUrl = async (
  firestore: Firestore,
  pdfUri: string
): Promise<string> => {
  if (!pdfUri) {
    return '';
  }
  const storage = getStorage(firestore.app);
  const imgRef = ref(storage, pdfUri);
  const url = await getDownloadURL(imgRef);
  return url;
};

export const arePermissionsInvalid = (error: unknown): boolean => {
  // @ts-ignore
  if (!error || !error?.code) {
    return false;
  }
  // @ts-ignore
  return error?.code === 'permission-denied';
};

export * as GCPUtilities from './GCPUtilities';
