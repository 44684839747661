import React, { useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { userState } from './state/atoms/user';
import { container } from 'tsyringe';
import { AuthService } from '../../../service/contracts/AuthService';
import { sessionState } from './state/atoms';

export function Logout() {
  const setUserState = useSetRecoilState(userState);
  const setTokenState = useSetRecoilState(sessionState);
  const authService = container.resolve<AuthService>('AuthService');

  useEffect(() => {
    authService.logout().then(() => {
      setUserState(undefined);
      setTokenState(undefined);
      localStorage.removeItem('userData');
      localStorage.removeItem('session');
    });
  });

  return (
    <Switch>
      <Redirect to='/login' />
    </Switch>
  );
}
